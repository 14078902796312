import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { RecaptchaValidationResourceService, DonneesPersonnellesResourceService, NomenclatureDTO, AdhesionEnLigneNoAuthResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import * as moment from 'moment';
import { Input } from "@angular/core";
import { Pays } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { ViewEncapsulation } from "@angular/core";
import { ValidateNIR } from "src/app/shared/nir-validator";
import { ValidatorSofaxisEmail } from "src/app/shared/sofaxis-common-email.validator";
import { ControleCoherencePeriode } from "src/app/shared/controle-date.validator";
import { ValidateNumSecu } from "src/app/shared/components/new-adh-form/controle-numSecu.validator";
import { AfterSubmitErrorStateMatcher } from "src/app/shared/after-submit-error-state-matcher";
import { DialogService } from "src/app/api/dialog.service";
import { AELAdhesionDTO, AdresseDTO, AELRachatDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';
import { GarantiesPartComponent } from "src/app/shared/components/new-adh-form-parts/garanties-part/garanties-part.component";
import { RachatPartComponent } from "src/app/shared/components/new-adh-form-parts/rachat-part/rachat-part.component";
import { AdherentPartComponent } from "src/app/shared/components/new-adh-form-parts/adherent-part/adherent-part.component";
import { ConfirmEmailValidators } from "src/app/shared/components/new-adh-form-parts/adherent-part/confirm-email-validators";
import { CollectivitePartComponent } from 'src/app/shared/components/new-adh-form-parts/collectivite-part/collectivite-part.component';
import { combineLatest } from "rxjs";
import { NewAdhService } from "src/app/api/new-adh.service";
import { ValidatorFn, ValidationErrors } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { MatStepper } from "@angular/material/stepper";
import { SiretPipe } from "src/app/pipes/siret-pipe";
import { RecaptchaComponent } from "ng-recaptcha";
import { Scavenger } from '@wishtack/rx-scavenger';
import { NotificationService } from "src/app/api/notification.service";
import { environment } from 'src/environments/environment';
import { MediaObserver } from '@angular/flex-layout';
import { DecimalHelperService } from "src/app/api/decimal-helper.service";
import { DownloadHelperService } from "src/app/api/dowload-helper.service";
import { MatDialog } from "@angular/material/dialog";
import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import TelephoneNumberUtils from "src/app/utils/telephone-utils";
import { filter } from "rxjs/internal/operators/filter";
import { Router } from "@angular/router";
import { AppConfig } from "src/app/utils/app.config";
import { CgPartComponent } from 'src/app/shared/components/new-adh-form-parts/cg-part/cg-part.component';
@Component( {
    selector: 'app-new-adh-form',
    templateUrl: './new-adh-form.component.html',
    styleUrls: ['./new-adh-form.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class NewAdhFormComponent implements OnInit {
    public fonctionsPPE: Array<NomenclatureDTO>;
    PaysParDefaut: Pays;
    docuSignState: any;
    @ViewChild( "myStepper" ) private myStepper: MatStepper;
    @ViewChild( "captchaRef" ) private captchaRef: RecaptchaComponent;
    @ViewChild( "captchaRef2" ) private captchaRef2: RecaptchaComponent;
    @ViewChild( "collectivitePart" ) private collectivitePart: CollectivitePartComponent;
    @ViewChild( GarantiesPartComponent ) garantie;
    @ViewChild( RachatPartComponent ) rachat;
    @ViewChild( AdherentPartComponent ) adherent;
    @ViewChild( "cgPart" ) private cgPart: CgPartComponent;
    @Input() initialAdhesion: AELAdhesionDTO;
    @Input() useCaptcha: boolean;
    @Input() provenanceExterne: boolean;

    saisieInterneTerminee: boolean = false;
    documentsTelecharges:boolean=false;
    adresseElu;
    formSubmitted: boolean = false;
    reCaptchaSikeKey = environment.reCaptchaSikeKey;
    recaptchaStr = '';
    //https://github.com/wishtack/wishtack-steroids/tree/master/packages/rx-scavenger
    private _scavenger = new Scavenger( this );
    editable = true;
    matcher: AfterSubmitErrorStateMatcher;
    adhesionFromService;

    adherentFormGroup: FormGroup;
    collectiviteFormGroup: FormGroup;
    rachatFormGroup: FormGroup;
    garantiesFormGroup: FormGroup;
    cgFormGroup: FormGroup;
    dureeUrl: string;
    noticeDownLoaded = false;
    pieceIdentiteAverifier = true;
    pieceIdentiteModeModification = false;
    public PhonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;


    constructor( @Inject( DOCUMENT ) document, private adhesionEnLigneNoAuthResourceService: AdhesionEnLigneNoAuthResourceService, public media: MediaObserver, private config: AppConfig, private pipeSiret: SiretPipe, private newAdhService: NewAdhService, private donneesPersonnellesService: DonneesPersonnellesResourceService,
        private _formBuilder: FormBuilder, private adhesionEnLigneService: AdhesionEnLigneResourceService,
        private recaptchaService: RecaptchaValidationResourceService, private serviceDialog: DialogService, private route: ActivatedRoute
        , private notificationService: NotificationService, private decimalHelperService: DecimalHelperService, private downloadHelperService: DownloadHelperService,
        public dialog: MatDialog, private router: Router ) {

        this.dureeUrl = this.config.getConfig( 'dureeUrl' );
        this.newAdhService.adhesionItem$.pipe(
            this._scavenger.collect()
        ).subscribe( adhesion => {
            this.adhesionFromService = adhesion;
        } );
    }

    //
    // https://stackoverflow.com/questions/43488605/detect-when-challenge-window-is-closed-for-google-recaptcha?rq=1
    //

    observer: MutationObserver;
    recaptchaWindow;

    executeAndListenCaptcha( captchaRef, callback: () => void ) {

        this.killListener();

        captchaRef.execute();

        const captchaFrame = Array.from( document.getElementsByTagName( 'iframe' ) ).find(( frame ) => {
            return frame.src.indexOf( 'google.com/recaptcha/api2/bframe' ) >= 0;
        } );

        if ( captchaFrame ) {
            this.recaptchaWindow = captchaFrame.parentNode.parentNode;

            this.observer = new MutationObserver(( mutations ) => { if ( this.recaptchaWindow.style.opacity == 0 ) { this.killListener(); callback(); } } );

            this.observer.observe( this.recaptchaWindow, { attributes: true, attributeFilter: ['style'] } );
        }
    }

    killListener() {
        this.notificationService.close();
        if ( this.observer ) {

            this.observer.disconnect();
            this.observer = null;
        }
    }

    onRecaptchaClosed(): void {
        this.notificationService.close();

        this.serviceDialog.openInfoDialog(
            'La sauvegarde de votre saisie a été interompue.' )
            .afterClosed().pipe(
            this._scavenger.collect()
            ).subscribe( res => {

            },
            error => {
                this.serviceDialog.openErrorDialog( "Une erreur s'est produite." );
            } );
    }

    onRecaptchaSignatureClosed(): void {
        this.notificationService.close();

        this.serviceDialog.openInfoDialog( 'La signature a été interompue.' ).afterClosed().pipe( this._scavenger.collect() ).subscribe(
            res => {
            },
            error => {
                this.serviceDialog.openErrorDialog( "Une erreur s'est produite." );
            } );
    }

    ngOnInit() {
        this.PaysParDefaut = { codePays: 235, libelle: "FRANCE" };
        const navigation = combineLatest( this.route.paramMap, this.route.queryParamMap, ( params, queryParams ) => ( { params, queryParams } ) );

        navigation.pipe( this._scavenger.collect() ).subscribe(( navigation ) => {
            this.doInit();
            this.adherentFormGroup.get( 'adherentPPE' ).valueChanges.subscribe(( change ) => {
                if ( change == true ) {
                    this.adherentFormGroup.get( 'adherentFonctionPPE' ).enable();
                    this.adherentFormGroup.get( 'adherentPaysExerciceFonction' ).enable();
                } else {
                    this.adherentFormGroup.get( 'adherentFonctionPPE' ).disable();
                    this.adherentFormGroup.get( 'adherentPaysExerciceFonction' ).disable();
                }
                //this.ref.detectChanges();
            } );
            this.adherentFormGroup.get( 'entouragePPE' ).valueChanges.subscribe(( change ) => {
                if ( change == true ) {
                    this.adherentFormGroup.get( 'entourageFonctionPPE' ).enable();
                    this.adherentFormGroup.get( 'entourageLien' ).enable();
                    this.adherentFormGroup.get( 'entourageNom' ).enable();
                    this.adherentFormGroup.get( 'entourageNomNaissance' ).enable();
                    this.adherentFormGroup.get( 'entouragePrenom' ).enable();
                    this.adherentFormGroup.get( 'entourageDateNaissance' ).enable();
                    this.adherentFormGroup.get( 'entouragePaysExerciceFonction' ).enable();
                } else {
                    this.adherentFormGroup.get( 'entourageFonctionPPE' ).disable();
                    this.adherentFormGroup.get( 'entourageLien' ).disable();
                    this.adherentFormGroup.get( 'entourageNom' ).disable();
                    this.adherentFormGroup.get( 'entourageNomNaissance' ).disable();
                    this.adherentFormGroup.get( 'entouragePrenom' ).disable();
                    this.adherentFormGroup.get( 'entourageDateNaissance' ).disable();
                    this.adherentFormGroup.get( 'entouragePaysExerciceFonction' ).disable();
                }
                //this.ref.detectChanges();
            } );

        } );
    }

    getCiviliteAdherent() {
        var codeCivilite = this.initialAdhesion.adherent.codeCivilite;
        if ( codeCivilite == null || "MONSIEUR" != codeCivilite.toUpperCase() ) {
            codeCivilite = "MADAME";
        }
        return codeCivilite.toUpperCase();
    }

    getPays( adresse: AdresseDTO ) {
        var paysParDefaut = { codePays: 235, libelle: "FRANCE" };
        if ( adresse == undefined || adresse == null || adresse.pays == null ) {
            return paysParDefaut;
        }
        return adresse.pays;
    }

    getPaysPpe( pays: Pays ) {
        var paysParDefaut = { codePays: 235, libelle: "FRANCE" };
        if ( pays == undefined || pays == null ) {
            return paysParDefaut;
        }
        return pays;
    }
    
    isPieceIdentitePerimee() {
        return this.initialAdhesion.adherent.dateValiditePiece != null && moment( this.initialAdhesion.adherent.dateValiditePiece ).isBefore( moment() );
    }

    initPieceIdentite() {
        this.initialAdhesion.adherent.typePieceIdentite = null;
        this.initialAdhesion.adherent.controleManuelIdentiteAFaire = null;
        this.initialAdhesion.adherent.pieceIdentite = [];
        this.initialAdhesion.adherent.dateValiditePiece = null;
        this.initialAdhesion.adherent.idAFile = null;
        this.initialAdhesion.adherent.statutAnalyse = null;
        this.initialAdhesion.adherent.statutAnalyseCheckSumMrz = null;
        this.initialAdhesion.adherent.dateEmissionPiece = null;
    }

    doInit() {
        var formAdherentDirty = this.initialAdhesion != undefined && this.initialAdhesion != null && this.initialAdhesion.adherent != null;
        var formCollectiviteDirty = this.initialAdhesion != undefined && this.initialAdhesion != null && this.initialAdhesion.collectivite != null;
        var formRachatDirty = this.initialAdhesion != undefined && this.initialAdhesion != null && this.initialAdhesion.rachat != null;

        if ( this.initialAdhesion == null ) {
            this.initInitialAdhesion();
        } else {
            if ( this.initialAdhesion.adherent == null ) {
                this.initialAdhesion.adherent = {};
                this.initialAdhesion.adherent.pieceIdentite = [];
                this.initialAdhesion.adherent.bulletinAdhesionPapier = [];
                this.pieceIdentiteAverifier = true;
            } else {
                // console.log( '**************************' + this.isPieceIdentitePerimee() );
                if ( this.isPieceIdentitePerimee() ) {
                    this.notificationService.warn( 'Votre pièce d\'identité enregistrée n\'est plus valide' );
                    this.initPieceIdentite();
                }

                this.pieceIdentiteAverifier = this.initialAdhesion.adherent.typePieceIdentite == null && this.initialAdhesion.adherent.controleManuelIdentiteAFaire == null;
                // this.initialAdhesion.adherent.pieceIdentiteExistante = !this.pieceIdentiteAverifier;
            }
            if ( this.initialAdhesion.collectivite == null ) {
                this.initialAdhesion.collectivite = {};
            }
            if ( this.initialAdhesion.beneficiaires == null ) {
                this.initialAdhesion.beneficiaires = [];
            }
        }
        this.adhesionEnLigneNoAuthResourceService.getListeFonctionPPE().subscribe( result => {
            this.fonctionsPPE = result;
        } )

        this.adherentFormGroup = this._formBuilder.group( {

            ligne1: [( this.initialAdhesion.adherent.adresse || {} ).ligne1, Validators.maxLength( 40 )],
            ligne2: [( this.initialAdhesion.adherent.adresse || {} ).ligne2, [Validators.required, Validators.maxLength( 40 )]],
            ligne3: [( this.initialAdhesion.adherent.adresse || {} ).ligne3, Validators.maxLength( 40 )],
            codePostal: [( this.initialAdhesion.adherent.adresse || {} ).codePostal, [Validators.required]],
            ville: [( this.initialAdhesion.adherent.adresse || {} ).ville, [Validators.required]],
            pays: [this.getPays( this.initialAdhesion.adherent.adresse ), [Validators.required]],

            numeroMobileFrance: [TelephoneNumberUtils.toNationalFormat( this.initialAdhesion.adherent.portable )],
            prefixeMobile: [TelephoneNumberUtils.toPrefix( this.initialAdhesion.adherent.portable )],

            numeroFixe: [this.initialAdhesion.adherent.telephone, [Validators.pattern( this.PhonePattern ), Validators.minLength( 10 ), Validators.maxLength( 15 )]],

            email: [this.initialAdhesion.adherent.mail, [ValidatorSofaxisEmail, Validators.required]],
            emailCheck: ['', [ValidatorSofaxisEmail, Validators.required, ConfirmEmailValidators.ConfirmEmail( 'email' )]],
            numeroSecu: [this.initialAdhesion.adherent.numeSecu, [Validators.required, ValidateNIR]],
            fonction: [this.initialAdhesion.adherent.fonction, this.provenanceExterne ? Validators.required : null]
            , coherenceNumSecu: [{ value: '' }, [this.validatorNumSecu()]],
            biaPapier: [formAdherentDirty ? this.initialAdhesion.adherent.bulletinAdhesionPapier : '', this.provenanceExterne ? null : Validators.required],


            adherentPPE: this.initialAdhesion.adherent.ppe != null && this.initialAdhesion.adherent.ppe.adherentPPE ? this.initialAdhesion.adherent.ppe.adherentPPE != null : false,
            adherentFonctionPPE: [( this.initialAdhesion.adherent.ppe || {} ).adherentFonctionPPE],
            adherentPaysExerciceFonction: [this.getPaysPpe( this.initialAdhesion.adherent.ppe ? this.initialAdhesion.adherent.ppe.adherentPaysExerciceFonction : null ), [Validators.required]],

            entouragePPE: this.initialAdhesion.adherent.ppe != null && this.initialAdhesion.adherent.ppe.entouragePPE ? this.initialAdhesion.adherent.ppe.entouragePPE != null : false,
            entourageNom: [( this.initialAdhesion.adherent.ppe || {} ).entourageNom],
            entourageNomNaissance: [( this.initialAdhesion.adherent.ppe || {} ).entourageNomNaissance],

            entourageFonctionPPE: [( this.initialAdhesion.adherent.ppe || {} ).entourageFonctionPPE],

            entourageLien: [( this.initialAdhesion.adherent.ppe || {} ).entourageLien],
            entouragePrenom: [( this.initialAdhesion.adherent.ppe || {} ).entouragePrenom],
            entourageDateNaissance: [( this.initialAdhesion.adherent.ppe || {} ).entourageDateNaissance],
            entouragePaysExerciceFonction: [this.getPaysPpe( this.initialAdhesion.adherent.ppe ? this.initialAdhesion.adherent.ppe.entouragePaysExerciceFonction : null ), [Validators.required]],

        } );

        this.collectiviteFormGroup = this._formBuilder.group( {
            idExterne: [( ( this.initialAdhesion || {} ).collectivite || {} ).idExterne],
            numeroSIRET: [this.pipeSiret.transform( this.initialAdhesion.collectivite.numeroSiret ), Validators.required],
            denomination: [this.initialAdhesion.collectivite.raisonSociale, Validators.required],
            ligne1: [( this.initialAdhesion.collectivite.adresse || {} ).ligne1, Validators.maxLength( 40 )],
            ligne2: [( this.initialAdhesion.collectivite.adresse || {} ).ligne2, [Validators.required, Validators.maxLength( 40 )]],
            ligne3: [( this.initialAdhesion.collectivite.adresse || {} ).ligne3, Validators.maxLength( 40 )],
            codePostal: [( this.initialAdhesion.collectivite.adresse || {} ).codePostal, [Validators.required]],
            ville: [( this.initialAdhesion.collectivite.adresse || {} ).ville, [Validators.required]],
            pays: [this.getPays( this.initialAdhesion.collectivite.adresse ), [Validators.required]],
            contactCivilite: [this.initialAdhesion.collectivite.interlocuteurCodeCivilite || "MADAME", [Validators.required]],
            contactNom: [this.initialAdhesion.collectivite.interlocuteurNom, Validators.required],
            contactPrenom: [this.initialAdhesion.collectivite.interlocuteurPrenom, Validators.required],
            numeroFixe: [this.initialAdhesion.collectivite.interlocuteurTelephone, [Validators.pattern( this.PhonePattern ), Validators.minLength( 10 ), Validators.maxLength( 10 )]],
            contactEmail: [this.initialAdhesion.collectivite.interlocuteurMail, [ValidatorSofaxisEmail, Validators.required]]
        } );

        this.garantiesFormGroup = this._formBuilder.group( {

            taux: [this.initialAdhesion.taux == null ? '8' : ( parseInt( this.initialAdhesion.taux ) ).toString(), [Validators.required]],
            clauseGarantieDC: [( this.initialAdhesion.clauseDecesStandard == null ? true : this.initialAdhesion.clauseDecesStandard ), [Validators.required]],
            dateEffet: [this.initialAdhesion.dateEffetSouhaiteeAdhesion, [this.DateEffetValidator()]]
            //  dateEffet: [this.initialAdhesion.dateEffetSouhaiteeAdhesion]

        } );


        this.initFormGroupRachat();

        this.cgFormGroup = this._formBuilder.group( {
            getcGsDocumentsValidees: [null, Validators.requiredTrue],
            getcGsEtMLValidees: [null, Validators.requiredTrue],
         //   toSign: [null, [Validators.required]],
        } );

        if ( formAdherentDirty ) {
            this.adherentFormGroup.markAsDirty();
            for ( let i in this.adherentFormGroup.controls )
                this.adherentFormGroup.controls[i].markAsTouched();
        }

        if ( formCollectiviteDirty ) {
            this.collectiviteFormGroup.markAsDirty();
            for ( let i in this.collectiviteFormGroup.controls )
                this.collectiviteFormGroup.controls[i].markAsTouched();
        }

        if ( formRachatDirty ) {
            this.rachatFormGroup.markAsDirty();
            for ( let i in this.rachatFormGroup.controls )
                this.rachatFormGroup.controls[i].markAsTouched();
        }

        this.newAdhService.changeAdhesion( this.getAdhesionFromSaisie() );

        this.route.queryParams.pipe( filter( params => params.event && params.id ) ).subscribe( params => {
            setTimeout(() => {
                this.myStepper.linear = false;
                this.myStepper.selectedIndex = 6;
                var array = this.myStepper.steps.toArray()
                array[0].completed = true;
                array[1].completed = true;
                array[2].completed = true;
                array[3].completed = true;
                array[4].completed = true;
                array[5].completed = true;
                this.editable = false;
                this.myStepper.linear = true;
                this.docuSignState = params.event;
            } )
        } );
    }

    validatorNumSecu(): ValidatorFn {
        return ( controlCoh: AbstractControl ): { [key: string]: boolean } | null => {
            if ( this.adherentFormGroup != undefined ) {
                ValidateNumSecu( controlCoh, this.adherentFormGroup.get( 'numeroSecu' ), this.initialAdhesion.adherent.codeCivilite, this.initialAdhesion.adherent.dateNaissance );
            };
            return null;
        }
    }

    initInitialAdhesion() {
        this.initialAdhesion = {};
        this.initialAdhesion.adherent = {};
        this.initialAdhesion.collectivite = {};

        this.initialAdhesion.beneficiaires = [];
    }

    doRecaptchaSauvegarder( captchaResponse: string ) {
        this.notificationService.close();
        if ( captchaResponse ) {
            this.captchaRef.reset();
            this.sauvegarderDonneesSaisies( captchaResponse );
        }
    }

    disableSaisie() {
        this.editable = false;
    }

    enableSaisie() {
        this.editable = true;
    }

    doRecaptchaSigner( captchaResponse: string ) {
        this.notificationService.close();
        if ( captchaResponse ) {
            this.captchaRef2.reset();
            this.demanderSignature( captchaResponse );
        }
    }

    sauvegarderDonneesSaisies( captchaResponse: string ) {
        this.notificationService.wait( 'La sauvegarde de votre saisie est en cours.<br><br>Veuillez patienter.', false );
        this.adhesionEnLigneService.sauvegarderAdhesion( this.getAdhesionFromSaisie(), captchaResponse
        ).then(( observable ) => observable.pipe(
            this._scavenger.collect()
        ).subscribe(
            data => {

                //console.log( this.initialAdhesion );
                let piece = this.initialAdhesion.adherent.pieceIdentite;
                this.initialAdhesion = data;

                // if ( this.initialAdhesion.adherent.controleManuelIdentiteAFaire ) {

                this.initialAdhesion.adherent.pieceIdentite = piece;
                //}
                // console.log( this.initialAdhesion );
                this.notificationService.close();
                this.serviceDialog.openInfoDialog(
                    'Vos informations ont été enregistrées.<br><br>Nous vous avons envoyé un mail avec un lien ' +
                    'vous permettant de revenir sur cette adhésion pour la compléter.<br > <br>' +
                    '<b>Ce lien est valable pendant ' + this.dureeUrl + ' jours<b>' )
                    .afterClosed().pipe(
                    this._scavenger.collect()
                    ).subscribe( res => {

                    },
                    error => {
                        this.serviceDialog.openErrorDialog( "Une erreur s'est produite." );
                    } );

            },
            error => {
                this.notificationService.close();
                this.serviceDialog.openErrorDialog( "Une erreur s'est produite. Vos informations n\'ont pas été sauvegardées" );
            } ) )
            ;
    }

    demanderSignature( captchaResponse: string ) {
        this.disableSaisie();
        this.notificationService.wait( 'La redirection vers la signature électronique est en cours.<br><br>Veuillez patienter.', false );
        this.adhesionEnLigneService.demanderSignature( this.getAdhesionFromSaisie(), captchaResponse
        ).then(( observable ) => observable.pipe(
            this._scavenger.collect()
        ).subscribe(
            data => {
                this.notificationService.close();
                window.location.href = data.urlSignature;

            },
            error => {

                this.enableSaisie();

                this.notificationService.close();
                this.serviceDialog.openErrorDialog( "Une erreur s'est produite. La signature électronique a échoué." );
            } ) )
    }

    executeCapchaSauvegarde() {
        this.notificationService.wait( 'Le processus de sécuristation de votre saisie est déclenché.<br><br>Veuillez patienter.', false );
        this.executeAndListenCaptcha( this.captchaRef, this.onRecaptchaClosed );
    }

    executeCapchaSignature() {
        this.notificationService.wait( 'Le processus de signature électronique est déclenché.<br><br>Veuillez patienter.', false );
        this.executeAndListenCaptcha( this.captchaRef2, this.onRecaptchaSignatureClosed );
    }

    sauvegarder() {
        this.formSubmitted = true;
        const mobileValide = ( !this.useCaptcha ) || ( this.adherentFormGroup.get( 'numeroMobileFrance' ).valid && this.useCaptcha )
        if ( this.adherentFormGroup.get( 'email' ).valid && this.adherentFormGroup.get( 'emailCheck' ).valid && mobileValide ) {
            if ( this.useCaptcha ) {
                this.executeCapchaSauvegarde();
            } else {
                    this.sauvegarderDonneesSaisies( null );
            }
        } else {
            let message = "<br>Les champs suivants doivent être renseignés et valides :<br><br>";
            if ( !mobileValide ) {
                message = message + "- Numéro de téléphone mobile<br>";
            }
            if ( !this.adherentFormGroup.get( 'email' ).valid ) {
                message = message + "- Email<br>"
            }
            if ( !this.adherentFormGroup.get( 'emailCheck' ).valid ) {
                message = message + "- Confirmation de l'Email<br>";
            }
            this.serviceDialog.openErrorDialog( message )
                .afterClosed().pipe(
                this._scavenger.collect()
                ).subscribe( res => {

                } );
        }
    }

    sauvegarderFromInterne() {
        this.doFormSubmitted();
        if ( this.adherentFormGroup.invalid ) {
            for ( let i in this.adherentFormGroup.controls )
                this.adherentFormGroup.controls[i].markAsTouched();
            this.notificationService.warn( 'Votre formulaire contient des erreurs. Veuillez corriger ces erreurs pour enregister votre saisie. ' );
        } else {
            const mobilePhone=this.adherentFormGroup.get( 'numeroMobileFrance' ).value
            if (mobilePhone && this.adherentFormGroup.get( 'numeroMobileFrance' ).valid){
                this.sauvegarderDonneesSaisiesFromInterne();
            } else {
                this.serviceDialog.openConfirmDialog( 'Un numéro de téléphone portable valide sera obligatoire pour accéder à l\'espace adhérents. Merci de contacter l\'élu. Voulez vous continuer ?' )
                .afterClosed().subscribe( res => {
                    if ( res ) {
                        this.sauvegarderDonneesSaisiesFromInterne();
                    }
                } );
            }
        }

    }

    sauvegarderDonneesSaisiesFromInterne() {
        this.notificationService.wait( 'La sauvegarde de votre saisie est en cours.<br><br>Veuillez patienter.', false );
        //console.log( this.getAdherentFromSaisie() )
        this.adhesionEnLigneService.sauvegarderAdhesionPapier( this.getAdherentFromSaisie() ).subscribe(
            data => {
                let piece = this.initialAdhesion.adherent.pieceIdentite;
                this.initialAdhesion.adherent = data;
                if ( this.initialAdhesion.adherent.idAFile == null ) {
                    //pour éviter de récupérer des fichiers temporaires de la GED
                    //console.log( "idAfile non renseigné" );
                    this.initialAdhesion.adherent.pieceIdentite = piece;
                }
                //console.log( "sauvegarderDonneesSaisiesFromInterne dto retour" );
                //console.log( data );
                //console.log( "sauvegarderDonneesSaisiesFromInterne dto" );
                //console.log( this.initialAdhesion.adherent );
                this.notificationService.close();
                this.notificationService.wait( 'Début des contrôles BECLM.<br><br>Veuillez patienter.', false );
                this.adhesionEnLigneService.controlerAdhesionPapier( this.initialAdhesion.adherent ).subscribe(
                    data => {
                        this.notificationService.close();
                        //console.log( data );
                        // this.initialAdhesion.adherent = data;
                        this.saisieInterneTerminee = true;
                    },
                    error => {
                        this.notificationService.close();
                        this.serviceDialog.openErrorDialog( "Une erreur s'est produite. Vos informations n\'ont pas été sauvegardées" );
                    } );



            },
            error => {
                this.notificationService.close();
                this.serviceDialog.openErrorDialog( "Une erreur s'est produite. Vos informations n\'ont pas été sauvegardées" );
            } );
    }

    setInitialAdhesionEmpty() {
        this.initialAdhesion.id = null;
        this.initialAdhesion.idDeTransfertPourSauvegarde = null;
        this.initialAdhesion.idExterne = null;
        this.initialAdhesion.adherent = {};
        this.initialAdhesion.adherent.id = this.adhesionFromService.adherent.id;
        this.initialAdhesion.adherent.idExterne = this.adhesionFromService.adherent.idExterne;
        this.initialAdhesion.collectivite = {};
        this.initialAdhesion.dateEffetSouhaiteeAdhesion = null;
        this.initialAdhesion.dateSignature = null;
        this.initialAdhesion.faitA = null;
        this.initialAdhesion.taux = null;
        this.initialAdhesion.libelleEtatAdhesion = null;
        this.initialAdhesion.codeEtatAdhesion = "EnCoursSaisie";
        this.initialAdhesion.optionGarantieDeces = true;
        this.initialAdhesion.clauseDecesStandard = true;
        this.initialAdhesion.dateEffetGarantieDeces = null;
        this.initialAdhesion.beneficiaires = [];
        this.initialAdhesion.rachat = null;

        this.initialAdhesion.cgsDocumentsValidees = false;

        this.initialAdhesion.cgsEtMLValidees = false;
        this.initialAdhesion.cpreuveValidees = true;

    }

    resetFormulaireAderent() {

        this.adherentFormGroup.reset();
        this.adherentFormGroup.patchValue( {
            civilite: "MADAME",
            pays: this.getPays( null ),
            adherentPPE: false,
            entouragePPE: false

        } )

    }
    resetFormulaireCollectivite() {
        this.collectiviteFormGroup.reset();
        this.collectiviteFormGroup.patchValue( {
            contactCivilite: "MADAME",
            pays: this.getPays( null )
        } )
    }

    resetFormulaireGaranties() {
        this.initialAdhesion.beneficiaires = [];
        this.garantiesFormGroup.patchValue( {
            taux: "8",
            garantieDC: true,
            clauseGarantieDC: true,
            dateEffet: null,
        } );
        this.garantie.dataSourceBeneficiaires = [];
    }

    resetFormulaireRachat() {
        this.rachatFormGroup.reset();
        this.rachatFormGroup.patchValue( {
            doRachat: false,
            rachatEnPlusFois: true,
            datePremierMoisRachat: moment().startOf( 'month' ).add( 1, 'M' ),
            echeancier: "AUTO",
            pjointe: null
        } );

        this.rachatFormGroup.get( 'taux' ).disable();
        this.rachatFormGroup.get( 'pjointe' ).disable();
        this.rachatFormGroup.get( 'dateDebutRachat' ).disable();
        this.rachatFormGroup.get( 'dateFinRachat' ).disable();
        this.rachatFormGroup.get( 'echeancier' ).disable();
        this.rachatFormGroup.get( 'nbmensualites' ).disable();
        this.rachatFormGroup.get( 'montantIndemnitesRachetees' ).disable();
    }

    resetStepper() {
        this.myStepper.reset();
        var array = this.myStepper.steps.toArray();
        this.editable = false;
        this.myStepper.linear = true;
        this.myStepper.selectedIndex = 0;
        this.editable = true;
    }

    resetAdhesion() {
        this.docuSignState = null
        this.documentsTelecharges=false
        this.resetStepper();

        this.adherentFormGroup.patchValue( {
            /* civilite: this.adhesionFromService.adherent.codeCivilite,
             nomPatronymique: this.adhesionFromService.adherent.nomPatronymique,
             nomUsage: this.adhesionFromService.adherent.nomUsage,
             prenom: this.adhesionFromService.adherent.prenom,
             lieuNaissance: this.adhesionFromService.adherent.lieuNaissance,
             dateNaissance: this.adhesionFromService.adherent.dateNaissance,*/
            ligne1: this.adhesionFromService.adherent.adresse.ligne1,
            ligne2: this.adhesionFromService.adherent.adresse.ligne2,
            ligne3: this.adhesionFromService.adherent.adresse.ligne3,
            codePostal: this.adhesionFromService.adherent.adresse.codePostal,
            ville: this.adhesionFromService.adherent.adresse.ville,
            pays: this.adhesionFromService.adherent.adresse.pays,
            numeroMobileFrance: TelephoneNumberUtils.toNationalFormat( this.initialAdhesion.adherent.portable ),
            prefixeMobile: TelephoneNumberUtils.toPrefix( this.initialAdhesion.adherent.portable ),
            numeroFixe: this.adhesionFromService.adherent.telephone,
            email: this.adhesionFromService.adherent.mail,
            numeroSecu: this.adhesionFromService.adherent.numeSecu,

            adherentPPE: this.adhesionFromService.adherent.ppe.adherentPPE,
            adherentFonctionPPE: this.adhesionFromService.adherent.ppe.adherentFonctionPPE,
            adherentPaysExerciceFonction: this.adhesionFromService.adherent.ppe.adherentPaysExerciceFonction,

            entouragePPE: this.adhesionFromService.adherent.ppe.entouragePPE,
            entourageFonctionPPE: this.adhesionFromService.adherent.ppe.entourageFonctionPPE,
            entourageLien: this.adhesionFromService.adherent.ppe.entourageLien,
            entourageNom: this.adhesionFromService.adherent.ppe.entourageNom,
            entourageNomNaissance: this.adhesionFromService.adherent.ppe.entourageNomNaissance,
            entouragePrenom: this.adhesionFromService.adherent.ppe.entouragePrenom,
            entourageDateNaissance: this.adhesionFromService.adherent.ppe.entourageDateNaissance,
            entouragePaysExerciceFonction: this.adhesionFromService.adherent.ppe.entouragePaysExerciceFonction,


        } )
        //    this.adherentFormGroup.get( 'pjointe' ).reset();
        //   this.adherentFormGroup.get( 'pjointe' ).setValue( this.adhesionFromService.adherent.pieceIdentite );
        this.adherentFormGroup.updateValueAndValidity();
        //collectivité
        this.resetFormulaireCollectivite();
        //garanties
        this.resetFormulaireGaranties()
        //rachat
        this.resetFormulaireRachat();
       // this.setInitialAdhesionEmpty();
        //CGs
        /*this.cgFormGroup.patchValue( {
            toSign: null
        } );*/
        this.newAdhService.changeAdhesion( this.getAdhesionFromSaisie() );
    }

    downloadDocumentAdhesion() {
        this.route.queryParams.pipe(
            this._scavenger.collect()
        ).subscribe( params => {
            this.adhesionEnLigneService.downloadRecapAdhesion( params["idPli"] ).then( observable => observable.pipe(
                this._scavenger.collect()
            ).subscribe( result => {
                var filename = 'NouvelleAdhesion.pdf'
                if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
                    window.navigator.msSaveOrOpenBlob( result, filename );
                } else {
                    // pour Chrome et FireFox
                    const url = window.URL.createObjectURL( result );
                    const a = document.createElement( 'a' );
                    document.body.appendChild( a );
                    a.setAttribute( 'style', 'display: none' );
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL( url );
                    document.body.removeChild( a );
                }
            } ) );
        } );
    }

    initDateNaissance() {
        return this.initialAdhesion.adherent == null || this.initialAdhesion.adherent.dateNaissance == null ? null : moment( this.initialAdhesion.adherent.dateNaissance );
    }

    initFormGroupRachat() {

        this.rachatFormGroup = this._formBuilder.group( {
            doRachat: [this.isRachat(), Validators.required],
            rachatEnPlusFois: [!this.isRachat() ? true : this.isRachatEnSeuleFois()],
            pjointe: [{ disabled: this.isDisabledPieceJointeEchancier(), value: !this.isDisabledPieceJointeEchancier() && this.initialAdhesion.rachat.echeancier != null ? this.initialAdhesion.rachat.echeancier : '' }, Validators.required],
            montMax: [],
            datePremierMoisRachat: [this.initDatePremierRachat()],
            taux: [{ disabled: true }],
            echeancier: [{ disabled: this.isDisabledEchancier(), value: this.initTypeEchancier() }, Validators.required],
            montantIndemnitesRachetees: [{ disabled: !this.isRachat(), value: ( this.initialAdhesion.rachat || {} ).montantIndemnitesRachetees }],
            dateDebutRachat: [{ disabled: !this.isRachat(), value: this.initDateDebutRachat() }, [Validators.required, this.DateDebutRachatValidator( this.garantiesFormGroup.get( 'dateEffet' ) )]],
            dateFinRachat: [{ disabled: !this.isRachat(), value: this.initDateFinRachat() }, [Validators.required, this.DateFinRachatValidator( this.garantiesFormGroup.get( 'dateEffet' ) )]],
            nbmensualites: [{ disabled: this.isDisabledNbMensualite(), value: ( this.initialAdhesion.rachat || {} ).nbMensualites }, [Validators.required, Validators.min( 1 ), Validators.max( 72 )]]

        }, {
                validator: ControleCoherencePeriode( 'dateDebutRachat', 'dateFinRachat' ),
            } );


    }
    isDisabledEchancier() {
        return !this.isRachat() || this.isRachatEnSeuleFois();
    }

    isDisabledNbMensualite() {
        return this.isDisabledEchancier() || this.initialAdhesion.rachat.typeEcheancier != "AUTO";
    }

    isDisabledPieceJointeEchancier() {
        return this.isDisabledEchancier() || this.initialAdhesion.rachat.typeEcheancier != "MAN";
    }

    initDateDebutRachat() {
        return this.initialAdhesion.rachat == null || this.initialAdhesion.rachat.dateDebutPeriodeRachat == null ? null : moment( this.initialAdhesion.rachat.dateDebutPeriodeRachat );
    }

    initDateFinRachat() {
        return this.initialAdhesion.rachat == null || this.initialAdhesion.rachat.dateFinPeriodeRachat == null ? null : moment( this.initialAdhesion.rachat.dateFinPeriodeRachat );
    }

    initDatePremierRachat() {
        if ( this.initialAdhesion.rachat == null || this.initialAdhesion.rachat.datePremierMoisVersement == null ) {
            return moment().startOf( 'month' ).add( 1, 'M' );
        } else {
            return moment( this.initialAdhesion.rachat.datePremierMoisVersement );
        }
    }

    initTypeEchancier() {
        if ( this.initialAdhesion.rachat == null || this.initialAdhesion.rachat.typeEcheancier == null ) {
            return "AUTO";
        } else {
            return this.initialAdhesion.rachat.typeEcheancier;
        }
    }

    isRachat() {
        return this.initialAdhesion.rachat != null && this.initialAdhesion.rachat.doRachat;
    }

    isRachatEnSeuleFois() {
        return this.initialAdhesion.rachat != null && this.isRachat() && this.initialAdhesion.rachat.rachatEnUneFois;
    }

    DateDebutRachatValidator( dateEffetAdhesion ): ValidatorFn {
        return ( controlDeb: AbstractControl ): { [key: string]: boolean } | null => {

            var dateDebMin = moment( '31/03/1992', "DD/MM/YYYY" );
            if ( controlDeb.value == undefined || controlDeb.value == null ) {
                return {
                    'required': true
                };
            }
            if ( controlDeb.value.isBefore( dateDebMin ) ) {
                return {
                    'dateDebutRachatSup92': true
                };
            }
            var previousMonth = moment( dateEffetAdhesion.value ).subtract( 1, 'month' );
            var lastDayOfMonthPrevious = previousMonth.endOf( 'month' );
            if ( controlDeb.value.isAfter( lastDayOfMonthPrevious ) ) {
                return {
                    'dateDebRachatSupDateAdhesion': true
                };
            }

            return null;
        }
    }

    DateFinRachatValidator( dateEffetAdhesion ): ValidatorFn {
        return ( controlFin: AbstractControl ): { [key: string]: boolean } | null => {
            if ( controlFin.value == undefined || controlFin.value == null ) {
                return {
                    'required': true
                };
            }
            var previousMonth = moment( dateEffetAdhesion.value ).subtract( 1, 'month' );
            var lastDayOfMonthPrevious = previousMonth.endOf( 'month' );
            if ( controlFin.value.isAfter( lastDayOfMonthPrevious ) ) {
                return {
                    'dateFinRachatSupDateAdhesion': true
                };
            }
            return null;
        };
    }

    DateEffetValidator(): ValidatorFn {
        return ( controlDeb: AbstractControl ): { [key: string]: boolean } | null => {
            if ( this.garantiesFormGroup != undefined && this.garantie != undefined ) {

                if ( controlDeb.value == null ) { // null or undefined
                    return {
                        'required': true
                    };
                }
                if ( moment( controlDeb.value ).date() != 1 ) {
                    return {
                        'premierJourDuMois': true
                    };

                }

                let dateDebMin = this.garantie.dateAdhesionMin;
                let dateDebMax = this.garantie.dateAdhesionMax;
                if ( moment( controlDeb.value ).isBefore( dateDebMin ) || moment( controlDeb.value ).isAfter( dateDebMax ) ) {
                    return {
                        'periode3MoisMax': true
                    };

                }
            }
            return null;
        }
    }

    ngOnDestroy() {
    }

    toUpperCase( chaine ) {
        if ( chaine !== undefined && chaine != null ) {
            return chaine.toUpperCase();
        }
        return chaine;
    }

    getAdherentFromSaisie() {
        return {
            id: ( this.initialAdhesion == null || this.initialAdhesion.adherent == null ) ? null : this.initialAdhesion.adherent.id,
            idAppliCommercialWindev: ( this.initialAdhesion == null || this.initialAdhesion.adherent == null ) ? null : this.initialAdhesion.adherent.idAppliCommercialWindev,
            idExterne: ( this.initialAdhesion == null || this.initialAdhesion.adherent == null ) ? null : this.initialAdhesion.adherent.idExterne,
            codeCivilite: this.initialAdhesion.adherent.codeCivilite,
            nomPatronymique: this.toUpperCase( this.initialAdhesion.adherent.nomPatronymique ),
            nomUsage: this.toUpperCase( this.initialAdhesion.adherent.nomUsage ),
            prenom: this.initialAdhesion.adherent.prenom,
            lieuNaissance: this.initialAdhesion.adherent.lieuNaissance,
            paysNaissance: this.initialAdhesion.adherent.paysNaissance,
            codePaysNaissance: this.initialAdhesion.adherent.codePaysNaissance,
            dateNaissance: this.initialAdhesion.adherent.dateNaissance,
            numeSecu: ( this.adherentFormGroup.get( 'numeroSecu' ).value == null ) ? null : this.adherentFormGroup.get( 'numeroSecu' ).value.replace( /\s+/g, '' ),
            adresse: this.getAdresse( this.adherentFormGroup ),
            mail: this.adherentFormGroup.get( 'email' ).value,
            telephone: this.adherentFormGroup.get( 'numeroFixe' ).value,
            portable: TelephoneNumberUtils.toE164( this.adherentFormGroup.get( 'numeroMobileFrance' ).value, this.adherentFormGroup.get( 'prefixeMobile' ).value ),
            fonction: this.adherentFormGroup.get( 'fonction' ).value,
            bulletinAdhesionPapier: this.provenanceExterne ? [] : this.adherentFormGroup.get( 'biaPapier' ).value,
            idFonctionnel: this.initialAdhesion.adherent.idFonctionnel,
            numeroPieceIdentite: this.initialAdhesion.adherent.numeroPieceIdentite,
            nationalite: this.initialAdhesion.adherent.nationalite,
            dateValiditePiece: this.initialAdhesion.adherent.dateValiditePiece,
            dateEmissionPiece: this.initialAdhesion.adherent.dateEmissionPiece,
            typePieceIdentite: this.initialAdhesion.adherent.typePieceIdentite,
            controleManuelIdentiteAFaire: this.initialAdhesion.adherent.controleManuelIdentiteAFaire,
            controleManuelPPEAFaire: this.initialAdhesion.adherent.controleManuelPPEAFaire,
            controleManuelGDAAFaire: this.initialAdhesion.adherent.controleManuelGDAAFaire,
            pieceIdentite: this.initialAdhesion.adherent.pieceIdentite,
            // pieceIdentiteExistante: this.initialAdhesion.adherent.pieceIdentiteExistante,
            idAFile: this.initialAdhesion.adherent.idAFile,
            statutAnalyse: this.initialAdhesion.adherent.statutAnalyse,
            statutAnalyseCheckSumMrz: this.initialAdhesion.adherent.statutAnalyseCheckSumMrz,

            ppe: this.getPpe( this.adherentFormGroup ),
        };
    }

    getAdresse( formGroup ) {
        return {
            ligne1: formGroup.get( "ligne1" ).value,
            ligne2: formGroup.get( "ligne2" ).value,
            ligne3: formGroup.get( "ligne3" ).value,
            codePostal: formGroup.get( "codePostal" ).value,
            ville: this.toUpperCase( formGroup.get( "ville" ).value ),
            pays: ( formGroup.get( "pays" ).value || {} )
        };
    }

    hasAdressError( formGroup ) {
        let errors =
            formGroup.get( "ligne1" ).errors ||
            formGroup.get( "ligne2" ).errors ||
            formGroup.get( "ligne3" ).errors ||
            formGroup.get( "codePostal" ).errors ||
            formGroup.get( "ville" ).errors ||
            formGroup.get( "pays" ).errors;

        return !( errors == null );
    }

    getPpe( formGroup ) {
        return {
            adherentPPE: formGroup.get( "adherentPPE" ).value,
            adherentFonctionPPE: ( formGroup.get( "adherentPPE" ).value && formGroup.get( "adherentFonctionPPE" ).value != null ) ? formGroup.get( "adherentFonctionPPE" ).value : null,
            adherentPaysExerciceFonction: formGroup.get( "adherentPPE" ).value ? formGroup.get( "adherentPaysExerciceFonction" ).value : null,
            entouragePPE: formGroup.get( "entouragePPE" ).value,
            entourageNom: this.toUpperCase( formGroup.get( "entouragePPE" ).value ? formGroup.get( "entourageNom" ).value : null ),
            entourageNomNaissance: this.toUpperCase( formGroup.get( "entouragePPE" ).value ? formGroup.get( "entourageNomNaissance" ).value : null ),
            entourageFonctionPPE: ( formGroup.get( "entouragePPE" ).value && formGroup.get( "entourageFonctionPPE" ).value != null ) ? formGroup.get( "entourageFonctionPPE" ).value : null,
            entourageLien: formGroup.get( "entouragePPE" ).value ? formGroup.get( "entourageLien" ).value : null,
            entouragePrenom: formGroup.get( "entouragePPE" ).value ? formGroup.get( "entouragePrenom" ).value : null,
            entourageDateNaissance: formGroup.get( "entouragePPE" ).value ? formGroup.get( "entourageDateNaissance" ).value : null,
            entouragePaysExerciceFonction: formGroup.get( "entouragePPE" ).value ? formGroup.get( "entouragePaysExerciceFonction" ).value : null,
        };

    }

    getCollectiviteFromSaisie() {
        return {
            id: ( this.initialAdhesion == null || this.initialAdhesion.collectivite == null ) ? null : this.initialAdhesion.collectivite.id,
            idExterne: this.collectiviteFormGroup.get( 'idExterne' ).value,
            idFonc: ( this.initialAdhesion == null || this.initialAdhesion.collectivite == null ) ? null : this.initialAdhesion.collectivite.idFonc,
            numeroSiret: this.collectiviteFormGroup.get( 'numeroSIRET' ).value == null ? null : this.collectiviteFormGroup.get( 'numeroSIRET' ).value.replace( /\s+/g, '' ),
            raisonSociale: this.toUpperCase( this.collectiviteFormGroup.get( 'denomination' ).value ),
            adresse: this.getAdresse( this.collectiviteFormGroup ),
            interlocuteurCodeCivilite: this.collectiviteFormGroup.get( 'contactCivilite' ).value,
            interlocuteurNom: this.toUpperCase( this.collectiviteFormGroup.get( 'contactNom' ).value ),
            interlocuteurPrenom: this.collectiviteFormGroup.get( 'contactPrenom' ).value,
            interlocuteurMail: this.collectiviteFormGroup.get( 'contactEmail' ).value,
            interlocuteurTelephone: this.collectiviteFormGroup.get( 'numeroFixe' ).value,
        }
    }

    rachatEnUnseuleFois(): boolean {
        return this.rachatFormGroup.get( 'rachatEnPlusFois' ).value;
    }

    doRachat(): boolean {
        return this.rachatFormGroup.get( 'doRachat' ).value;
    }

    isEchancierAuto(): boolean {
        return !this.rachatEnUnseuleFois() && this.rachatFormGroup.get( 'echeancier' ).value == "AUTO";
    }

    isEchancierMan(): boolean {
        return !this.rachatEnUnseuleFois() && this.rachatFormGroup.get( 'echeancier' ).value == "MAN";
    }

    getRachatFromSaisie(): AELRachatDTO {

        if ( !this.doRachat() ) {
            return null;
        } else {
            const rachatAuto = ( this.isEchancierAuto() );

            const result = {
                id: ( this.initialAdhesion == null || this.initialAdhesion.rachat == null ) ? null : this.initialAdhesion.rachat.id,
                doRachat: this.rachatFormGroup.get( 'doRachat' ).value,
                dateDebutPeriodeRachat: this.rachatFormGroup.get( 'dateDebutRachat' ).value,
                dateFinPeriodeRachat: this.rachatFormGroup.get( 'dateFinRachat' ).value,
                // montantIndemnitesRachetees: this.rachatFormGroup.get( 'montantIndemnitesRachetees' ).value,
                montantIndemnitesRachetees: this.decimalHelperService.normalizeNumber( this.rachatFormGroup.get( 'montantIndemnitesRachetees' ).value ),
                rachatEnUneFois: this.rachatFormGroup.get( 'rachatEnPlusFois' ).value,
                nbMensualites: !rachatAuto ? null : this.rachatFormGroup.get( 'nbmensualites' ).value,
                montantARepartir: this.rachat != undefined ? this.rachat.total() : null,
                mensualiteStandard: !rachatAuto ? null : ( this.rachat != undefined ? this.rachat.mensualiteStandard() : null ),
                mensualiteFinale: !rachatAuto ? null : ( this.rachat != undefined ? this.rachat.mensualiteFinale() : null ),
                datePremierMoisVersement: !rachatAuto ? null : this.rachatFormGroup.get( 'datePremierMoisRachat' ).value,
                typeEcheancier: this.rachatFormGroup.get( 'rachatEnPlusFois' ).value ? null : this.rachatFormGroup.get( 'echeancier' ).value,
                echeancier: this.isEchancierMan() ? this.rachatFormGroup.get( 'pjointe' ).value : []

            }

            return result;
        }
    }

    getDateEffetAdhesion() {
        if ( this.garantiesFormGroup.get( 'dateEffet' ).value == null ) { // Undefined or null


            var firstDayOfNextMonth = moment().add( 1, 'month' ).startOf( 'month' );
            this.garantiesFormGroup.get( 'dateEffet' ).patchValue( firstDayOfNextMonth );
            this.garantiesFormGroup.updateValueAndValidity();
        }

        return this.garantiesFormGroup.get( 'dateEffet' ).value;
    }

    getAdhesionFromSaisie() {
        if ( this.garantie != undefined ) {
            this.initialAdhesion.beneficiaires = this.garantie.nouvelleAdhesion.beneficiaires;
        }
        return {
            id: this.initialAdhesion == null ? null : this.initialAdhesion.id,
            idExterne: this.initialAdhesion == null ? null : this.initialAdhesion.idExterne,
            idDeTransfertPourSauvegarde: this.initialAdhesion == null ? null : this.initialAdhesion.idDeTransfertPourSauvegarde,
            adherent: this.getAdherentFromSaisie(),
            collectivite: this.getCollectiviteFromSaisie(),
            dateEffetSouhaiteeAdhesion: this.getDateEffetAdhesion(),
            dateSignature: null,
            faitA: null,
            taux: this.garantiesFormGroup.get( 'taux' ).value,
            libelleEtatAdhesion: null,
            codeEtatAdhesion: "EnCoursSaisie",
            //optionGarantieDeces: this.garantiesFormGroup.get( 'garantieDC' ).value,
            optionGarantieDeces: true,
            clauseDecesStandard: this.garantiesFormGroup.get( 'clauseGarantieDC' ).value,
            dateEffetGarantieDeces: this.garantiesFormGroup.get( 'dateEffet' ).value,
            beneficiaires: !this.garantiesFormGroup.get( 'clauseGarantieDC' ).value ? this.initialAdhesion.beneficiaires : [],
            rachat: this.getRachatFromSaisie(),
            cGsDocumentsValideesValidees: this.cgFormGroup.get( 'getcGsDocumentsValidees' ).value,
            cGsEtMLValidees: this.cgFormGroup.get( 'getcGsEtMLValidees' ).value,
            cPreuveValidees: true
        };
    }

    checkCGsFormaGroupValid() {
       /* let disabled = false;
        for ( let i in this.cgFormGroup.controls ) {
            if ( this.cgFormGroup.controls[i].disabled ) {
                disabled = true;
                break;
            }
        }
        if ( !disabled ) {
            this.cgFormGroup.patchValue( {
                toSign: "Fait"
            } );

        } else {
            this.cgFormGroup.patchValue( {
                toSign: null
            } );
        }*/
        if ( this.cgFormGroup.valid ) {
            this.myStepper.next();
        } else {
            this.notificationService.warn( 'Vous devez consulter chaque document et déclarer en avoir pris connaissance pour passer à l\'étape suivante. ' );
        }

    }
    signer() {
        this.checkCGsFormaGroupValid();
    }

    declencherSignature() {
        // this.disableSaisie();
        if ( this.useCaptcha ) {
            this.executeCapchaSignature();
        } else {
            this.demanderSignature( null );
        }
    }

    onSelectionChange( event ) {
        this.newAdhService.changeAdhesion( this.getAdhesionFromSaisie() );
        event.previouslySelectedStep.completed = true;
        var formSelected = event.selectedStep.stepControl;
       
        //ouverture de la notice à l'ouverture de la page CGs
        if ( event.selectedIndex == 5 && !this.noticeDownLoaded ) {
            this.noticeDownLoaded = true;
            this.cgPart.downloadNotice();
        }

        if ( formSelected != undefined && formSelected.dirty ) {

            /*  for ( let i in event.selectedStep.stepControl.controls ) {
                  if ( formSelected.controls[i].enabled ) {
                      formSelected.controls[i].markAsTouched();
                      formSelected.controls[i].markAsDirty();
                      formSelected.controls[i].updateValueAndValidity();
                  }
              }*/
        } else {
            //cas de recap où pas de forme groupe
            event.selectedStep.completed = true;
        }
    }

    doFormSubmitted() {
        if ( this.myStepper.selectedIndex == 0 ) {
            this.adherent.formSubmitted = true;

            if ( this.hasAdressError( this.adherentFormGroup ) ) {
                this.adherent.expandAdresse();
            }
        } else {
            if ( this.myStepper.selectedIndex == 1 ) {
                if ( this.hasAdressError( this.collectiviteFormGroup ) ) {
                    this.collectivitePart.expandAdresse();
                }
            }
        }
    }

    suivant( formGroup: FormGroup ) {
        this.doFormSubmitted();
        if ( formGroup.invalid ) {
            for ( let i in formGroup.controls )
                formGroup.controls[i].markAsTouched();
            this.notificationService.warn( 'Votre formulaire contient des erreurs. Veuillez corriger ces erreurs pour passer à l\'étape suivante. ' );
        } else {
            this.myStepper.next();
        }
    }

    getLabelBoutonEffacer() {
        if ( this.media.isActive( 'xs' ) || this.media.isActive( 'sm' ) ) {
            return "Efaccer";
        }
        return "Effacer votre saisie";

    }

    getLabelBoutonSauvegarder() {
        if ( this.media.isActive( 'xs' ) || this.media.isActive( 'sm' ) ) {
            return "Sauvegarder";
        }
        return "Sauvegarder pour revenir ultérieurement";

    }

    goTo( index ) {
        this.myStepper.selectedIndex = index;
    }

    editerRachat() {
        this.notificationService.wait( 'Le téléchargement de votre échéancier provisoire est en cours.<br><br>Veuillez patienter.', false );
        this.adhesionEnLigneService.editerRachat( this.getAdhesionFromSaisie() ).then( observable => observable.subscribe(
            result => {
                this.notificationService.close();
                this.downloadHelperService.saveOrOpenBlob( result, 'rachat.pdf' );
            }
        ) );
    }

    downloadAdhesionAdherent() {
        this.adhesionEnLigneService.adhesionAdherent( this.getAdhesionFromSaisie() ).then( observable => observable.subscribe(
            result => {
                this.downloadHelperService.saveOrOpenBlob( result, 'adhesion.pdf' );
            }
        ) );
    }

    downloadAdhesionCollectivite() {
        this.adhesionEnLigneService.adhesionCollectivite( this.getAdhesionFromSaisie() ).then( observable => observable.subscribe(
            result => {
                this.downloadHelperService.saveOrOpenBlob( result, 'precompte.pdf' );
            }
        ) );
    }

    dateEffetChange() {
        if ( this.rachatFormGroup.get( 'dateDebutRachat' ).touched || this.rachatFormGroup.get( 'dateDebutRachat' ).dirty ) {
            this.rachatFormGroup.get( 'dateDebutRachat' ).markAsDirty();
            this.rachatFormGroup.get( 'dateDebutRachat' ).updateValueAndValidity();
        }

        if ( this.rachatFormGroup.get( 'dateFinRachat' ).touched || this.rachatFormGroup.get( 'dateFinRachat' ).dirty ) {
            this.rachatFormGroup.get( 'dateFinRachat' ).markAsDirty();
            this.rachatFormGroup.get( 'dateFinRachat' ).updateValueAndValidity();
        }
    }

    pieceidentiteVerified( event ) {
        this.initialAdhesion.adherent = event;
        this.pieceIdentiteAverifier = false;
        this.pieceIdentiteModeModification = false;
    }

    nouvelleAdhesionFromInterne() {
        this.pieceIdentiteAverifier = true;
        this.initialAdhesion.adherent = {};
        this.initialAdhesion.adherent.pieceIdentite = [];
        this.saisieInterneTerminee = false;
        this.pieceIdentiteAverifier = true;
        this.resetFormulaireAderent();
    }

    nouvelleAdhesionMemeEluFromInterne() {
        this.saisieInterneTerminee = false;
        //this.initialAdhesion.adherent.pieceIdentiteExistante = !this.initialAdhesion.adherent.controleManuelIdentiteAFaire;

        this.initialAdhesion.adherent.bulletinAdhesionPapier = [];
        this.initialAdhesion.adherent.pieceIdentite = this.initialAdhesion.adherent.pieceIdentite;
        //this.newAdhService.getPiecesForNewSaisie( this.initialAdhesion.adherent );
        this.adherentFormGroup.get( 'biaPapier' ).reset();
        //console.log( this.initialAdhesion.adherent );

    }

    modifierIdentite() {
        this.pieceIdentiteModeModification = true;
    }

    nouvelleAdhesion(){
        this.resetAdhesion()
    }
    
    
    telechargerAdhesion() {
        this.adhesionEnLigneService.documentsAdhesionAImprimer( this.getAdhesionFromSaisie()).then( observable => observable.pipe(
            this._scavenger.collect()
        ).subscribe( result => {
            this.downloadHelperService.saveOrOpenBlobResponse( result );
            this.documentsTelecharges=true
         } ) );
    }

    isVerifAutoOK(){
        return  this.initialAdhesion && this.initialAdhesion.adherent && this.initialAdhesion.adherent.controleManuelIdentiteAFaire === false
    }
}
