import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { trigger, transition, animate, style } from '@angular/animations';

@Component( {
    selector: 'app-snack-wait',
    templateUrl: './snack-wait.component.html',
    styleUrls: ['./snack-wait.component.scss']
} )
export class SnackWaitComponent implements OnInit {
    message: string;
    buttonCloseShown: boolean;
    constructor( @Inject( MAT_SNACK_BAR_DATA ) public data: any, public snackBarRef: MatSnackBarRef<SnackWaitComponent> ) {
        this.message = data.msg;
        this.buttonCloseShown = data.buttonCloseShown;
    }

    ngOnInit() {
    }


}
