import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from "@angular/common";
import { Decimal } from "decimal.js/decimal";

@Pipe( {
    name: 'decimalEuro'
} )
export class DecimalEuroPipe implements PipeTransform {

    constructor( private currencyPipe: CurrencyPipe ) { }

    transform( value: any, showSymbol: boolean = true ): any {
        return this.currencyPipe.transform(( value instanceof Decimal ) ? value.toNumber() : value, 'EUR', showSymbol ? 'symbol' : '' );
    }

}
