import { Directive } from '@angular/core';
import { HostBinding } from "@angular/core";
import { ElementRef } from "@angular/core";
import { Renderer2 } from "@angular/core";
import { Input } from "@angular/core";

@Directive( {
    selector: '[appTypeDemande]'
} )
export class TypeDemandeDirective {

    static readonly attributeName = "data-type-demande-directive-previous-class";

    @Input( 'appTypeDemande' ) codeType: string;

    constructor( private el: ElementRef, private renderer: Renderer2 ) {
    }

    ngOnChanges() {
        const previousClass = this.el.nativeElement.getAttribute( TypeDemandeDirective.attributeName );
        if ( previousClass ) {
            this.renderer.removeClass( this.el.nativeElement, previousClass );
            this.renderer.removeAttribute( this.el.nativeElement, TypeDemandeDirective.attributeName );
        }

        let newClass = null;
        switch ( this.codeType ) {
            case 'NEWADH':
                newClass = 'fa-sign-in-alt';
                break;
            case 'RIB':
                newClass = 'fa-money-check';
                break;
            case 'GDC':
                newClass = 'fa-umbrella';
                break;
            case 'RETRAITE':
                newClass = 'fa-umbrella-beach';
                break;
            case 'BENIF':
                newClass = 'fa-users';
                break;
            case 'CHTX':
                newClass = 'fa-percentage';
                break;
            case 'RCHT':
                newClass = 'fa-coins';
                break;
            case 'SUSP':
                newClass = 'fa-pause';
                break;
            case 'AUTRE':
                newClass = 'fa-comment-dots';
                break;
        }

        if ( newClass ) {
            this.renderer.setAttribute( this.el.nativeElement, TypeDemandeDirective.attributeName, newClass );
            this.renderer.addClass( this.el.nativeElement, newClass );
        }
    }
}

