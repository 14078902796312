import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { throwError } from 'rxjs';
import {
    AdhesionEnLigneAuthResourceService,
    AdhesionEnLigneNoAuthResourceService,
     AELAdhesionDTO, 
     AELAdherentDTO
} from '@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist';

@Injectable()
export class AdhesionEnLigneResourceService {

    constructor( private adhesionEnLigneNoAuthResourceService: AdhesionEnLigneNoAuthResourceService,
        private adhesionEnLigneAuthResourceService: AdhesionEnLigneAuthResourceService,
        private keycloackService: KeycloakService ) {
    }

    /**
     * Permet de générer une nouvelle adhésion
     * @param body Edition rachat
     */
    public adhesionAdherent( body: AELAdhesionDTO ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.adhesionAdherent( body ) : this.adhesionEnLigneNoAuthResourceService.adhesionAdherent( body ) );
    }


    /**
     * Permet de générer une nouvelle adhésion
     * @param body Edition rachat
     */
    public adhesionCollectivite( body: AELAdhesionDTO ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.adhesionCollectivite( body ) : this.adhesionEnLigneNoAuthResourceService.adhesionCollectivite( body ) );
    }


    /**
     * Permet de récupérer un document issu de la GED
     * @param idDocument L'identifiant du document
     */

    public downloadDocument( idDocument: string ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.downloadDocument( idDocument ) : this.adhesionEnLigneNoAuthResourceService.downloadDocument( idDocument ) );
    }

    public downloadDocumentNoAuth( idDocument: string ) {
        return this.adhesionEnLigneNoAuthResourceService.downloadDocument( idDocument );
    }

    public downloadNotice() {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.downloadNotice() : this.adhesionEnLigneNoAuthResourceService.downloadNotice() );
    }
    public downloadCpreuve() {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.downloadConventionPreuve() : this.adhesionEnLigneNoAuthResourceService.downloadConventionPreuve() );
    }

    public downloadDocumentsSignesContenusDansEnveloppe(enveloppeId: string) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? throwError("") : this.adhesionEnLigneNoAuthResourceService.downloadDocumentsSignesContenusDansEnveloppe(enveloppeId) );
    }

    /**
     * Permet de récupérer le fichier pdf d'adhésion
     * @param id L'identifiant du document
      */
    public downloadRecapAdhesion( id: string ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.downloadRecapAdhesion( id ) : this.adhesionEnLigneNoAuthResourceService.downloadRecapAdhesion( id ) );
    }

    /**
     * Permet d'editer un rachat
     *
     * @param body Edition rachat
      */

    public editerRachat( body: AELAdhesionDTO ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.editerRachat( body ) : this.adhesionEnLigneNoAuthResourceService.editerRachat( body ) );
    }

    /**
     * Renvoie l'adhésion en cours de saisie
     * @param id L'identifiant de l'adhesion en cours
     */
    public getAdhesionEnCours( id: string ) {

        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.getAdhesionEnCours( id ) : this.adhesionEnLigneNoAuthResourceService.getAdhesionEnCours( id ) );
    }

    /**
     * Permet d'obtenir des informations sur une collectivite via son siret
     * 
     * @param siret numero de siret
     */
    public getCollectiviteBySiret( siret: string ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.getCollectiviteBySiret( siret ) : this.adhesionEnLigneNoAuthResourceService.getCollectiviteBySiret( siret ) );
    }

    /**
     * Permet de récupérer les mandats
     * 
     * Rem: l'écriture en async revient au mem que promise.then
     */
    public getListeMandats() {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.getListeMandats() : this.adhesionEnLigneNoAuthResourceService.getListeMandats() );
    }


    /**
     * Permet de sauvegarder une adhésion
     * @param body La demande de retraite
     * @param recaptcha recaptcha
     */
    public sauvegarderAdhesion( body: AELAdhesionDTO, recaptcha: string ) {
        return this.keycloackService.isLoggedIn().then((loggin: boolean) => loggin ? this.adhesionEnLigneAuthResourceService.sauvegarderAdhesion( body, recaptcha) : this.adhesionEnLigneNoAuthResourceService.sauvegarderAdhesion(recaptcha, body));
    }

    public sauvegarderAdhesionPapier( body: AELAdherentDTO ) {
        return this.adhesionEnLigneNoAuthResourceService.sauvegarderAdhesionPapier( body );
    }
    public controlerAdhesionPapier( body: AELAdherentDTO ) {
        return this.adhesionEnLigneNoAuthResourceService.controlerAdhesionPapier( body );
    }

    public demanderSignature( body: AELAdhesionDTO, recaptcha: string ) {
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? this.adhesionEnLigneAuthResourceService.demanderSignature( body, recaptcha ) : this.adhesionEnLigneNoAuthResourceService.demanderSignature( recaptcha, body ) );
    }

    public documentsAdhesionAImprimer( adhesion: AELAdhesionDTO ){
        return this.keycloackService.isLoggedIn().then(( loggin: boolean ) => loggin ? throwError("") : this.adhesionEnLigneNoAuthResourceService.documentsAdhesionAImprimer( adhesion,'response' ) );
    }

    public envoyerSMS( id: string ) {
        return this.adhesionEnLigneNoAuthResourceService.envoyerSMS( id );
    }
    public verifierCoderSMS( codeSMS: string, id: string ) {
        return this.adhesionEnLigneNoAuthResourceService.verifierCodeSMS( id, codeSMS );
    }

    public getFreshAriadFrame() {
        return this.adhesionEnLigneNoAuthResourceService.generateWebsdkFrame();
    }
    public getFreshAriadContactByPhone( numMobile ) {
        return this.adhesionEnLigneNoAuthResourceService.generateWebsdkContact( numMobile );
    }

    public getAFile( fileUid: string, onboardinId: string ) {
        return this.adhesionEnLigneNoAuthResourceService.getAFile( fileUid, onboardinId );

    }

    public getNotification( resourceId: string ) {
        return this.adhesionEnLigneNoAuthResourceService.getNotification( resourceId );
    }

}
