import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { CollectiviteDTO, DownloadResourceService, AdherentResourceService, EluDTO, AELAdhesionDTO, PieceJustificativeDTO, AELAdherentDTO } from '@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist';
import { GlobalLoadingService } from "src/app/api/global-loading.service";
import { Decimal } from 'decimal.js/decimal';
import { HttpResponse } from "@angular/common/http";
import { DialogService } from "src/app/api/dialog.service";
import { Scavenger } from '@wishtack/rx-scavenger';
import { ActivatedRoute } from "@angular/router";
@Injectable()

export class NewAdhService {
    private _adhesionItemSource = new ReplaySubject<AELAdhesionDTO>();
    adhesionItem$ = this._adhesionItemSource.asObservable();

    constructor( private serviceDialog: DialogService, private route: ActivatedRoute ) { }


    changeAdhesion( adhesion: AELAdhesionDTO ) {
        this._adhesionItemSource.next( adhesion );
    }

    openDialogMessageEtatAdhesion( codeEtatAdhesion, _scavenger: Scavenger ) {
        this.serviceDialog.openMessageDialog(
            this.getMessageEtatAdhesion( codeEtatAdhesion ) )
            .afterClosed().pipe(
            _scavenger.collect()
            ).subscribe( res => {

            },
            error => {
                this.serviceDialog.openErrorDialog( "Une erreur s'est produite." );
            } );
    }

    getMessageEtatAdhesion( codeEtatAdhesion ) {
        if ( codeEtatAdhesion == 'ENATTENTESIGNATURE' ) {
            return '<b>Votre adhésion est en attente de signature.</b> Vous ne pouvez plus y accéder en modification. <br><br>' +
                'Pour reprendre la signature de cette adhésion, veuillez utiliser le lien contenu dans le mail qui vous a été adressé.' +
                '<br> <br>Si vous souhaitez souscrire une nouvelle adhésion, veuillez fermer cette fenêtre et cliquer sur le bouton <b>Je souhaite adhérer</b>';
        }
        if ( codeEtatAdhesion == 'SIGNEE' ) {
            return '<b>Vous avez signée cette adhésion.</b> Vous ne pouvez plus y accéder en modification.' +
                ' <br> <br> Si vous souhaitez souscrire une nouvelle adhésion, veuillez fermer cette fenêtre et cliquer sur le bouton <b>Je souhaite adhérer</b>';
        }

        if ( codeEtatAdhesion == 'ABANDON' ) {
            return '<b>Vous avez refusé la signature de cette adhésion.</b> Vous ne pouvez plus y accéder en modification.' +
                ' <br> <br> Si vous souhaitez souscrire une nouvelle adhésion, veuillez fermer cette fenêtre et cliquer sur le bouton <b>Je souhaite adhérer</b>';
        }
    }

    getAdhesionForNewSaisie( adhesionChargee ) {
        return {
            id: null,
            idDeTransfertPourSauvegarde: null,
            idExterne: null,
            adherent: this.getAdherentForNewSaisie( adhesionChargee ),
            collectivite: {},
            dateEffetSouhaiteeAdhesion: null,
            dateSignature: null,
            faitA: null,
            taux: null,
            libelleEtatAdhesion: null,
            codeEtatAdhesion: "EnCoursSaisie",
            optionGarantieDeces: false,
            dateEffetGarantieDeces: null,
            beneficiaires: [],
            rachat: null,
            getcGsDocumentsValidees: false,

            getcGsEtMLValidees: false,
            getcPreuveValidees: false,

        }
    }
    getPiecesForNewSaisie( adherent: AELAdherentDTO ): Array<PieceJustificativeDTO> {
        let data: Array<PieceJustificativeDTO> = new Array<PieceJustificativeDTO>();
        for ( let piece of adherent.pieceIdentite ) {
            if ( !piece.watermarked ) {
                data.push( piece );
            }
        }
        return data;
    }

    getAdherentForNewSaisie( adhesionChargee ) {

        return {

            id: null,
            idAppliCommercialWindev: null,
            idExterne: ( adhesionChargee == null || adhesionChargee.adherent == null ) ? null : adhesionChargee.adherent.idExterne,
            codeCivilite: adhesionChargee.adherent.codeCivilite,
            nomPatronymique: adhesionChargee.adherent.nomPatronymique,
            nomUsage: adhesionChargee.adherent.nomUsage,
            prenom: adhesionChargee.adherent.prenom,
            lieuNaissance: adhesionChargee.adherent.lieuNaissance,
            dateNaissance: adhesionChargee.adherent.dateNaissance,
            numeSecu: adhesionChargee.adherent.numeSecu,
            adresse: adhesionChargee.adherent.adresse,
            mail: adhesionChargee.adherent.mail,
            telephone: adhesionChargee.adherent.telephone,
            portable: adhesionChargee.adherent.portable,
            fonction: null,
            pieceIdentite: adhesionChargee.adherent.pieceIdentite, // this.getPiecesForNewSaisie( adhesionChargee.adherent ),
            idAFile: adhesionChargee.adherent.idAFile,
            statutAnalyse: adhesionChargee.adherent.statutAnalyse,
            statutAnalyseCheckSumMrz: adhesionChargee.adherent.statutAnalyseCheckSumMrz,

            idFonctionnel: adhesionChargee.adherent.idFonctionnel,
            controleManuelIdentiteAFaire: adhesionChargee.adherent.controleManuelIdentiteAFaire,
            controleManuelPPEAFaire: adhesionChargee.adherent.controleManuelPPEAFaire,
            controleManuelGDAAFaire: adhesionChargee.adherent.controleManuelGDAAFaire,
            codePaysNaissance: adhesionChargee.adherent.codePaysNaissance,
            paysNaissance: adhesionChargee.adherent.paysNaissance,
            numeroPieceIdentite: adhesionChargee.adherent.numeroPieceIdentite,
            nationalite: adhesionChargee.adherent.nationalite,
            typePieceIdentite: adhesionChargee.adherent.typePieceIdentite,
            dateValiditePiece: adhesionChargee.adherent.dateValiditePiece,
            dateEmissionPiece: adhesionChargee.adherent.dateEmissionPiece,

            ppe: {
                adherentPPE: adhesionChargee.adherent.ppe.adherentPPE,
                adherentFonctionPPE: adhesionChargee.adherent.ppe.adherentFonctionPPE,
                adherentPaysExerciceFonction: adhesionChargee.adherent.ppe.adherentPaysExerciceFonction,

                entouragePPE: adhesionChargee.adherent.ppe.entouragePPE,
                entourageFonctionPPE: adhesionChargee.adherent.ppe.entourageFonctionPPE,
                entourageLien: adhesionChargee.adherent.ppe.entourageLien,
                entourageNom: adhesionChargee.adherent.ppe.entourageNom,
                entourageNomNaissance: adhesionChargee.adherent.ppe.entourageNomNaissance,
                entouragePrenom: adhesionChargee.adherent.ppe.entouragePrenom,
                entourageDateNaissance: adhesionChargee.adherent.ppe.entourageDateNaissance,
                entouragePaysExerciceFonction: adhesionChargee.adherent.ppe.entouragePaysExerciceFonction
            }



        };
    }

    openInfoDialogue( message, _scavenger: Scavenger ) {
        this.serviceDialog.openMessageDialog( message ).afterClosed().pipe( _scavenger.collect() ).subscribe( data => {
            this.route.url.pipe( _scavenger.collect() ).subscribe( url => {
            } );
        } );
    }
    openBienVenueDialogue( message, envelopeId, _scavenger: Scavenger ) {
        this.serviceDialog.openBienVenueDialog( message ,envelopeId).afterClosed().pipe( _scavenger.collect() ).subscribe( data => {
            this.route.url.pipe( _scavenger.collect() ).subscribe( url => {
            } );
        } );
    }
    openErrorDialogue( message, _scavenger: Scavenger ) {
        this.serviceDialog.openErrorDialogWithWidth( message, '600px' ).afterClosed().pipe( _scavenger.collect() ).subscribe( data => {
            this.route.url.pipe( _scavenger.collect() ).subscribe( url => {
            } );
        } );
    }
    handleEventDocussign( codeEvent, phoneGestion, _scavenger: Scavenger, envelopeId ) {
        let adhesionApreremplir = false;
        if ( codeEvent === 'signing_complete' ) {
            adhesionApreremplir = true;
            const message =
                ' <div class="text--center"><span class="mt-3"><b>Vous venez de signer électroniquement votre demande d\'adhésion.</b></span> </div><br><br>'
                + '<span class="mt-3">Nous vous avons adressé un mail vous permettant de consulter les documents signés liés à votre adhésion.</span><br>'
                + '<span class="mt-3">Votre adhésion sera prise en charge dans les plus brefs délais et un certificat vous sera adressé pour confirmer la validité de celle-ci.</span><br><br>'
                + '<span>Si vous souhaitez souscrire une nouvelle adhésion, veuillez fermer cette fenêtre et cliquer sur le bouton <b>Je souhaite adhérer</b></span><br><br>'
                + '<div class="text--center"> <span class="mt-3"><b>Nous vous remercions de votre confiance.<b></span> </div>';

            this.openBienVenueDialogue( message, envelopeId,_scavenger );
        }
        if ( codeEvent === 'viewing_complete' ) {
            this.openInfoDialogue( 'Merci d\'avoir consulté le document.<br><br>' +
                'Si vous souhaitez reprendre la signature de votre adhésion, utilisez le lien contenu dans le mail qui vous a été adressé.', _scavenger )
        }
        if ( codeEvent === 'decline' ) {
            adhesionApreremplir = true;
            this.openInfoDialogue( 'Votre refus de signer a été pris en compte.<br><br>' +
                'Si vous souhaitez souscrire une nouvelle adhésion, veuillez fermer cette fenêtre et cliquer sur le bouton <b>Je souhaite adhérer</b>', _scavenger );
        }
        if ( codeEvent === 'cancel' ) {
            this.openInfoDialogue(
                'Si vous souhaitez reprendre la signature de votre adhésion, utilisez le lien contenu dans le mail qui vous a été adressé.', _scavenger );
        }
        if ( codeEvent === 'ttl_expired' ) {
            this.openInfoDialogue( 'Le délai pour accéder au document a expiré.<br><br>' +
                'Si vous souhaitez reprendre la signature de votre adhésion, utilisez le lien contenu dans le mail qui vous a été adressé.', _scavenger );
        }
        if ( codeEvent === 'session_timeout' ) {
            this.openInfoDialogue( 'La durée de la session de signature a expiré.<br><br>' +
                ' Si vous souhaitez reprendre la signature de votre adhésion, utilisez le lien contenu dans le mail qui vous a été adressé.', _scavenger );
        }
        if ( codeEvent === 'exception' ) {
            this.openErrorDialogue( 'Un incident technique est survenu pendant le processus de signature de votre adhésion.<br><br>' +
                'Pour reprendre la signature de votre adhésion, utilisez le lien contenu dans le mail qui vous a été adressé.<br><br>' +
                'Si l\'incident persiste, contactez-nous au ' + phoneGestion + '.', _scavenger );
        }
        if ( codeEvent === 'service_signature_indisponible' ) {
            this.openErrorDialogue( 'Le service de signature électronique est actuellement indisponible.<br><br>' +
                'Pour reprendre la signature de votre adhésion, utilisez le lien contenu dans le mail qui vous a été adressé.<br > <br>' +
                'Si l\'incident persiste, contactez-nous au ' + phoneGestion + '.', _scavenger );
        }
        return adhesionApreremplir;
    }
}
