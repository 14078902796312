import { Component, OnInit } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { EluDTO, DemandeResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { DialogService } from "src/app/api/dialog.service";
import { ViewEncapsulation } from "@angular/core";
import { AfterSubmitErrorStateMatcher } from "src/app/shared/after-submit-error-state-matcher";

@Component( {
    selector: 'app-demande-generique',
    templateUrl: './demande-generique.component.html',
    styleUrls: ['./demande-generique.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class DemandeGeneriqueComponent implements OnInit {
    matcher: AfterSubmitErrorStateMatcher;
    @Input() elu: EluDTO;

    ngOnInit() {
    }

    form: FormGroup;
    formSubmitted: boolean = false;

    protected demandeEnCoursSubject = new BehaviorSubject<boolean>( false );

    protected demandeEnCours$ = this.demandeEnCoursSubject.asObservable();

    constructor( private dialogService: DialogService, private formBuilder: FormBuilder, private demandeResourceService: DemandeResourceService ) {
        this.form = this.formBuilder.group( { pjointe: null, descriptif: null } );
        this.matcher = new AfterSubmitErrorStateMatcher( this );

    }

    envoiDemande() {

        this.formSubmitted = true;

        if ( this.form.valid ) {
            this.demandeEnCoursSubject.next( true );
            this.demandeResourceService.emettreDemandeGenerique( { idAdherent: this.elu.id, pjointes: this.form.get( 'pjointe' ).value, demande: this.form.get( 'descriptif' ).value } ).subscribe(
                data => {
                    this.cleanFields();
                    this.demandeEnCoursSubject.next( false );
                    this.dialogService.openWarningDialog( "Votre <b>demande</b> est <b>en cours de traitement</b>.<br><br>Vous pouvez suivre l’avancement du traitement de votre demande en consultant la rubrique «Démarches en ligne» de votre espace.<br>" );
                },
                error => {
                    this.cleanFields();
                    this.demandeEnCoursSubject.next( false );
                    this.dialogService.openWarningDialog( "Une erreur s'est produite lors de l'emission de la demande.<br>Merci de réessayer ulterieurement.</b>" );
                }
            );
        }

    }

    cleanFields() {
        this.form.patchValue( { pjointe: null, descriptif: null } );
        this.formSubmitted = false;
    }
}
