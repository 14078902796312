import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Input } from "@angular/core";
import { UtilsResourceService, Pays, AdresseDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { ViewEncapsulation } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ControlContainer } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Validators } from "@angular/forms";

@Component( {
    selector: 'app-adressDialog-part',
    templateUrl: './adressDialog-part.component.html',
    styleUrls: ['./adressDialog-part.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    encapsulation: ViewEncapsulation.None
} )
export class AdressDialogPartComponent implements OnInit {
    public listePays: Array<Pays>;
    public listeVilles: Array<String>;
    formGroup: FormGroup;
    PaysParDefaut: Pays;
    public ZipCodeRegex = /^\d{5}$/;
    adresse: AdresseDTO;

    constructor( public dialogRef: MatDialogRef<AdressDialogPartComponent>, @Inject( MAT_DIALOG_DATA ) public data, private utilsService: UtilsResourceService, private _formBuilder: FormBuilder ) {
        this.adresse = data.adresse;
    }
    getPays() {
        this.PaysParDefaut = { codePays: 235, libelle: "FRANCE" };
        if ( this.adresse == undefined || this.adresse == null || this.adresse.pays == null ) {
            return this.PaysParDefaut;
        }
        return this.adresse.pays;
    }
    ngOnInit() {
        this.formGroup = this._formBuilder.group( {
            ligne1: [( this.adresse || {} ).ligne1, Validators.maxLength( 40 )],
            ligne2: [( this.adresse || {} ).ligne2, [Validators.required, Validators.maxLength( 40 )]],
            ligne3: [( this.adresse || {} ).ligne3, Validators.maxLength( 40 )],
            codePostal: [( this.adresse || {} ).codePostal || null, Validators.required],
            ville: [( this.adresse || {} ).ville, Validators.required],
            pays: [this.getPays(), Validators.required]
        } );
        this.utilsService.getListePays().subscribe( result => {
            this.listePays = result;

        } )
    }
    ngAfterViewInit() {
        if ( this.formGroup.get( 'pays' ) !== undefined && this.formGroup.get( 'pays' ).value.codePays == 235 ) {
            this.formGroup.get( 'codePostal' ).setValidators( [Validators.required, Validators.pattern( this.ZipCodeRegex )] );
            this.formGroup.get( 'codePostal' ).updateValueAndValidity();
        }
    }
    compareObjects( o1: Pays, o2: Pays ): boolean {
        return o1 && o2 ? o1.codePays === o2.codePays : o1 === o2;
    }
    codePostalModifie( $value: string ) {
        this.formGroup.get( 'ville' ).setValue( '' );
        this.villeParCodePostal( $value );
    }

    villeParCodePostal( $codePostal: string ) {
        this.listeVilles = [];
        if ( $codePostal.length >= 3 && this.formGroup.get( 'pays' ).value.codePays == 235 ) {
            this.utilsService.getCodePostauxVillesParCodePostal( $codePostal ).subscribe( result => {
                for ( const entry of Object.entries( result ) ) {
                    let codePostal = Object.keys( entry[1] )[0];
                    let ville = Object.values( entry[1] )[0];
                    this.listeVilles.unshift( codePostal + " " + ville );
                }
            } )
        }

    }

    onSelectionCodePostal( entry: string ) {
        this.formGroup.get( 'codePostal' ).setValue( entry.substring( 0, 5 ) );
        this.formGroup.get( 'ville' ).setValue( entry.substring( 5 ) );
    }

    onRecherchePaysChange() {
        if ( this.formGroup.get( 'pays' ).value.codePays == 235 ) {
            this.formGroup.get( 'codePostal' ).setValidators( [Validators.required, Validators.pattern( this.ZipCodeRegex )] );
        } else {

            this.formGroup.get( 'codePostal' ).setValidators( [Validators.required] );
        }
        this.listeVilles = [];
        this.formGroup.get( 'codePostal' ).setValue( '' );
        this.formGroup.get( 'ville' ).setValue( '' );
        this.formGroup.get( 'codePostal' ).updateValueAndValidity();
    }
    onNoClick(): void {
        this.dialogRef.close( { valid: false } );
    }
    toUpperCase( chaine ) {
        if ( chaine !== undefined && chaine != null ) {
            return chaine.toUpperCase();
        }
        return chaine;

    }

    onValider(): void {
        for ( let i in this.formGroup.controls )
            this.formGroup.controls[i].markAsTouched();
        if ( this.formGroup.valid ) {
            this.dialogRef.close( {
                valid: true, adresse: {
                    ligne1: this.formGroup.get( "ligne1" ).value,
                    ligne2: this.formGroup.get( "ligne2" ).value,
                    ligne3: this.formGroup.get( "ligne3" ).value,
                    codePostal: this.formGroup.get( "codePostal" ).value,
                    ville: this.toUpperCase( this.formGroup.get( "ville" ).value ),
                    pays: this.formGroup.get( "pays" ).value
                }
            } );
        }
    }
}
