import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Component, OnInit } from '@angular/core';
import { AppConfig } from "src/app/utils/app.config";
import { ActivatedRoute } from "@angular/router";
import { DocumentDTO, DocumentationResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { DownloadHelperService } from "src/app/api/dowload-helper.service";
import { NewAdhService } from "src/app/api/new-adh.service";
import { ContactDialogComponent } from 'src/app/shared/components/new-adh-form-parts/contactDialog/contactDialog.component';
import { combineLatest } from "rxjs";
import { CgComponent } from "src/app/shared/components/new-adh-form-parts/cg/cg.component";
import { ProtectionDonneesComponent } from 'src/app/shared/components/new-adh-form-parts/protectionDonnees/protectionDonnees.component';
import { DialogService } from "src/app/api/dialog.service";
import { Scavenger } from '@wishtack/rx-scavenger';
import { SmsDialogPartComponent } from "src/app/shared/components/new-adh-form-parts/smsDialog-part/smsDialog-part.component";
import { AELAdhesionDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { BehaviorSubject } from "rxjs";
import { OnDestroy } from "@angular/core";
import { ParamMap } from "@angular/router";
import { RouteParamMap } from "src/app/shared/route-parammap";
import { NotificationService } from "src/app/api/notification.service";
import { SouscriptionInfosDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/souscriptionInfosDialog-part/souscriptionInfosDialog-part.component';
import { KeycloakService } from "keycloak-angular";

@Component( {
    selector: 'app-unauthenticated-new-adhesion',
    templateUrl: './unauthenticated-new-adhesion.component.html',
    styleUrls: ['./unauthenticated-new-adhesion.component.scss']
} )
export class UnauthenticatedNewAdhesionComponent implements OnDestroy, OnInit {

    phoneGestion: string;
    mailGestion: string;
    fonpelDocs: string;
    fonpelFAQ: string;
    start = false;
    provenanceInterne = false;
    documents: DocumentDTO[];
    private _scavenger = new Scavenger( this );
    adhesion: AELAdhesionDTO;

    protected chargementEnCoursSubject = new BehaviorSubject<boolean>( false );

    protected chargementEnCours$ = this.chargementEnCoursSubject.asObservable();

    constructor( private keycloakService: KeycloakService, private config: AppConfig, private route: ActivatedRoute,
        private adhesionEnLigneResourceService: AdhesionEnLigneResourceService,
        private documentationResourceService: DocumentationResourceService,
        private downloadHelperService: DownloadHelperService,
        private dialog: MatDialog,
        private serviceDialog: DialogService,
        private newAdhService: NewAdhService,
        private notificationService: NotificationService ) {

        this.phoneGestion = this.config.getConfig( 'phoneGestion' );
        this.mailGestion = this.config.getConfig( 'mailGestion' );
        this.fonpelDocs = this.config.getConfig( 'fonpelDocs' );
        this.fonpelFAQ = this.config.getConfig( 'fonpelFAQ' );

        const routeParam = combineLatest( this.route.paramMap, this.route.queryParamMap, ( params: ParamMap, queryParams: ParamMap ) => new RouteParamMap( params, queryParams ) );

        routeParam.subscribe(( routeParamMap ) => {

            //console.log( route.snapshot.data );

            this.provenanceInterne = route.snapshot.data['provenanceInterne'] != undefined && route.snapshot.data['provenanceInterne'];

            // console.log( "provenance=" + this.provenanceInterne );

            if ( ( routeParamMap.has( 'event' ) && routeParamMap.has( 'idTransfert' ) ) || routeParamMap.has( 'idDeTransfertPourSauvegarde' ) || routeParamMap.has( 'idAdhesion' ) || this.provenanceInterne ) {

                const id = routeParamMap.get( 'idDeTransfertPourSauvegarde' ) || routeParamMap.get( 'idTransfert' ) || routeParamMap.get( 'idAdhesion' );



                if ( id ) {
                    const idFromDocusign = ( routeParamMap.has( 'event' ) && routeParamMap.has( 'idTransfert' ) );
                    //console.log( "id ici1= " + id );
                    if ( idFromDocusign ) {
                        const codeEvent = routeParamMap.get( 'event' );
                        const idTransfert = routeParamMap.get( 'idTransfert' );
                        const envelopeId = routeParamMap.get( 'envelopeId' );


                        const adhesionApreremplir = newAdhService.handleEventDocussign( codeEvent, this.phoneGestion, this._scavenger, envelopeId );
                        if ( adhesionApreremplir ) {
                            this.chargementEnCoursSubject.next( true );
                            this.adhesionEnLigneResourceService.getAdhesionEnCours( id ).then( observable => observable.pipe( this._scavenger.collect() ).subscribe(
                                adhesion => {
                                    this.chargementEnCoursSubject.next( false );
                                    this.adhesion = newAdhService.getAdhesionForNewSaisie( adhesion );
                                    //console.log( "==id from docusign==========" );
                                    //console.log( this.adhesion.adherent );
                                }, error => {
                                    this.chargementEnCoursSubject.next( false );
                                    this.serviceDialog.openErrorDialog( "Url non valide." );
                                } ) );
                        }

                    } else {
                        // console.log( "id ici2= " + id );
                        if ( this.provenanceInterne ) {
                            this.start = true;
                        } else {
                            // console.log(routeParamMap);
                            this.authentifier( routeParamMap, newAdhService, id );
                        }
                    }

                } else {
                    if ( this.provenanceInterne ) {
                        this.start = true;
                    }

                }
            }

        } );

    }

    authentifier( routeParamMap, newAdhService, id ) {

        this.chargementEnCoursSubject.next( true );
        //console.log( "je suis dans authentifier" );
        this.adhesionEnLigneResourceService.envoyerSMS( id ).subscribe(
            result => {
                this.chargementEnCoursSubject.next( false );
                const dialogRef = this.dialog.open( SmsDialogPartComponent, {
                    width: '800px',
                    disableClose: true,
                    data: {
                        title: "Authentification par SMS", adhesionEnLigneResourceService: this.adhesionEnLigneResourceService, idTransfertAdhesion: id
                    }
                }
                );
                dialogRef.afterClosed().subscribe( result => {
                    if ( result.valid ) {
                        this.chargementEnCoursSubject.next( true );
                        this.adhesionEnLigneResourceService.getAdhesionEnCours( id ).then( observable => observable.pipe( this._scavenger.collect() ).subscribe(
                            adhesion => {
                                this.chargementEnCoursSubject.next( false );
                                //console.log( "=======" );
                                //console.log( adhesion.adherent );
                                if ( adhesion.codeEtatAdhesion == 'ENCOURSSAISIE' ) {
                                    this.adhesion = adhesion;
                                    if ( routeParamMap.has( 'idDeTransfertPourSauvegarde' ) ) {
                                        this.startForm();
                                    }
                                } else {
                                    newAdhService.openDialogMessageEtatAdhesion( adhesion.codeEtatAdhesion, this._scavenger );
                                    this.adhesion = newAdhService.getAdhesionForNewSaisie( adhesion );
                                }
                                this.notificationService.success( "Vous êtes authentifié." );
                            }, error => {
                                this.chargementEnCoursSubject.next( false );
                                this.serviceDialog.openErrorDialog( "Url non valide." );
                            } ) );
                    } else {
                        this.notificationService.warn( 'Vous n\'êtes pas authentifié. L\'accès à cette adhésion n\'est pas autorisé.' );
                    }
                } );

            }, error => {
                this.chargementEnCoursSubject.next( false );

                this.serviceDialog.openErrorDialog( "Url non valide." );
            } );
    }

    ngOnInit() {
        this.documentationResourceService.getDocumentsAEL().subscribe( documents => {
            this.documents = documents;
        } );
    }

    ngOnDestroy() {
        // Laisser cette méthode: Pour Scavenger
    }

    download( doc: DocumentDTO ) {
        if ( this.provenanceInterne ) {
            this.adhesionEnLigneResourceService.downloadDocumentNoAuth( doc.idDocument ).subscribe(
                result => {
                    this.downloadHelperService.saveOrOpenBlob( result, doc.libelle + '.pdf' );
                }
            );
        } else {
            this.adhesionEnLigneResourceService.downloadDocument( doc.idDocument ).then( observable => observable.subscribe(
                result => {
                    this.downloadHelperService.saveOrOpenBlob( result, doc.libelle + '.pdf' );
                }
            ) );
        }
    }

    startForm() {
        this.start = true;
    }

    openDialog(): void {
        const dialogRef = this.dialog.open( ContactDialogComponent, {
            width: '600px'
        } );
    }

    opencg() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.minHeight = 780;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            action: false
        };

        const dialogRef = this.dialog.open( CgComponent, dialogConfig );
    }

    openProtectionDonnees() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        // dialogConfig.height = '800px';
        dialogConfig.width = '800px';
        dialogConfig.data = {
            action: false
        };

        const dialogRef = this.dialog.open( ProtectionDonneesComponent, dialogConfig );
    }


    openSiteFonpel() {
        window.open( this.fonpelDocs, "_blank" );
    }

    libelleCivilite() {
        if ( this.adhesion && this.adhesion.adherent ) {
            return this.adhesion.adherent.codeCivilite == 'MADAME' ? 'Madame' : 'Monsieur';
        }
        return "";
    }

    openInfosSouscription() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.width = '700px';
        dialogConfig.data = {
            action: false
        };

        const dialogRef = this.dialog.open( SouscriptionInfosDialogPartComponent, dialogConfig );
        dialogRef.afterClosed().subscribe( result => {
            if ( result.valid ) {
                this.startForm();

            }
        } );
    }

    async deconnect() {
        await this.keycloakService.logout();
    }

}
