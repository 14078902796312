import { Component, OnInit } from '@angular/core';
import { Moment } from "moment/moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { DateAdapter } from "@angular/material/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as moment from 'moment';

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@Component( {
    selector: 'app-month-date',
    templateUrl: './month-date.component.html',
    styleUrls: ['./month-date.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component levxel here, due to limitations of
        // our example generation script.
        //  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
} )

export class MonthDateComponent implements OnInit {

    @Input() formGroup: FormGroup;

    minDate;

    constructor() {
        this.minDate = moment().startOf( 'month' );
    }

    ngOnInit() {
    }

    get datePremierMoisRachat() { return this.formGroup.get( 'datePremierMoisRachat' ); }

    chosenMonthHandler( normalizedMonth: Moment, datepicker: MatDatepicker<Moment> ) {
        moment.locale( 'fr' );
        if ( normalizedMonth ) {
            const ctrlValue = this.datePremierMoisRachat.value ? this.datePremierMoisRachat.value : normalizedMonth.clone();
            ctrlValue.month( normalizedMonth.month() );
            this.datePremierMoisRachat.setValue( ctrlValue );
            datepicker.close();
        }
    }

}
