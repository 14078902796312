import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

function emptyObjectToNull( obj ) {
    if ( !obj || ( Object.keys( obj ).length === 0 ) ) {
        return null;
    } else {
        return obj;
    }
}

const COHERENCEERRORPROPERTYNAME: string = "ControleCoherencePeriode";

export function ControleCoherencePeriode( controlName: string, controlName2: string ) {
    return ( formGroup: FormGroup ) => {
        const controlDeb = formGroup.controls[controlName];
        const controlFin = formGroup.controls[controlName2];

        let errorsDeb = controlDeb.errors || {};
        if ( errorsDeb.ControleCoherencePeriode ) {
            delete errorsDeb[COHERENCEERRORPROPERTYNAME];
            controlDeb.setErrors( emptyObjectToNull( errorsDeb ) );
        }

        let errorsFin = controlFin.errors || {};
        if ( errorsFin.ControleCoherencePeriode ) {
            delete errorsFin[COHERENCEERRORPROPERTYNAME];
            controlFin.setErrors( emptyObjectToNull( errorsFin ) );
        }

        if ( controlDeb.valid && controlFin.valid && controlDeb.value && controlFin.value && controlDeb.value.isAfter( controlFin.value ) ) {
            errorsDeb[COHERENCEERRORPROPERTYNAME] = true;
            controlDeb.setErrors( errorsDeb );

            errorsFin[COHERENCEERRORPROPERTYNAME] = true;
            controlFin.setErrors( errorsFin );
        }
    }
}


