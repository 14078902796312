import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { KeycloakService, KeycloakConfig } from 'keycloak-angular';
import { throwError } from 'rxjs';

@Injectable()
export class AppConfig {

    private config: Object = null;
    private env: Object = null;

    constructor( private http: Http ) {
    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig( key: any ) {
        return this.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public getEnv( key: any ) {
        return this.env[key];
    }

    /**
     * This method:
     *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
     *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
     */
    public load( keycloackService: KeycloakService ) {
        return new Promise(( resolve, reject ) => {
            this.http.get( './assets/env/env.json' ).pipe( map( res => res.json() ),
                catchError(( error: any ): any => {
                    console.log( 'Configuration file "env.json" could not be read' );
                    resolve( true );
                    return throwError( error.message || 'Server error' );
                } )
            ).subscribe(( envResponse ) => {
                this.env = envResponse;
                let request: any = null;

                switch ( envResponse.env ) {
                    case 'production': {
                        request = this.http.get( './assets/env/env.' + envResponse.env + '.json' );
                    } break;

                    case 'development': {
                        request = this.http.get( './assets/env/env.' + envResponse.env + '.json' );
                    } break;

                    case 'default': {
                        console.error( 'Environment file is not set or invalid' );
                        resolve( true );
                    } break;
                }

                if ( request ) {
                    request.pipe(
                        map(( res: Response ) => res.json() ),
                        catchError(( error: any ) => {
                            console.error( 'Error reading ' + envResponse.env + ' configuration file' );
                            resolve( error );
                            return throwError( error.message || 'Server error' );
                        } )
                    )
                        .subscribe( async ( responseData: any ) => {
                            this.config = responseData;
                            const keycloakConfig: KeycloakConfig = this.getConfig( 'keycloakConfig' );
                            await keycloackService.init( {
                                config: keycloakConfig,
                                initOptions: {
                                    onLoad: 'check-sso',
                                    checkLoginIframe: false
                                },
                                enableBearerInterceptor: true,
                                bearerExcludedUrls: ['/rest/email/validation', '/rest/recaptcha/validation', '/rest/util', '/rest/AdhesionEnLigne/*', '/rest/documentation']
                            } );

                            resolve( true );
                        } );
                } else {
                    console.error( 'Env config file "env.json" is not valid' );
                    resolve( true );
                }
            } );

        } );
    }
}
