import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { UtilsResourceService, Pays, NomenclatureDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';
import { FormGroup } from "@angular/forms";
import { NewAdhService } from "src/app/api/new-adh.service";
import { AELAdhesionDTO, AdhesionEnLigneNoAuthResourceService, AdhesionEnLigneAuthResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { KeycloakService } from 'keycloak-angular';
import { Observable } from "rxjs";
import { ValidateNumSecu } from "src/app/shared/components/new-adh-form/controle-numSecu.validator";
import { Subject } from "rxjs";
import { AELAdherentDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { ViewChild } from "@angular/core";
import { AdressPartComponent } from "src/app/shared/components/new-adh-form-parts/adress-part/adress-part.component";

@Component( {
    selector: 'app-adherent-part',
    templateUrl: './adherent-part.component.html',
    styleUrls: ['./adherent-part.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class AdherentPartComponent implements OnInit {
    public listePays: Array<Pays>;
    public fonctionsPPE: Array<NomenclatureDTO>;
    PaysParDefaut: Pays;
    @Input() formGroup;
    @Input() adherent: AELAdherentDTO;
    @Input() provenanceExterne: boolean;
    nouvelleAdhesion: AELAdhesionDTO;
    private listMandats = new Array<NomenclatureDTO>();
    @Input()
    saisieInterneTerminee;
    @Output() nouvelleAdhesionFromInterne = new EventEmitter();
    @Output() nouvelleAdhesionMemeEluFromInterne = new EventEmitter();
    @Output() modifierIdentite = new EventEmitter();
    @ViewChild( AdressPartComponent ) adressPartComponent;


    stateChanges = new Subject<void>();
    private _dateNaisanceCoherent: boolean;
    private _formSubmitted: boolean;

    set formSubmitted( value: boolean | null ) {
        this._formSubmitted = value
        this.stateChanges.next();
    }
    get formSubmitted() {
        return this._formSubmitted;
    }

    set dateNaisanceCoherent( value: boolean | null ) {
        this._dateNaisanceCoherent = value;
        this.stateChanges.next();
    }
    get dateNaisanceCoherent() {
        return this._dateNaisanceCoherent;
    }


    constructor( private utilsService: UtilsResourceService, private newAdhService: NewAdhService, private adhesionEnLigneNoAuthResourceService: AdhesionEnLigneNoAuthResourceService,
        private adhesionEnLigneAuthResourceService: AdhesionEnLigneAuthResourceService, private keycloackService: KeycloakService, private adhesionEnLigneResourceService: AdhesionEnLigneResourceService ) {
        this.newAdhService.adhesionItem$.subscribe( adhesion => this.nouvelleAdhesion = adhesion );
        this.formSubmitted = false;
    }

    async ngOnInit() {
        this.utilsService.getListePays().subscribe( result => {
            this.listePays = result;
            this.PaysParDefaut = { codePays: 235, libelle: "FRANCE" };
        } )
        this.adhesionEnLigneNoAuthResourceService.getListeFonctionPPE().subscribe( result => {
            this.fonctionsPPE = result;
        } )
        /*     this.formGroup.get( 'civilite' ).valueChanges.subscribe(( change ) => {
                 this.formSubmitted = false;
                 this.formGroup.get( 'coherenceNumSecu' ).patchValue( "" );
                 this.formGroup.get( 'coherenceNumSecu' ).updateValueAndValidity();
             } );
     
             this.formGroup.get( 'dateNaissance' ).valueChanges.subscribe(( change ) => {
                 this.dateNaisanceCoherent = false;
                 this.formSubmitted = false;
                 this.formGroup.get( 'coherenceNumSecu' ).patchValue( "" );
                 this.formGroup.get( 'coherenceNumSecu' ).updateValueAndValidity();
             } );*/

        this.formGroup.get( 'numeroSecu' ).valueChanges.subscribe(( change ) => {
            this.dateNaisanceCoherent = false;
            this.formSubmitted = false;
            this.formGroup.get( 'coherenceNumSecu' ).patchValue( "" );
            this.formGroup.get( 'coherenceNumSecu' ).updateValueAndValidity();

        } );
        this.formGroup.get( 'email' ).valueChanges.subscribe(( change ) => {

            this.formGroup.get( 'emailCheck' ).patchValue( "" );
            this.formGroup.get( 'emailCheck' ).updateValueAndValidity();

        } );

        var listMandatsObservable = await this.adhesionEnLigneResourceService.getListeMandats();
        listMandatsObservable.subscribe(
            val => {
                this.listMandats = val;
            } );
    }
    
    isVerifAutoOK(): boolean{
        return this.adherent && this.adherent.controleManuelIdentiteAFaire === false
    }

    compareObjects( o1: NomenclatureDTO, o2: NomenclatureDTO ): boolean {
        return o1 && o2 ? o1.code === o2.code : o1 === o2;
    }

    onBlurNomNaissanceEntourageAdherent() {
        if ( this.formGroup.get( 'entourageNomNaissance' ).value != null && ( this.formGroup.get( 'entourageNom' ).value == null || this.formGroup.get( 'entourageNom' ).value == "" ) )

            this.formGroup.get( 'entourageNom' ).patchValue( this.formGroup.get( 'entourageNomNaissance' ).value );
    }
    get coherenceNumSecu() { return this.formGroup.get( 'coherenceNumSecu' ); }

    onBlurDateNaissance() {
        this.dateNaisanceCoherent = this.iserreurDateNaissance();
    }
    iserreurDateNaissance() {
        return this.formGroup.get( 'coherenceNumSecu' ).hasError( 'coherenceDateNaissanceNumSecu' );
    }
    onNouvelleAdhesionFromInterne() {
        this.nouvelleAdhesionFromInterne.emit();
    }
    onNouvelleAdhesionMemeEluFromInterne() {
        this.nouvelleAdhesionMemeEluFromInterne.emit();
    }
    onModifierIdentite() {
        this.modifierIdentite.emit();
    }

    expandAdresse() {
        this.adressPartComponent.expand();
    }
}
