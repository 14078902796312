import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';
import { Component, OnInit } from '@angular/core';
import { Input } from "@angular/core";
import { ViewEncapsulation,ViewChild } from "@angular/core";
import { DialogService } from "src/app/api/dialog.service";
import { MatMenuTrigger } from '@angular/material';
import { NotificationService } from "src/app/api/notification.service";
import { AdressPartComponent } from 'src/app/shared/components/new-adh-form-parts/adress-part/adress-part.component';

@Component( {
    selector: 'app-collectivite-part',
    templateUrl: './collectivite-part.component.html',
    styleUrls: ['./collectivite-part.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class CollectivitePartComponent implements OnInit {
    @Input() formGroup;
    constructor( private serviceDialog: DialogService, private adhesionEnLigneService: AdhesionEnLigneResourceService, private notificationService: NotificationService ) { }
   
    @ViewChild( AdressPartComponent ) adressPartComponent;
    
    ngOnInit() {
        this.formGroup.get( 'numeroSIRET' ).valueChanges.subscribe(( change ) => {

            this.formGroup.get( 'contactNom' ).patchValue( "" );
            this.formGroup.get( 'contactPrenom' ).patchValue( "" );
            this.formGroup.get( 'contactPrenom' ).patchValue( "" );
            this.formGroup.get( 'numeroFixe' ).patchValue( "" );
            this.formGroup.get( 'contactEmail' ).patchValue( "" );

        } );
    }
    afficherAide() {
        this.serviceDialog.openInfoDialog(
            'Le numéro de SIRET de votre collectivité, composé de 14 caractères, figure sur votre bulletin d\'indemnités.<br> <br>Vous pouvez effectuer une recherche de SIRET en <a _ngcontent-fvi-c24="" target="_blank" href="https://www.sirene.fr/sirene/public/recherche">Cliquant ici</a>' )
            .afterClosed().subscribe( res => {

            } );
    }

    updateSiret( value: string ) {
        this.notificationService.wait( 'La recherche de votre collectivité est en cours. <br><br>Veuillez patienter.', false );
        this.adhesionEnLigneService.getCollectiviteBySiret( value ).then( observable => observable.subscribe(
            result => {
                this.notificationService.close();
                if ( result ) {
                    this.formGroup.patchValue( { idExterne: result.idFonc } );

                    this.formGroup.patchValue( { denomination: result.raisonSociale } );
                    this.formGroup.patchValue( { codePostal: ( result.adresse || {} ).codePostal } );
                    this.formGroup.patchValue( { ville: ( result.adresse || {} ).ville } );
                    this.formGroup.patchValue( { ligne1: ( result.adresse || {} ).ligne1 } );
                    this.formGroup.patchValue( { ligne2: ( result.adresse || {} ).ligne2 } );
                    this.formGroup.patchValue( { ligne3: ( result.adresse || {} ).ligne3 } );


                    /* if ( !this.formGroup.get( 'denomination' ).value ) {
                         this.formGroup.patchValue( { denomination: result.raisonSociale } );
                     }
 
                     if ( !this.formGroup.get( "codePostal" ).value ) {
                         this.formGroup.patchValue( { codePostal: ( result.adresse || {} ).codePostal } );
                     }
 
                     if ( !this.formGroup.get( "ville" ).value ) {
                         this.formGroup.patchValue( { ville: ( result.adresse || {} ).ville } );
                     }
 
                     if ( !this.formGroup.get( "ligne1" ).value ) {
                         this.formGroup.patchValue( { ligne1: ( result.adresse || {} ).ligne1 } );
                     }
 
                     if ( !this.formGroup.get( "ligne2" ).value ) {
                         this.formGroup.patchValue( { ligne2: ( result.adresse || {} ).ligne2 } );
                     }
 
                     if ( !this.formGroup.get( "ligne3" ).value ) {
                         this.formGroup.patchValue( { ligne3: ( result.adresse || {} ).ligne3 } );
                     }*/
                }
            },
            error => {
                this.notificationService.close();
            } ) );
    }
    
    expandAdresse() 
    {
        this.adressPartComponent.expand();
    }
}
