import { AbstractControl } from "@angular/forms";

function emptyObjectToNull( obj ) {
    if ( !obj || ( Object.keys( obj ).length === 0 ) ) {
        return null;
    } else {
        return obj;
    }
}

/*export function ValidateNumSecu( controlCoh: AbstractControl, numsecu: AbstractControl, civilite: AbstractControl, dateNaissance: AbstractControl ) {

    if ( controlCoh != undefined && controlCoh != null && numsecu != null && numsecu.value != undefined && numsecu.value != null
        && numsecu.valid && dateNaissance.valid ) {

        let errorsCoh = {};
        if ( ( civilite.value != undefined ) && ( civilite.value != null ) ) {
            var firstCaracteres = numsecu.value.replace( /\s+/g, '' ).substring( 0, 1 );
            if ( ( firstCaracteres == "2" && civilite.value == "MONSIEUR" ) || ( firstCaracteres == "1" && civilite.value == "MADAME" ) ) {
                errorsCoh['coherenceCiviliteNumSecu'] = true;
            }
        }
        if ( ( dateNaissance.value != undefined ) && ( dateNaissance.value != null ) ) {
            var yearNaissanceSecu = numsecu.value.replace( /\s+/g, '' ).substring( 1, 3 );
            var monthNaissanceSecu = +( numsecu.value.replace( /\s+/g, '' ).substring( 3, 5 ) );


            var twoDigitsYear = new Date( dateNaissance.value ).getFullYear().toString().substr( 2, 2 );
            var monthNaissance = ( new Date( dateNaissance.value ).getMonth() + 1 );

            if ( twoDigitsYear != yearNaissanceSecu || monthNaissance != monthNaissanceSecu ) {
                errorsCoh['coherenceDateNaissanceNumSecu'] = true;
            }
        }
        setTimeout(() => {
            controlCoh.setErrors( emptyObjectToNull( errorsCoh ) );

        } );
    }*/
export function ValidateNumSecu( controlCoh: AbstractControl, numsecu: AbstractControl, civilite: string, dateNaissance: Date ) {

    if ( controlCoh != undefined && controlCoh != null && numsecu != null && numsecu.value != undefined && numsecu.value != null
        && numsecu.valid ) {

        let errorsCoh = {};
        if ( ( civilite != undefined ) && ( civilite != null ) ) {
            var firstCaracteres = numsecu.value.replace( /\s+/g, '' ).substring( 0, 1 );
            if ( ( firstCaracteres == "2" && civilite == "MONSIEUR" ) || ( firstCaracteres == "1" && civilite == "MADAME" ) ) {
                errorsCoh['coherenceCiviliteNumSecu'] = true;
            }
        }
        if ( dateNaissance != null ) {
            var yearNaissanceSecu = numsecu.value.replace( /\s+/g, '' ).substring( 1, 3 );
            var monthNaissanceSecu = +( numsecu.value.replace( /\s+/g, '' ).substring( 3, 5 ) );


            var twoDigitsYear = new Date( dateNaissance ).getFullYear().toString().substr( 2, 2 );
            var monthNaissance = ( new Date( dateNaissance ).getMonth() + 1 );

            if ( twoDigitsYear != yearNaissanceSecu || monthNaissance != monthNaissanceSecu ) {
                errorsCoh['coherenceDateNaissanceNumSecu'] = true;
            }
        }
        setTimeout(() => {
            controlCoh.setErrors( emptyObjectToNull( errorsCoh ) );

        } );
    }

}







