import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from "@angular/core";
import { trigger } from "@angular/animations";
import { state } from "@angular/animations";
import { style } from "@angular/animations";
import { animate } from "@angular/animations";
import { transition } from "@angular/animations";
import { BeneficiaireDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { BeneficiairePartComponent } from "src/app/shared/components/new-adh-form-parts/beneficiaire-part/beneficiaire-part.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DialogService } from "src/app/api/dialog.service";
import { Decimal } from "decimal.js/decimal";
import { FormGroup } from "@angular/forms";
import { NewAdhService } from "src/app/api/new-adh.service";
import { AELAdhesionDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { PercentPipe } from "src/app/pipes/percent.pipe";
import { ValidatorFn, ValidationErrors } from "@angular/forms";
import { Moment } from "moment/moment";

import * as moment from 'moment';

@Component( {
    selector: 'app-garanties-part',
    templateUrl: './garanties-part.component.html',
    styleUrls: ['./garanties-part.component.scss'],
    animations: [
        trigger( 'detailExpand', [
            state( 'collapsed', style( { height: '0px', minHeight: '0' } ) ),
            state( 'expanded', style( { height: '*' } ) ),
            transition( 'expanded <=> collapsed', animate( '225ms cubic-bezier(0.4, 0.0, 0.2, 1)' ) ),
        ] )
    ],
} )
export class GarantiesPartComponent implements OnInit {
    displayedColumnsBeneficiaire: string[] = ['beneficiaire', 'ordre', 'taux', 'actionsColumn'];
    dataSourceBeneficiaires;
    nouvelleAdhesion: AELAdhesionDTO;
    expandedElement: BeneficiaireDTO | null;
    @Input() formGroup;
    maxRepart;
    public visibleDiv;
    maxNombreBeneficiaire;
    @Output()
    dateEffetChange = new EventEmitter();
    dateAdhesionMin: Moment;
    dateAdhesionMax: Moment;

    readonly HUNDRED: Decimal = new Decimal( "100" );

    constructor( private newAdhService: NewAdhService, public dialog: MatDialog, private serviceDialog: DialogService, private percentPipe: PercentPipe ) {
        this.newAdhService.adhesionItem$.subscribe( adhesion => this.nouvelleAdhesion = adhesion );
    }

    filterDateAdhesion = ( m: Moment ): boolean => {
        const day = m.date();
        // Prevent Saturday and Sunday from being selected.
        //return day !== 0 && day !== 6;
        return day == 1;
    }


    validatorRepartition(): ValidatorFn {
        return ( control: FormGroup ): ValidationErrors | null => {

            if ( this.formGroup.get( 'clauseGarantieDC' ).value ) {
                return null;
            }

            let mapRepartition: Map<number, Decimal> = this.totalRepartitionMap()

            if ( mapRepartition.size == 0 ) {
                return { 'problemeRepartition': true };
            } else {
                const keys = mapRepartition.keys();
                let result = keys.next();
                while ( !result.done ) {
                    if ( !this.HUNDRED.equals( mapRepartition.get( result.value ) ) ) {
                        return { 'problemeRepartition': true };
                    }
                    result = keys.next();
                }

                return null;
            }
        }
    }
    getDateDebutErrorMessage() {
        if ( this.formGroup.get( 'dateEffet' ).hasError( 'required' ) ) {
            return "Veuillez saisir la date d'effet de l'adhésion";
        } else if ( this.formGroup.get( 'dateEffet' ).hasError( 'premierJourDuMois' ) ) {
            return "La date d'effet de l'adhésion doit commencer le premier jour du mois";
        }
        else if ( this.formGroup.get( 'dateEffet' ).hasError( 'periode3MoisMax' ) ) {
            return `La date d'effet de l'adhésion doit commencer au plus tôt le ${this.dateAdhesionMin.format( 'DD/MM/YYYY' )} et au plus tard le ${this.dateAdhesionMax.format( 'DD/MM/YYYY' )}`;
        }

        return '';
    }

    getMessagesErreurBeneficiaire() {
        let mapRepartition: Map<number, Decimal> = this.totalRepartitionMap()

        if ( mapRepartition.size == 0 ) {
            return `Veuillez renseigner au moins un bénéficiaire (maximum ${this.maxNombreBeneficiaire}).<br><br>Si vous désignez plusieurs bénéficiaires, le total cumulé des taux de répartition par ordre de priorité, doit être égal à ${this.percentPipe.transform( this.HUNDRED, true )}.`;
        } else {
            let erreurs: string = ''

            mapRepartition.forEach(( value: Decimal, ordre: number ) => {

                if ( !this.HUNDRED.equals( value ) ) {
                    if ( erreurs.length > 0 ) {
                        erreurs += "<br>"
                    }
                    erreurs += `Le taux de répartition cumulé à l'ordre ${ordre} doit être de ${this.percentPipe.transform( this.HUNDRED, true )}. ${this.percentPipe.transform( value, true )} declarés, ${this.percentPipe.transform( Decimal.sub( this.HUNDRED, value ), true )} restant à déclarer`;
                }
            } );

            return erreurs;
        }

    }

    totalRepartitionMap(): Map<number, Decimal> {
        let result: Map<number, Decimal> = new Map<number, Decimal>();

        if ( this.nouvelleAdhesion.beneficiaires ) {
            for ( let beneficiaire of this.nouvelleAdhesion.beneficiaires ) {
                let ordre: number = beneficiaire.ordrePriorite
                let total: Decimal = result.has( ordre ) ? result.get( ordre ) : new Decimal( 0 )
                total = total.add( beneficiaire.tauxRepartition );
                result.set( ordre, total )
            }
        }

        return result;
    }

    ngOnInit() {
        setTimeout(() => {
            this.formGroup.setValidators( this.validatorRepartition() )
            this.formGroup.updateValueAndValidity();
        } );

        this.maxNombreBeneficiaire = 8;
        this.visibleDiv = "";

        this.updateDataSource();

        this.formGroup.get( 'dateEffet' ).valueChanges.subscribe(( change ) => {
            this.dateEffetChange.emit();
        } );

        this.dateAdhesionMin = moment().add( 1, 'months' ).startOf( 'month' );
        this.dateAdhesionMax = moment().add( 3, 'months' ).startOf( 'month' );
    }

    afficherDetail( id: any ) {
        if ( this.visibleDiv === id ) {
            this.visibleDiv = null;
        } else {
            this.visibleDiv = id;
        }
    }

    divDetailVisible( id: any ): boolean {
        return this.visibleDiv == id;
    }

    private updateDataSource() {
        var sortedArray: BeneficiaireDTO[] = this.nouvelleAdhesion.beneficiaires.sort(( b1, b2 ) => {
            return b1.ordrePriorite - b2.ordrePriorite
        } );
        this.dataSourceBeneficiaires = new MatTableDataSource( sortedArray );
        this.formGroup.updateValueAndValidity();
    }

    insertBeneficiaire() {

        if ( this.nouvelleAdhesion.beneficiaires.length == this.maxNombreBeneficiaire ) {
            this.serviceDialog.openErrorDialog(
                `<br>Le nombre de bénéficiaires est limité à ${this.maxNombreBeneficiaire}.` );
        } else {
            const dialogRef = this.dialog.open( BeneficiairePartComponent, {
                width: '800px',
                disableClose: true,
                data: {
                    title: "Ajouter un bénéficiaire", repartionMap: this.totalRepartitionMap(), beneficiaire: null, adresseElu: this.nouvelleAdhesion.adherent.adresse
                }
            } );

            dialogRef.afterClosed().subscribe( result => {
                if ( result.valid ) {
                    this.nouvelleAdhesion.beneficiaires.push( result.beneficiaire );
                    this.updateDataSource();
                }
            } );
        }
    }

    updateBeneficiaire( beneficiaire: BeneficiaireDTO ) {

        const dialogRef = this.dialog.open( BeneficiairePartComponent, {
            width: '800px',
            disableClose: true,
            data: { title: "Modifier un bénéficiaire", repartionMap: this.totalRepartitionMap(), beneficiaire: beneficiaire, adresseElu: this.nouvelleAdhesion.adherent.adresse }
        } );

        dialogRef.afterClosed().subscribe( result => {
            if ( result.valid ) {
                const index = this.nouvelleAdhesion.beneficiaires.indexOf( beneficiaire );
                if ( index > -1 ) {
                    this.nouvelleAdhesion.beneficiaires[index] = result.beneficiaire;
                }
                this.updateDataSource();
            }
        } );

    }

    modifierBeneficiaire( beneficiaire: BeneficiaireDTO ) {

        const index = this.nouvelleAdhesion.beneficiaires.indexOf( beneficiaire );
        if ( index > -1 ) {
            this.nouvelleAdhesion.beneficiaires[index] = beneficiaire;
            this.updateDataSource();
        }
    }

    supprimerBeneficiaire( beneficiaire: BeneficiaireDTO ) {
        this.serviceDialog.openConfirmDialog( 'Voulez vous supprimer le bénéficiaire ?' )
            .afterClosed().subscribe( res => {
                if ( res ) {
                    const index = this.nouvelleAdhesion.beneficiaires.indexOf( beneficiaire );
                    if ( index > -1 ) {
                        this.nouvelleAdhesion.beneficiaires.splice( index, 1 );
                        this.updateDataSource();
                    }
                }
            } );
    }
}
