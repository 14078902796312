import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from "@angular/common";
import { Decimal } from "decimal.js/decimal";

@Pipe( {
    name: 'emptyCheck'
} )
export class EmptyCheckPipe implements PipeTransform {
    constructor( private decimalPipe: DecimalPipe ) { }

    transform( value: any ): any {
        if ( !value ) {
            return 'Non renseigné';
        } else {
            return value;
        }
    }

}
