import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { CollectiviteDTO, DownloadResourceService, AdherentResourceService, EluDTO } from '@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist';
import { GlobalLoadingService } from "src/app/api/global-loading.service";
import { Decimal } from 'decimal.js/decimal';
import { HttpResponse } from "@angular/common/http";

@Injectable()

// https://stackoverflow.com/questions/35985009/angular-2-what-is-equivalent-to-root-scope
// https://stackoverflow.com/questions/34376854/delegation-eventemitter-or-observable-in-angular/35568924#35568924

export class HelperServiceService {

    private _eluItemSource = new ReplaySubject<EluDTO>();
    eluItem$ = this._eluItemSource.asObservable();

    constructor( private globalLoadingService: GlobalLoadingService, private service: AdherentResourceService ) {
        this.globalLoadingService.startLoading();
        this.service.currentUser().subscribe( elu => {
            this.changeElu( elu );
            this.globalLoadingService.stopLoading();
        } );
    }

    changeElu( elu: EluDTO ) {
        this._eluItemSource.next( elu );
    }

    estCotisant( elu: EluDTO ): boolean {
        return elu.adhesionsCotisant.length != 0;
    }

    estRentier( elu: EluDTO ): boolean {
        return elu.adhesionsRentier.length != 0;
    }
    aVersementUnique( elu: EluDTO ): boolean {
        return elu.adhesionsResiliees.length != 0;
    }



    uncrypt( idCrypt: String ) {
        if ( !idCrypt ) {
            return null;
        }

        return idCrypt.substr( 0, idCrypt.length - 2 );
    }


    entries( obj ) {
        return Object.entries( obj );
    }
}
