/** Error when invalid control is dirty, touched, or submitted. */
import { FormControl } from "@angular/forms";
import { FormGroupDirective } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { NgForm } from "@angular/forms";

export class AfterSubmitErrorStateMatcher implements ErrorStateMatcher {
    composant: any;

    isErrorState( control: FormControl | null, form: FormGroupDirective | NgForm | null ): boolean {
        const dateParseError = control && control.hasError( 'matDatepickerParse' );
        const patternError = control && control.hasError( 'pattern' );
        const isSubmitted = this.composant && this.composant.formSubmitted;
        return !!( control && control.invalid && ( control.dirty || ( control.touched && ( dateParseError || patternError ) ) || isSubmitted ) );
    }

    constructor( composant ) {
        this.composant = composant;
    }
}