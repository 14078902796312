import { Injectable } from "@angular/core";
import { Decimal } from "decimal.js/decimal";
import { HttpResponse } from "@angular/common/http";
import { DownloadResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";

@Injectable()
export class DownloadHelperService {
    constructor( private downloadService: DownloadResourceService ) {

    }

    saveOrOpenBlob( blob: Blob, blobFileName: string ) {
        if ( window.navigator && window.navigator.msSaveOrOpenBlob ) {
            window.navigator.msSaveOrOpenBlob( blob, blobFileName );
        } else {
            // pour Chrome et FireFox
            const url = window.URL.createObjectURL( blob );
            const a = document.createElement( 'a' );
            document.body.appendChild( a );
            a.setAttribute( 'style', 'display: none' );
            a.href = url;
            a.download = blobFileName;
            a.click();
            window.URL.revokeObjectURL( url );
            document.body.removeChild( a );
        }
    }

    saveOrOpenBlobResponse( response: HttpResponse<Blob> ) {
        const contentDisposition = response.headers.get( 'Content-Disposition' );
        const filename = this.parseFilenameFromContentDisposition( contentDisposition );

        this.saveOrOpenBlob( response.body, filename );
    }

    downloadFile( idDocument, libelle ) {
        this.downloadService.downloadDocument( idDocument ).subscribe(
            result => {
                this.saveOrOpenBlob( result, libelle + '.pdf' );
            }
        );
    }

    // Cf. https://stackoverflow.com/questions/33046930/how-to-get-the-name-of-a-file-downloaded-with-angular-http

    parseFilenameFromContentDisposition( contentDisposition ): string {

        if ( !contentDisposition ) return null;

        const matches = /filename="(.*?)"/g.exec( contentDisposition );

        return matches && matches.length > 1 ? matches[1] : null;
    }

    downloadAttachment( idDocument ) {
        this.downloadService.downloadDocument( idDocument, 'response' ).subscribe(
            result => {
                const contentDisposition = result.headers.get( 'Content-Disposition' );
                const filename = this.parseFilenameFromContentDisposition( contentDisposition );

                this.saveOrOpenBlob( result.body, filename );
            }
        );
    }



}