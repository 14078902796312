import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'NIR'
} )
export class NirPipe {
    transform( value: string ) {
        if ( value == null ) {
            return null;
        }
        let spacePlacement = [1, 3, 5, 7, 10, 13];
        let newStr = '';
        value = value.replace( / /g, '' );
        for ( let i = 0; i < value.length; i++ ) {
            for ( var space of spacePlacement ) {
                if ( i == space ) {
                    newStr += ' ';
                }
            }
            newStr += value[i];
        }
        return newStr;
    }
}