import { NgModule } from '@angular/core';
import { TypeDemandeDirective } from "src/app/directives/type-demande.directive";
import { MontantPatternDirective } from "src/app/directives/montant-pattern.directive";
import { DigitOnlyDirective } from "src/app/directives/digit-only.directive";
import { DigitOnlyABDirective } from "src/app/directives/digit-only-with-ab.directive";
import { IfErrorDirective } from "./if-error.directive";
@NgModule( {
    imports: [],
    declarations: [DigitOnlyABDirective, TypeDemandeDirective, MontantPatternDirective, DigitOnlyDirective, IfErrorDirective],
    exports: [DigitOnlyABDirective, TypeDemandeDirective, MontantPatternDirective, DigitOnlyDirective, IfErrorDirective]
} )
export class DirectivesModule { }
