import { ParamMap } from "@angular/router";

export class RouteParamMap implements ParamMap {

    private maps: ParamMap[] = [];
    keys: string[] = [];

    constructor( map1: ParamMap, map2: ParamMap ) {
        if ( map1 ) {
            this.maps.push( map1 );
            this.keys = this.keys.concat( map1.keys );
        }
        if ( map2 ) {
            this.maps.push( map2 )
            this.keys = this.keys.concat( map2.keys );
        }
    }

    has( name: string ): boolean {
        if ( this.maps.find( map => map.has( name ) ) ) {
            return true;
        }

        return false;
    }

    get( name: string ): string {
        if ( this.has( name ) ) {
            return this.maps.find( map => map.has( name ) ).get( name );
        } else {
            return null;
        }
    }

    getAll( name: string ): string[] {
        if ( this.has( name ) ) {
            return this.maps.find( map => map.has( name ) ).getAll( name );
        } else {
            return null;
        }
    }
}