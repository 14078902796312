import { Component, OnInit } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { UtilsResourceService, BeneficiaireDTO, AdresseDTO, Pays } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { FormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { ViewEncapsulation } from "@angular/core";
import { NotificationService } from "src/app/api/notification.service";
import { ValidatorFn } from "@angular/forms";
import { AbstractControl } from "@angular/forms";
import { UpperCasePipe } from "@angular/common";
import { Decimal } from "decimal.js/decimal";
import { AdressDialogPartComponent } from "src/app/shared/components/new-adh-form-parts/adressDialog-part/adressDialog-part.component";
import { InfosNaissanceDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/infosNaissanceDialog-part/infosNaissanceDialog-part.component';
import { DecimalHelperService } from 'src/app/api/decimal-helper.service';

import { ValidatorSofaxisEmail } from "src/app/shared/sofaxis-common-email.validator";

@Component( {
    selector: 'app-beneficiaire-part',
    templateUrl: './beneficiaire-part.component.html',
    styleUrls: ['./beneficiaire-part.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class BeneficiairePartComponent implements OnInit {
    public listePays: Array<Pays>;
    PaysParDefaut: Pays;
    beneficiaire: BeneficiaireDTO;
    formGroup: FormGroup;
    adresseElu: AdresseDTO;
    adresse: AdresseDTO;
    formSubmitted: boolean = false;
    maxRepart: Decimal
    tauxRepartitionInit: Decimal
    ordreInit: number
    public ZipCodeRegex = /^\d{5}$/;
    public PhonePattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    public pourcentagePattern = /(^100(\.0{1,2})?$)|(^([0-9]([0-9])?)(\.[0-9]{1,2})?$)/;

    dateNaissance;
    villeNaissance;
    paysNaissance;
    codePostalNaissance;
    priorites: number[] = [];
    repartionMap: Map<number, Decimal>

    private static readonly HUNDRED: Decimal = new Decimal( "100" )
    private static readonly ZERO: Decimal = new Decimal( 0 )
    private static readonly ORDREMAXIMUM: number = 8

    constructor( private utilsService: UtilsResourceService, private notificationService: NotificationService, private upperCasePipe: UpperCasePipe, private decimalHelperService: DecimalHelperService, public dialogRef: MatDialogRef<BeneficiairePartComponent>,
        @Inject( MAT_DIALOG_DATA ) public data, private _formBuilder: FormBuilder, public dialog: MatDialog ) {
        this.beneficiaire = data.beneficiaire;
        this.repartionMap = data.repartionMap;
        this.adresseElu = data.adresseElu;
        this.adresse = null;
    }


    ngOnInit() {
        this.utilsService.getListePays().subscribe( result => {
            this.listePays = result;
        } )

        if ( this.beneficiaire == undefined || this.beneficiaire == null ) {
            this.beneficiaire = {};
            this.dateNaissance = null;
            this.villeNaissance = null;
            this.paysNaissance = null;
            this.codePostalNaissance = null
            this.adresse = null;
            this.tauxRepartitionInit = BeneficiairePartComponent.ZERO
            this.ordreInit = 0
        } else {
            this.dateNaissance = this.beneficiaire.dateNaissance;
            this.villeNaissance = this.beneficiaire.villeNaissance;
            this.paysNaissance = this.beneficiaire.paysNaissance;
            this.codePostalNaissance = this.beneficiaire.codePostalNaissance;
            this.adresse = this.beneficiaire.adresse;
            this.tauxRepartitionInit = new Decimal( this.beneficiaire.tauxRepartition )
            this.ordreInit = this.beneficiaire.ordrePriorite
        }

        for ( var i = 1; i <= Math.min( BeneficiairePartComponent.ORDREMAXIMUM, this.ordreMaximumCourant() + 1 ); i++ ) {
            if ( i == this.ordreInit || !this.repartionMap.has( i ) || !BeneficiairePartComponent.HUNDRED.equals( this.repartionMap.get( i ) ) ) {
                this.priorites.push( i )
            }
        }

        if ( !this.beneficiaire.ordrePriorite && this.priorites.length == 1 ) {
            this.beneficiaire.ordrePriorite = this.priorites[0];
        }

        this.formGroup = this._formBuilder.group( {
            ordrePriorite: [this.beneficiaire.ordrePriorite, Validators.required],
            nomPatronymique: [this.beneficiaire.nomPatronimique, Validators.required],
            nomUsage: [this.beneficiaire.nomMarital, Validators.required],
            prenom: [this.beneficiaire.prenom, Validators.required],
            tauxRepartition: [this.beneficiaire.tauxRepartition, [Validators.required, Validators.pattern( this.pourcentagePattern ), this.tauxRepartValidator()]],
            email: [this.beneficiaire.email, [ValidatorSofaxisEmail]],
            telephone: [this.beneficiaire.telephone, [Validators.pattern( this.PhonePattern ), Validators.minLength( 10 ), Validators.maxLength( 15 )]]

        } );

        this.formGroup.get( 'ordrePriorite' ).valueChanges.subscribe(( change ) => {
            this.updateMaxRepart( change );
        } );

        this.updateMaxRepart( this.beneficiaire.ordrePriorite );
    }

    private ordreMaximumCourant(): number {
        let ordreMax: number = 0
        if ( this.repartionMap ) {
            this.repartionMap.forEach(( value: Decimal, ordre: number ) => {
                ordreMax = Math.max( ordreMax, ordre )
            } );
        }

        return ordreMax;
    }
    private updateMaxRepart( ordre?: number ) {
        if ( ordre ) {
            this.maxRepart = new Decimal( 100 );
            if ( this.repartionMap && this.repartionMap.has( ordre ) ) {
                this.maxRepart = this.maxRepart.sub( this.repartionMap.get( ordre ) );
            }
            if ( this.ordreInit && this.ordreInit == ordre ) {
                this.maxRepart = this.maxRepart.add( this.tauxRepartitionInit );
            }
        }
        else {
            this.maxRepart = null;
        }
        this.formGroup.get( 'tauxRepartition' ).updateValueAndValidity();
    }

    getPays() {
        this.PaysParDefaut = { codePays: 235, libelle: "FRANCE" };
        if ( this.beneficiaire == undefined || this.beneficiaire == null || this.beneficiaire.paysNaissance == null ) {
            return this.PaysParDefaut;
        }
        return this.beneficiaire.paysNaissance;
    }

    compareObjects( o1: Pays, o2: Pays ): boolean {
        return o1 && o2 ? o1.codePays === o2.codePays : o1 === o2;
    }

    doAnnuler() {
        this.dialogRef.close( { valid: false } );
    }

    setBenefciaireFromSaisie() {
        if ( this.beneficiaire == null || this.beneficiaire == undefined ) {
            this.beneficiaire = {};
        }
        this.beneficiaire.nomPatronimique = this.upperCasePipe.transform( this.formGroup.get( "nomPatronymique" ).value );
        this.beneficiaire.nomMarital = this.upperCasePipe.transform( this.formGroup.get( "nomUsage" ).value );
        this.beneficiaire.prenom = this.formGroup.get( "prenom" ).value;
        this.beneficiaire.villeNaissance = this.villeNaissance;
        this.beneficiaire.dateNaissance = this.dateNaissance;
        this.beneficiaire.paysNaissance = this.paysNaissance;
        this.beneficiaire.codePostalNaissance = this.codePostalNaissance;
        this.beneficiaire.adresse = this.adresse;
        this.beneficiaire.telephone = this.formGroup.get( "telephone" ).value;
        this.beneficiaire.email = this.formGroup.get( "email" ).value;
        this.beneficiaire.tauxRepartition = this.formGroup.get( "tauxRepartition" ).value;
        this.beneficiaire.ordrePriorite = this.formGroup.get( "ordrePriorite" ).value;
    }

    doValider() {
        this.formSubmitted = true;
        if ( this.formGroup.valid && this.adresse != null && this.dateNaissance != null && this.villeNaissance != null && this.paysNaissance != null ) {
            this.formSubmitted = false;
            this.setBenefciaireFromSaisie();

            this.dialogRef.close( {
                valid: true, beneficiaire: this.beneficiaire
            } );
        } else {
            this.notificationService.warnCourt( 'Votre formulaire contient des erreurs. Veuillez corriger ces erreurs. ' );
        }
    }

    tauxRepartValidator(): ValidatorFn {
        return ( control: AbstractControl ): { [key: string]: boolean } | null => {
            if ( this.decimalHelperService.isDecimal( control.value ) ) {
                let value = this.decimalHelperService.toDecimal( control.value );

                if ( this.maxRepart && this.maxRepart.lessThan( value ) || value.equals( new Decimal( 0 ) ) ) {
                    return {
                        'tauxRepart': true
                    };
                }

                return null;
            } else {
                return {
                    'tauxRepart': true
                };
            }
        };
    }

    modifierAdresse() {

        const dialogRef = this.dialog.open( AdressDialogPartComponent, {
            width: '800px',
            disableClose: true,
            data: {
                title: "Modifier l'adresse du bénéficiaire", adresse: this.adresse,
            }
        }
        );
        dialogRef.afterClosed().subscribe( result => {
            if ( result.valid ) {
                this.adresse = result.adresse;
            }
        } );
    }

    modifierInfosNaissance() {

        const dialogRef = this.dialog.open( InfosNaissanceDialogPartComponent, {
            width: '800px',
            disableClose: true,
            data: {
                title: "Modifier les informations de naissance du bénéficiaire",
                dateNaissance: this.dateNaissance,
                villeNaissance: this.villeNaissance,
                paysNaissance: this.paysNaissance,
                codePostalNaissance: this.codePostalNaissance
            }
        } );

        dialogRef.afterClosed().subscribe( result => {
            if ( result.valid ) {
                this.dateNaissance = result.dateNaissance;
                this.villeNaissance = result.villeNaissance;
                this.paysNaissance = result.paysNaissance;
                this.codePostalNaissance = result.codePostalNaissance;

            }
        } );
    }

    copierAdresseElu() {
        this.adresse = this.adresseElu;
    }

    supprimerAdresse() {
        this.adresse = null;
        this.formSubmitted = true;
    }

    supprimerInfosNaissance() {

        this.dateNaissance = null;
        this.villeNaissance = null;
        this.codePostalNaissance = null;
        this.paysNaissance = null;

        this.formSubmitted = true;
    }

    onBlurNomPatronimyque() {
        if ( this.formGroup.get( 'nomPatronymique' ).value != null && ( this.formGroup.get( 'nomUsage' ).value == null || this.formGroup.get( 'nomUsage' ).value == "" ) )
            this.formGroup.get( 'nomUsage' ).patchValue( this.formGroup.get( 'nomPatronymique' ).value );
    }
}
