
export class PrefixeCode {
    public allPrefixe = [
        [33, "France"],
        [590, "Guadeloupe"],
        [594, "Guyane"],
        [262, "Réunion"],
        [596, "Martinique"],
        [262, "Mayotte"],
        [508, "Saint-Pierre Et Miquelon"],
        [681, "Wallis Et Futuna"]
    ];
}