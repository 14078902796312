import { Pipe, PipeTransform } from '@angular/core';

@Pipe( {
    name: 'SIRET'
} )
export class SiretPipe {
    transform( value: string ) {
        if ( value == null ) {
            return null;
        }
        let newStr = '';
        let cpt1 = 0;
        let cpt2 = 0;
        value = value.replace( / /g, '' );
        for ( let i = 0; i < value.length; i++ ) {
            newStr += value[i];
            cpt1 = cpt1 + 1;
            if ( cpt1 == 3 && cpt2 < 3 ) {
                cpt2 = cpt2 + 1;
                cpt1 = 0;
                newStr += ' ';
            }
        }
        return newStr;
    }
}