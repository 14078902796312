import { DocumentationResourceService } from '@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface FileReaderEventTarget extends EventTarget {
    result: string;
}

interface FileReaderEvent extends Event {
    target: FileReaderEventTarget;
    getMessage(): string;
}

@Component( {
    selector: 'app-cg',
    templateUrl: './cg.component.html',
    styleUrls: ['./cg.component.scss']
} )

export class CgComponent implements OnInit {
    cgText;
    data;
    constructor( private documentationResourceService: DocumentationResourceService, private dialogRef: MatDialogRef<CgComponent>, @Inject( MAT_DIALOG_DATA ) data: any ) {
        this.data = data;
    }

    ngOnInit() {
        this.documentationResourceService.downloadConditionsGenerales().subscribe(
            res => {
                const reader = new FileReader();
                reader.addEventListener( 'loadend', ( e: FileReaderEvent ) => {
                    const text = e.target.result;
                    this.cgText = text;
                } );
                reader.readAsText( res );
            } );
    }


}
