import { FormControl } from '@angular/forms';
import { AbstractControl } from "@angular/forms";

declare const nirValidator: any;

export function ValidateNIR( c: AbstractControl ) {
    if ( nirValidator( c.value ) ) {
        return null;
    }
    return { validNIR: true };
}