import { Component, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ViewEncapsulation } from "@angular/core";
import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';
import { DownloadHelperService } from "src/app/api/dowload-helper.service";
import { Scavenger } from '@wishtack/rx-scavenger';
import { NotificationService } from "src/app/api/notification.service";

@Component({
    selector: 'app-new-adh-message-dialog',
    templateUrl: './new-adh-message-dialog.component.html',
    styleUrls: ['./new-adh-message-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,

})
export class NewAdhMessageDialogComponent implements OnDestroy, OnInit {
    downloadInProgress: boolean = false

    private _scavenger = new Scavenger(this);

    constructor(@Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<NewAdhMessageDialogComponent>, private adhesionEnLigneResourceService: AdhesionEnLigneResourceService,
        private downloadHelperService: DownloadHelperService, private notificationService: NotificationService) { }

    ngOnInit() {
    }

    telecharger() {
        this.downloadInProgress = true
        this.adhesionEnLigneResourceService.downloadDocumentsSignesContenusDansEnveloppe(this.data.enveloppeId).then(observable => observable.pipe(this._scavenger.collect()).subscribe(
            result => {
                this.downloadHelperService.saveOrOpenBlob(result, "DemandeDAdhesionFonpel.zip");
                this.downloadInProgress = false
            },
            _ => {
                this.notificationService.warn('Une erreur s\'est produite lors du téléchargement de votre Demande d\'adhésion');
                this.downloadInProgress = false
            }
        ));
    }

    closeDialog() {
        this.dialogRef.close(false);
    }

    ngOnDestroy() {
    }
}
