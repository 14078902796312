import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { TypeDemandeDTO, DemandeResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { shareReplay } from "rxjs/operators";
import { map } from "rxjs/internal/operators/map";

const CACHE_SIZE = 1;

@Injectable()
export class CacheService {

    // https://blog.thoughtram.io/angular/2018/03/05/advanced-caching-with-rxjs.html#implementing-a-basic-cache

    private cache$: Observable<Array<TypeDemandeDTO>>;

    constructor( private demandeResourceService: DemandeResourceService ) { }

    get typesDemande() {
        if ( !this.cache$ ) {
            return this.cache$ = this.requestTypeDemande().pipe( shareReplay( CACHE_SIZE ) );
        } else {
            return this.cache$;
        }
    }

    private requestTypeDemande() {
        return this.demandeResourceService.getListeTypeDemande();
    }
}
