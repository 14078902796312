import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe {
    transform(value: string) {
        if (value == null) {
            return null;
        }
        let newStr = '';
        for (let i = 0; i < value.length; i++) {
            newStr += value.substr(i, 1);
            if (Math.abs(i % 2) == 1 && i + 1 < value.length) {
                newStr += ' ';
            }
        }
        return newStr;
    }
}