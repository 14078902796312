import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ConfirmDialogComponent } from '../shared/components/confirm-dialog/confirm-dialog.component';
import { WarningDialogComponent } from "src/app/shared/components/warning-dialog/warning-dialog.component";
import { InfoDialogComponent } from "src/app/shared/components/info-dialog/info-dialog.component";
import { ErrorDialogComponent } from "src/app/shared/components/error-dialog/error-dialog.component";
import { NewAdhMessageDialogComponent } from "src/app/shared/components/new-adh-message-dialog/new-adh-message-dialog.component";
@Injectable( {
    providedIn: 'root'
} )
export class DialogService {

    constructor( private dialog: MatDialog ) { }

    openConfirmDialog( msg ) {
        return this.dialog.open( ConfirmDialogComponent, {
            width: '400px',
            disableClose: true,
            data: {
                message: msg
            }
        } );
    }

    openWarningDialog( msg ) {
        return this.dialog.open( WarningDialogComponent, {
            width: '390px',
            disableClose: true,
            data: {
                message: msg
            }
        } );
    }

    openErrorDialog( msg ) {
        return this.dialog.open( ErrorDialogComponent, {
            width: '390px',
            disableClose: true,
            data: {
                message: msg
            }
        } );
    }
    openErrorDialogWithWidth( msg, width ) {

        return this.dialog.open( ErrorDialogComponent, {
            width: width,
            disableClose: true,
            data: {
                message: msg
            }
        } );
    }
    openInfoDialog( msg ) {
        return this.dialog.open( InfoDialogComponent, {
            width: '390px',
            disableClose: true,
            data: {
                message: msg
            }
        } );
    }

    openMessageDialog( msg ) {
        return this.dialog.open( InfoDialogComponent, {
            width: '600px',
            disableClose: true,
            data: {
                message: msg
            }
        } );
    }
    openBienVenueDialog( msg, enveloppeId ) {
        return this.dialog.open( NewAdhMessageDialogComponent, {
            width: '900px',
            disableClose: true,
            data: {
                message: msg,
                enveloppeId:enveloppeId
            }
        } );
    }
}
