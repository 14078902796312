import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from "@angular/common";
import { Decimal } from "decimal.js/decimal";

@Pipe( {
    name: 'percent'
} )
export class PercentPipe implements PipeTransform {
    constructor( private decimalPipe: DecimalPipe ) { }

    transform( value: any, showUnit: boolean = false, showEmtpy: boolean = true ): any {
        const castValue = ( value instanceof Decimal ) ? value.toNumber() : value;
        if ( !castValue ) {
            return ( showEmtpy ) ? '' : 'Non disponible';
        }
        else {
            let result = this.decimalPipe.transform(( value instanceof Decimal ) ? value.toNumber() : value, '1.2-2' );
            if ( showUnit ) {
                return result + ' %';
            }
            else {
                return result;
            }
        }
    }

}
