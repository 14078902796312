import { PhoneNumberUtil, PhoneNumber, PhoneNumberType, PhoneNumberFormat } from 'google-libphonenumber';
import StringUtils from "src/app/utils/string-utils";

export default class TelephoneNumberUtils {
    static phoneNumberUtil = PhoneNumberUtil.getInstance();

    static toE164(inputNumber: string, countryCallingCode: number = 33) {
        if (!inputNumber) {
            return inputNumber;
        }
        var regionCodes = [].concat(this.phoneNumberUtil.getRegionCodeForCountryCode(countryCallingCode));
        try {
            const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(inputNumber, regionCodes[0]);
            const result = TelephoneNumberUtils.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.E164);
            //   console.log(result);
            return result;
        } catch (e) {
            // console.log( e );
            return inputNumber;
        }
    }

    static toNationalFormat(inputNumber: string, countryCallingCode: number = 33) {
        if (!inputNumber) {
            return inputNumber;
        }

        var regionCodes = [].concat(this.phoneNumberUtil.getRegionCodeForCountryCode(countryCallingCode));
        try {
            const phoneNumber = this.phoneNumberUtil.parseAndKeepRawInput(inputNumber, regionCodes[0]);
            const result = TelephoneNumberUtils.phoneNumberUtil.format(phoneNumber, PhoneNumberFormat.NATIONAL);
            return StringUtils.deleteWhitespace(result);
        } catch (e) {
            //console.log( e );
            return inputNumber;
        }
    }

    static toPrefix(inputNumber: string): number {

        if (!inputNumber) {
            return 33;
        }

        try {
            const phoneNumber = this.phoneNumberUtil.parse(inputNumber, "FR");
            const result = phoneNumber.getCountryCode();
            // console.log( result );
            return result;
        } catch (e) {
            //    console.log( e );
            return 33;
        }
    }
}