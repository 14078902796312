import { Directive } from '@angular/core';
import { ElementRef } from "@angular/core";
import { Renderer2 } from "@angular/core";
import { PatternValidator } from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";
import { Validator } from "@angular/forms";
import { OnChanges } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { ValidatorFn } from "@angular/forms";
import { Validators } from "@angular/forms";
import { ValidationErrors } from "@angular/forms";
import { PATTERN_VALIDATOR } from "@angular/forms/src/directives/validators";
import { NG_VALIDATORS } from "@angular/forms";
import { forwardRef } from "@angular/core";

@Directive( {
    selector: '[appMontantPattern]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MontantPatternDirective ), multi: true }
    ]
} )
export class MontantPatternDirective implements Validator {

    private _validator: ValidatorFn;
    private _onChange: () => void;

    constructor( private el: ElementRef, private renderer: Renderer2 ) {
    }

    ngOnInit() {
        this._validator = Validators.pattern( "^(\\d|\\s)*(\\.|\\,){0,1}(\\d|\\s){1,2}$" );
    }

    validate( c: AbstractControl ): ValidationErrors | null { return this._validator( c ); }

    registerOnValidatorChange( fn: () => void ): void { this._onChange = fn; }
}
