import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { environment } from 'src/environments/environment';
import { AppConfig } from 'src/app/utils/app.config';

@Component( {
    selector: 'app-contact',
    templateUrl: 'contactDialog.component.html',
    styleUrls: ['./contactDialog.component.scss']
} )
export class ContactDialogComponent implements OnInit {

    phoneGestion: string;
    mailCommercial: string;
    fonpelDocs: string;
    fonpelFAQ: string;
    constructor(
        public dialogRef: MatDialogRef<ContactDialogComponent>, private config: AppConfig
    ) { }

    ngOnInit() {
        this.phoneGestion = this.config.getConfig( 'phoneGestion' );
        this.mailCommercial = this.config.getConfig( 'mailCommercial' );
        this.fonpelDocs = this.config.getConfig( 'fonpelDocs' );
        this.fonpelFAQ = this.config.getConfig( 'fonpelFAQ' );
    }
}
