import { Injectable } from "@angular/core";
import { Decimal } from "decimal.js/decimal";

@Injectable()
export class DecimalHelperService {
    isDecimal( value ): boolean {
        try {
            new Decimal( this.normalizeNumber( value ) );
            return true;
        } catch ( e ) {
        }

        return false;
    }

    toDecimal( value ): Decimal {
        try {
            return new Decimal( this.normalizeNumber( value ) );
        } catch ( e ) {
        }

        return null;
    }

    normalizeNumber( stringNumber ) {
        if ( stringNumber ) {
            let result = stringNumber.toString().split( ',' ).join( '.' );
            result = result.split( ' ' ).join( '' );
            return result;
        } else {
            return stringNumber;
        }
    }

}