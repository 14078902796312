import { Component, OnInit } from '@angular/core';
import { Input } from "@angular/core";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";


import { SimpleChanges } from "@angular/core";
import { PrefixeCode } from "src/app/shared/components/prefix-tel/data/prefixe-code";
import { Prefix } from "src/app/shared/components/prefix-tel/model/prefix.model";

import { ValidatorFn, AbstractControl } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumber, PhoneNumberType } from 'google-libphonenumber';
import { ControlContainer } from "@angular/forms";
import { FormGroupDirective } from "@angular/forms";
import { Validators } from "@angular/forms";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function MobileNumberValidator( countryCallingCode: number = 33 ): ValidatorFn {
    return ( control: AbstractControl ): { [key: string]: any } => {
        let validNumber = false;
        if ( control != undefined && control.value != null && control.value != '' ) {

            var regionCodes = [].concat( phoneNumberUtil.getRegionCodeForCountryCode( countryCallingCode ) );
            try {
                const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
                    control.value, regionCodes[0]
                );
                validNumber = phoneNumberUtil.isValidNumber( phoneNumber );
                if ( validNumber ) {
                    const numberType = phoneNumberUtil.getNumberType( phoneNumber );
                    validNumber = validNumber && ( numberType == PhoneNumberType.MOBILE || numberType == PhoneNumberType.FIXED_LINE_OR_MOBILE );
                }
            } catch ( e ) { }

            return validNumber ? null : { 'wrongNumber': { value: control.value } };
        }
    }
}
@Component( {
    selector: 'app-prefix-tel',
    templateUrl: './prefix-tel.component.html',
    styleUrls: ['./prefix-tel.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],

    providers: [
        PrefixeCode
    ]
} )
export class PrefixTelComponent implements OnInit {
    _prefix: number;
    @Input() myFormControlName: string;
    @Input() myFormControlNamePrefixe: string;
    @Input() obligatoire: boolean;
    allPrefixes: Array<Prefix> = [];

    constructor( private prefixeCodeData: PrefixeCode, public parent: FormGroupDirective ) {
    }

    ngOnInit() {
        this.fetchCountryData();
        this.prefix = this.getControlPrefix().value || 33;
    }

    getControl() {
        return this.parent.form.get( this.myFormControlName );
    }
    getControlPrefix() {
        return this.parent.form.get( this.myFormControlNamePrefixe );
    }

    protected fetchCountryData(): void {
        this.prefixeCodeData.allPrefixe.forEach( c => {
            const prefix: Prefix = {
                countryCallingCode: +c[0],
                libelle: c[1].toString()
            }
            this.allPrefixes.push( prefix );
        } );
    }

    selectPrefix( prefixe ) {
        this.prefix = prefixe.countryCallingCode;
    }

    set prefix( countryCallingCode: number ) {
        this._prefix = countryCallingCode;
        if ( this.obligatoire ) {
            this.getControl().setValidators( [Validators.required, MobileNumberValidator( this._prefix )] );
        } else {
            this.getControl().setValidators( [MobileNumberValidator( this._prefix )] );
        }
        this.getControl().updateValueAndValidity();

        this.getControlPrefix().patchValue( countryCallingCode );
        this.getControlPrefix().updateValueAndValidity();
    }

    get prefix(): number {
        return this._prefix;
    }
}