import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { SnackSuccessComponent } from '../shared/components/snack-success/snack-success.component';
import { SnackErrorComponent } from '../shared/components/snack-error/snack-error.component';
import { SnackWaitComponent } from '../shared/components/snack-wait/snack-wait.component';

import {

    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material';
@Injectable( {
    providedIn: 'root'
} )
export class NotificationService {
    message: string = 'Snack Bar opened.';
    actionButtonLabel: string = 'Retry';
    action: boolean = true;
    setAutoHide: boolean = true;
    autoHide: number = 100000;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';
    public waitComp: SnackWaitComponent;
    constructor( public snackBar: MatSnackBar ) { }

    success( msg ) {
        this.snackBar.openFromComponent( SnackSuccessComponent, {
            panelClass: ['snack-success'],
            duration: 3000,
            data: msg
        } );
    }

    warn( msg ) {
        this.snackBar.openFromComponent( SnackErrorComponent, {
            panelClass: ['snack-error'],
            duration: 3000,
            data: msg
        } );
    }
    warnCourt( msg ) {
        this.snackBar.openFromComponent( SnackErrorComponent, {
            panelClass: ['snack-error'],
            duration: 1000,
            data: msg
        } );
    }
    wait( msg, buttonCloseShown ) {

        let config = new MatSnackBarConfig();
        config.verticalPosition = this.verticalPosition;
        config.panelClass = ['snack-wait'];
        config.data = { msg, buttonCloseShown };
        config.horizontalPosition = this.horizontalPosition;
        config.duration = this.setAutoHide ? this.autoHide : 0;

        return this.snackBar.openFromComponent( SnackWaitComponent, config );

    }
    close() {
        this.snackBar.dismiss();
    }
}
