import { Component, OnInit } from '@angular/core';
import { Input } from "@angular/core";
import { AppConfig } from "src/app/utils/app.config";
import { ViewEncapsulation } from "@angular/core";
import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';
import { AELAdherentDTO } from '@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist';
import { CgComponent } from 'src/app/shared/components/new-adh-form-parts/cg/cg.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DownloadHelperService } from "src/app/api/dowload-helper.service";

interface FileReaderEventTarget extends EventTarget {
    result: string;
}

interface FileReaderEvent extends Event {
    target: FileReaderEventTarget;
    getMessage(): string;
}

@Component( {
    selector: 'app-cg-part',
    templateUrl: './cg-part.component.html',
    styleUrls: ['./cg-part.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class CgPartComponent implements OnInit {

    @Input() formGroup;
    @Input() adherent: AELAdherentDTO
    fonpelDocs: string;
    cgText;
    idGedNotice = "Fon-010(o)VZ9ZWNlH1YE6sjZVhwz7MpYDY4N3hyE52jH2awIlO4g=";
    
    constructor( private dialog: MatDialog, private config: AppConfig, private adhesionEnLigneService: AdhesionEnLigneResourceService, private downloadHelperService: DownloadHelperService, ) {
        this.fonpelDocs = this.config.getConfig( 'fonpelDocs' );
    }

    ngOnInit() {
        this.formGroup.get( 'getcGsDocumentsValidees' ).disable();
        this.formGroup.get( 'getcGsEtMLValidees' ).disable();
  
        if  (!this.isVerifAutoOK()){
            // On enleve le validator requiered
            this.formGroup.get( 'getcGsEtMLValidees' ).clearValidators();
        }

        console.log(this.adherent);
        
    }

    opencg() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.minHeight = 780;
        dialogConfig.width = '800px';
        dialogConfig.data = {
            action: false
        };

        const dialogRef = this.dialog.open( CgComponent, dialogConfig );
        
        if  (this.isVerifAutoOK()){
            this.formGroup.get( 'getcGsEtMLValidees' ).enable();
        }
    }

    isVerifAutoOK(): boolean{
        return this.adherent && this.adherent.controleManuelIdentiteAFaire === false
    }

    downloadNotice() {

        this.adhesionEnLigneService.downloadNotice().then( observable => observable.subscribe(
            result => {
                this.downloadHelperService.saveOrOpenBlob( result, "Notice d'information" + '.pdf' );
                this.formGroup.get( 'getcGsDocumentsValidees' ).enable();
            }
        ) )
    }
}
