import { Component, OnInit, Inject } from '@angular/core';
import { Input } from "@angular/core";

import { ViewEncapsulation } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ControlContainer } from "@angular/forms";

import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Validators } from "@angular/forms";
import { NotificationService } from "src/app/api/notification.service";
import { AppConfig } from 'src/app/utils/app.config';

@Component( {
    selector: 'app-souscriptionInfosDialog-part',
    templateUrl: './souscriptionInfosDialog-part.component.html',
    styleUrls: ['./souscriptionInfosDialog-part.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    encapsulation: ViewEncapsulation.None
} )
export class SouscriptionInfosDialogPartComponent implements OnInit {
    phoneGestion: string;
    mailGestion: string;
    constructor( public dialogRef: MatDialogRef<SouscriptionInfosDialogPartComponent>, @Inject( MAT_DIALOG_DATA ) public data, private config: AppConfig ) {


    }

    ngOnInit() {
        this.phoneGestion = this.config.getConfig( 'phoneGestion' );
        this.mailGestion = this.config.getConfig( 'mailGestion' );
    }

    onContinuer(): void {
        this.dialogRef.close( { valid: true } );
    }
    onNoClick(): void {
        this.dialogRef.close( { valid: false } );
    }
}
