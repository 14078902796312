import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValidateEmailComponent } from "src/app/components/validate-email/validate-email.component";
import { UnauthenticatedNewAdhesionComponent } from "src/app/components/unauthenticated-new-adhesion/unauthenticated-new-adhesion.component";
import { AuthGuard } from './shared/auth-guard';

const routes: Routes = [
    { path: 'validateEmail', component: ValidateEmailComponent },
    { path: 'addAdhesionNoAuth', component: UnauthenticatedNewAdhesionComponent },
    { path: 'saisieAdhesionInterne', component: UnauthenticatedNewAdhesionComponent, canActivate: [AuthGuard], data: { provenanceInterne: true, roles: ['SaisieAdhesionInterne'] } },
    { path: 'nouvelleAdhesion', component: UnauthenticatedNewAdhesionComponent },
    { path: 'nouvelleAdhesion/:idDeTransfertPourSauvegarde', component: UnauthenticatedNewAdhesionComponent },
    { path: '', loadChildren: './home/home.module#HomeModule', data: { layout: { subheaderClass: 'no-safezone' } } }

];

@NgModule( {
    imports: [RouterModule.forRoot( routes )],
    exports: [RouterModule]
} )
export class AppRoutingModule { }
