import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Input } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ControlContainer } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Validators } from "@angular/forms";

import { AdhesionEnLigneResourceService } from 'src/app/api/adhesion-en-ligne-resource.service';

@Component( {
    selector: 'app-smsDialog-part',
    templateUrl: './smsDialog-part.component.html',
    styleUrls: ['./smsDialog-part.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    encapsulation: ViewEncapsulation.None
} )
export class SmsDialogPartComponent implements OnInit {

    formGroupSaisie: FormGroup;
    adhesionEnLigneResourceService: AdhesionEnLigneResourceService;
    idTransfertAdhesion: string;
    codeSMSValid = true;
    constructor( public dialogRef: MatDialogRef<SmsDialogPartComponent>, @Inject( MAT_DIALOG_DATA ) public data, private _formBuilder: FormBuilder ) {
        this.adhesionEnLigneResourceService = data.adhesionEnLigneResourceService;
        this.idTransfertAdhesion = data.idTransfertAdhesion;

    }
    ngOnInit() {
        this.formGroupSaisie = this._formBuilder.group( {
            code: ['', Validators.required]
        } );
        this.formGroupSaisie.get( 'code' ).valueChanges.subscribe(( change ) => {
            this.codeSMSValid = true;
        } );
    }
    onNoClickSaisie(): void {
        this.dialogRef.close( {
            valid: false
        } );
    }
    onValiderSaisie(): void {
        if ( this.formGroupSaisie.valid ) {
            const codeSMSSaisie = this.formGroupSaisie.get( 'code' ).value;
            this.adhesionEnLigneResourceService.verifierCoderSMS( this.idTransfertAdhesion, codeSMSSaisie ).subscribe(
                result => {

                    if ( result ) {
                        this.dialogRef.close( {
                            valid: true
                        } );
                    } else {
                        this.codeSMSValid = false;
                    }
                }, error => {
                    this.codeSMSValid = false;
                } );

        }
    }
}
