import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule, MatButtonModule, MatProgressSpinner, MatProgressSpinnerModule, MatCardModule, MatDatepickerModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatDividerModule, MatDialogModule } from '@angular/material';
import { BASE_PATH, ConditionsGeneralesResourceService, AdherentResourceService, DownloadResourceService, AdhesionEluResourceService, DonneesPersonnellesResourceService, DemandeResourceService, EmailValidationResourceService, RecaptchaValidationResourceService, UtilsResourceService, AdhesionEnLigneAuthResourceService, AdhesionEnLigneNoAuthResourceService, DocumentationResourceService } from '@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist';
import { AdhesionEnLigneResourceService } from './api/adhesion-en-ligne-resource.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { MatIconModule } from '@angular/material/icon';
import { registerLocaleData, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SnackErrorComponent } from './shared/components/snack-error/snack-error.component';
import { SnackWaitComponent } from './shared/components/snack-wait/snack-wait.component';
import { SnackSuccessComponent } from './shared/components/snack-success/snack-success.component';
import { SharedModule } from './shared/shared.module';
import { AppConfig } from './utils/app.config';
import { HttpModule } from '@angular/http';
import { QuitConfirmDialogComponent } from './shared/components/quit-confirm-dialog/quit-confirm-dialog.component';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { JustifInputDialogComponent } from "./shared/components/justif-input-dialog/justif-input-dialog.component";
import { JustifInputComponent } from "./shared/components/justif-input/justif-input.component";
import { MatMomentDateModule, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { WarningDialogComponent } from "./shared/components/warning-dialog/warning-dialog.component";
import { InfoDialogComponent } from "./shared/components/info-dialog/info-dialog.component";
import { HelperServiceService } from "./api/helper-service.service";
import { GlobalLoadingService } from "./api/global-loading.service";
import { DirectivesModule } from "src/app/directives/directives.module";
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { ValidateEmailComponent } from "./components/validate-email/validate-email.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CacheService } from "./api/cache.service";
import { ReactiveFormsModule } from "@angular/forms";
import { PipesModuleModule } from "./pipes/pipes-module.module";
import { ErrorDialogComponent } from "src/app/shared/components/error-dialog/error-dialog.component";
import { UnauthenticatedNewAdhesionComponent } from './components/unauthenticated-new-adhesion/unauthenticated-new-adhesion.component';
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { NewAdhService } from "src/app/api/new-adh.service";
import { DecimalHelperService } from "src/app/api/decimal-helper.service";
import { DownloadHelperService } from "src/app/api/dowload-helper.service";
import { SmsDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/smsDialog-part/smsDialog-part.component';
import { UpperCasePipe } from "@angular/common";
import * as moment from 'moment';
import { SouscriptionInfosDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/souscriptionInfosDialog-part/souscriptionInfosDialog-part.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

registerLocaleData( localeFr );

export function initConfig( config: AppConfig, keycloackService: KeycloakService ) {
    return () => config.load( keycloackService );
}

@NgModule( {
    declarations: [
        AppComponent,
        ValidateEmailComponent,
        UnauthenticatedNewAdhesionComponent
    ],
    imports: [
        HttpClientModule,
        HttpModule,
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MatDividerModule,
        MatIconModule,
        KeycloakAngularModule,
        MatCardModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        DirectivesModule,
        MatRadioModule,
        MatProgressSpinnerModule,
        MatMomentDateModule,
        MatButtonModule,
        FormsModule,
        PipesModuleModule,
        MatPasswordStrengthModule,


    ],

    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true }
        },
        AppConfig,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig, KeycloakService],
            multi: true
        },
        {
            provide: BASE_PATH,
            useValue: '.',
            deps: [environment]
        },
        ConditionsGeneralesResourceService,
        AdherentResourceService,
        DownloadResourceService,
        DemandeResourceService,
        RecaptchaValidationResourceService,
        AdhesionEnLigneAuthResourceService,
        AdhesionEnLigneNoAuthResourceService,
        AdhesionEnLigneResourceService,
        DatePipe,
        UtilsResourceService,
        HelperServiceService,
        DownloadHelperService,
        DecimalHelperService,
        NewAdhService,
        GlobalLoadingService,
        CacheService,
        DonneesPersonnellesResourceService,
        AdhesionEluResourceService,
        EmailValidationResourceService,
        DocumentationResourceService, SmsDialogPartComponent,
        UpperCasePipe,

    ],
    entryComponents: [SnackErrorComponent, SnackWaitComponent, SnackSuccessComponent, QuitConfirmDialogComponent, ConfirmDialogComponent, WarningDialogComponent, ErrorDialogComponent, InfoDialogComponent, JustifInputComponent, JustifInputDialogComponent, SmsDialogPartComponent, SouscriptionInfosDialogPartComponent],
    bootstrap: [AppComponent]
} )
export class AppModule {

    constructor() {
        this.overrideDate();
    }

    overrideDate() {

        Date.prototype.toJSON = function( key ) {
            return moment( this ).toJSON();
        }

        moment.fn.toJSON = function() {
            var result = this.format( 'YYYY-MM-DD' );
            return result;
        }
    }
}
