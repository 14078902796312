import { Component, OnInit, Inject } from '@angular/core';
import { OnDestroy } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ControlContainer } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { of, BehaviorSubject, timer, Subscription, Subject } from "rxjs";
import IdCheckIo from 'idcheckio-sdk';
import { AfterContentInit } from "@angular/core";
import { AfterViewChecked } from "@angular/core";
import { Scavenger } from "@wishtack/rx-scavenger/dist/scavenger";
import { AdhesionEnLigneResourceService } from "src/app/api/adhesion-en-ligne-resource.service";
import { AFile, WebOnboardingContext, OnboardingNotification } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import TelephoneNumberUtils from "src/app/utils/telephone-utils";
import { switchMap } from "rxjs/operators";
import { tap } from "rxjs/internal/operators/tap";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { environment } from 'src/environments/environment';
import { AppConfig } from "src/app/utils/app.config";

type State = 'NumPhoneCaptured' | 'error' | 'webcapture' | 'phonecapture' | 'loadingPersonInfo';
@Component( {
    selector: 'app-identiteParcoursWebDialog-part',
    templateUrl: './identiteParcoursWebDialog-part.component.html',
    styleUrls: ['./identiteParcoursWebDialog-part.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    encapsulation: ViewEncapsulation.None
} )
export class IdentiteParcoursWebDialogPartComponent implements AfterViewChecked, OnDestroy {
    // static environnementARIADNEXT: any;
    subscription: Subscription;


    protected formGroup;
    private _scavenger = new Scavenger( this );
    personInfo: AFile;
    protected state: State;

    protected boutonAnnulerShowen = true;
    /*  private personInfo: PersonInfo;
      protected state: FormState = 'choosecapturemode';*/
    messageErreur: string = "";
    erreurTehnique = false;
    private webOnboardingContext: WebOnboardingContext;
    onboardingUid: string;
    fileUid: string;
    private idcheck: IdCheckIo;
    private nombreEssaiPieceIdentite = 0;
    portable: string;
    private environnementARIADNEXT: string;
    public spinnerSubject = new BehaviorSubject<boolean>( false );
    public spinner$ = this.spinnerSubject.asObservable();
    private stopPolling$ = new Subject();
    nbChangementPieceIdentiteMax;
    wordings;

    nbEssaiKOPieceIdentite;


    constructor( public dialogRef: MatDialogRef<IdentiteParcoursWebDialogPartComponent>, @Inject( MAT_DIALOG_DATA ) public data, private _formBuilder: FormBuilder
        , private adhesionEnLigneResourceService: AdhesionEnLigneResourceService, private sanitizer: DomSanitizer, private config: AppConfig ) {

        this.environnementARIADNEXT = this.config.getConfig( 'environnementARIADNEXT' );
        this.onboardingUid = data.onboardingUid;
        this.fileUid = data.fileUid;
        this.nombreEssaiPieceIdentite = data.nombreEssaiPieceIdentite || 0;
        this.state = data.state;
        this.spinnerSubject.next( false );
        this.nbChangementPieceIdentiteMax = this.config.getConfig( 'nbChangementPieceIdentiteMax' );
        this.nbEssaiKOPieceIdentite = this.config.getConfig( 'nbEssaiKOPieceIdentite' );
        this.wordings = this.config.getConfig( 'wordings' );
    }

    ngOnInit() {
        if ( this.state == 'phonecapture' ) {
            this.initPhoneCapture();
        }
    }

    ngAfterViewChecked(): void {
        if ( this.state == 'webcapture' ) {
            this.initWebCapture();
        }
    }

    ngOnDestroy(): void {
        this.stopPolling$.next();
    }

    initPhoneCapture() {
        this.formGroup = this._formBuilder.group( {
            numeroMobileFrance: [TelephoneNumberUtils.toNationalFormat( null )],
            prefixeMobile: [TelephoneNumberUtils.toPrefix( null )],

        } );
    }

    initWebCapture() {
        if ( !this.idcheck ) {
            var elementExists = document.getElementById( "idcheck-component" );
            if ( elementExists ) {
                this.idcheck = IdentiteParcoursWebDialogPartComponent.createIdCheck( this.environnementARIADNEXT, this.wordings );
                if ( this.onboardingUid && this.fileUid ) {
                    this.webOnboardingContext = {
                        onboardingUid: this.onboardingUid,
                        fileUid: this.fileUid
                    }
                    this.initIdCheck( this.idcheck, this.onboardingUid );
                } else {
                    this.adhesionEnLigneResourceService.getFreshAriadFrame().subscribe( webOnboardingContext => {
                        //console.log( webOnboardingContext.onboardingUid );
                        this.webOnboardingContext = webOnboardingContext;
                        this.onboardingUid = webOnboardingContext.onboardingUid;
                        this.fileUid = webOnboardingContext.fileUid;
                        this.initIdCheck( this.idcheck, this.webOnboardingContext.onboardingUid );
                    }, error => {
                       // console.log( error );
                        this.goToErreurTechnique();

                    } );
                }
            }
        }
    }

    private static createIdCheck( env, wordings ): IdCheckIo {
        return new IdCheckIo( {
            platform: env,
            // mode: 'DOM',

            theme: {
                hideHeader: true,
                //   hideStepper: false,
                //   forceMobileLayout: false,

                // cf https://github.com/ariadnext/IDCHECK.IO_SDK-example-js/blob/master/examples/2-images.html
                pageTitle: {
                    //    width: '300px',
                    //    hideImagesBackground: true,
                },
                images: {
                    //    ID: 'https://localhost:3000/assets/images/logo_fonpel.png'
                },

                // Cf. https://github.com/ariadnext/IDCHECK.IO_SDK-example-js/blob/master/examples/3-styles.html
                btnBgColor: '#0095d4',
                btnHoverBgColor: '#0095d4',
                btnTxtColor: 'white',
                btnBorderRadius: '20px',

            },
            wordings,
            // https://www.npmjs.com/package/idcheckio-sdk#handling-events
            navigation: {
                skipEnd: true   // Important!!!!!!!!!
            }
        } );
    }
    // https://www.npmjs.com/package/idcheckio-sdk

    private initIdCheck( idcheck: IdCheckIo, onboardingId: string ): void {
        if ( idcheck ) {
            idcheck.init( { uid: onboardingId, element: '#idcheck-component' } );

            idcheck.on( 'ENDED', ( event ) => this.captureEnded() );
            idcheck.on( 'ERROR', ( event ) => this.captureError());
            idcheck.on( 'ACTIVITY', ( event ) => this.captureError());   
        }
    }

    resetInfoWsWebOnBoarding() {
        this.onboardingUid = null;
        this.fileUid = null;
    }

    static readonly MESSAGEA="La qualité de la copie de votre pièce d’identité ne permet pas la lecture automatique de vos informations. <br><br>Vous allez pouvoir procéder à la saisie manuelle de vos données d’identité et poursuivre votre demande d’adhésion.  <br><br>En fin de parcours, nous vous communiquerons les modalités pour finaliser votre demande d’adhésion."
    static readonly MESSAGEB="Votre justificatif d’identité ne peut être lu (qualité de la copie insuffisante, date de validité erronée, ...). <br><br>Merci de télécharger un nouveau fichier d'une pièce d’identité française en cours de validité.";
  
    captureError(): void {
        this.nombreEssaiPieceIdentite += 1
   
        if ( this.nombreEssaiPieceIdentite > this.nbEssaiKOPieceIdentite ) {
            this.messageErreur = IdentiteParcoursWebDialogPartComponent.MESSAGEA
            this.state = 'error'
        } 
    }

    captureEnded(): void {
        this.nombreEssaiPieceIdentite += 1;
   
        this.state = 'loadingPersonInfo';

        this.adhesionEnLigneResourceService.getAFile( this.webOnboardingContext.fileUid, this.webOnboardingContext.onboardingUid ).pipe( this._scavenger.collect() ).subscribe(
            personInfo => {
                this.resetInfoWsWebOnBoarding();
                this.personInfo = personInfo;
                //console.log( this.personInfo );
                if ( this.personInfo.valide ) {
                    if ( this.personInfo.nationalite == 'FRA' || this.personInfo.nationalite == null || this.personInfo.nationalite == '' ) {

                        this.nombreEssaiPieceIdentite = 0

                        this.dialogRef.close( { valid: true, onboardingUid: this.onboardingUid, fileUid: this.fileUid, personInfo: this.personInfo, erreurTehnique: this.erreurTehnique, nombreEssaiPieceIdentite: this.nombreEssaiPieceIdentite } );

                    } else {
                        if ( this.nombreEssaiPieceIdentite >= this.nbEssaiKOPieceIdentite ) {
                            this.messageErreur = IdentiteParcoursWebDialogPartComponent.MESSAGEA
                            
                        } else {
                            this.messageErreur = IdentiteParcoursWebDialogPartComponent.MESSAGEB
                        }
                        this.state = 'error';
                    }
                } else {
                    if ( this.nombreEssaiPieceIdentite >= this.nbEssaiKOPieceIdentite ) {
                        this.messageErreur = IdentiteParcoursWebDialogPartComponent.MESSAGEA
                    
                    } else {
                        this.messageErreur = IdentiteParcoursWebDialogPartComponent.MESSAGEB
                    }
                    this.state = 'error';
                }
            }, error => {
                this.goToErreurTechnique();
            } );

    }

    goToErreurTechnique() {
        this.messageErreur = "Le service est momentanément indisponible. <br><br> Nous vous redirigeons vers une saisie manuelle de vos informations d'identité.";
        this.state = 'error';
        this.erreurTehnique = true;
    }

    onNoClick(): void {
        this.dialogRef.close( { onboardingUid: this.onboardingUid, fileUid: this.fileUid, valid: false, personInfo: null, erreurTechnique: this.erreurTehnique, nombreEssaiPieceIdentite: this.nombreEssaiPieceIdentite } );
    }

    onChangePhoneNumber() {
        this.state = 'phonecapture';
    }

    onValiderSaisiePhoneNumber() {
        if ( this.formGroup.valid ) {
            this.spinnerSubject.next( true );
            this.portable = TelephoneNumberUtils.toE164( this.formGroup.get( 'numeroMobileFrance' ).value, this.formGroup.get( 'prefixeMobile' ).value )


            this.adhesionEnLigneResourceService.getFreshAriadContactByPhone( this.portable ).subscribe( webOnboardingContext => {

                //console.log( webOnboardingContext );

                this.webOnboardingContext = webOnboardingContext;
                this.onboardingUid = webOnboardingContext.onboardingUid;
                this.fileUid = webOnboardingContext.fileUid;


                this.startNoficationPolling( webOnboardingContext.onboardingUid );

                this.spinnerSubject.next( false );
                this.state = 'NumPhoneCaptured';
            }, error => {
                this.spinnerSubject.next( false );
                //console.log( "Ereuur onBoardingphone=============" );
                this.goToErreurTechnique();
                //console.log( error );
            } );
        }
    }

    startNoficationPolling( onboardingUid ): void {
        this.subscription = timer( environment.pollingNotificationPeriod, environment.pollingNotificationPeriod ).pipe(
            switchMap(() => this.adhesionEnLigneResourceService.getNotification( onboardingUid ) ),
            takeUntil( this.stopPolling$ )
        ).subscribe( result => this.traiterNotification( result ) );
    }

    traiterNotification( notification: OnboardingNotification ): void {
        //console.log( notification );

        if ( notification && notification.type !== OnboardingNotification.TypeEnum.Unknown ) {
            this.stopPolling$.next();
            if ( notification.type === OnboardingNotification.TypeEnum.Success ) {
                this.captureEnded();
            }
            else {
                this.goToErreurTechnique();
            }
        }
    }


}
