import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingService {
  private $loading: BehaviorSubject<number>;

  constructor () {
    this.$loading = new BehaviorSubject(0);
  }

  asObservable (): Observable<boolean> {
    return this.$loading.pipe(map((value) => {
      return value > 0;
    }));
  }

  get loading () {
    return this.$loading.getValue() > 0;
  }

  startLoading () {
    this.$loading.next(this.$loading.getValue() + 1);
  }

  stopLoading () {
    this.$loading.next(this.$loading.getValue() - 1);
  }
}
