import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive( {
    selector: '[digitOnly]'
} )
export class DigitOnlyDirective {
    private decimalCounter = 0;
    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];
    @Input() decimal = false;
    inputElement: HTMLElement;
    constructor( public el: ElementRef ) {
        this.inputElement = el.nativeElement;
    }

    @HostListener( 'keydown', ['$event'] )
    onKeyDown( e: KeyboardEvent ) {
        e.key === '.' ? this.decimalCounter++ : '';

        if (
            this.navigationKeys.indexOf( e.key ) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            ( e.key === 'a' && e.ctrlKey === true ) || // Allow: Ctrl+A
            ( e.key === 'c' && e.ctrlKey === true ) || // Allow: Ctrl+C
            ( e.key === 'v' && e.ctrlKey === true ) || // Allow: Ctrl+V
            ( e.key === 'x' && e.ctrlKey === true ) || // Allow: Ctrl+X
            ( e.key === 'a' && e.metaKey === true ) || // Allow: Cmd+A (Mac)
            ( e.key === 'c' && e.metaKey === true ) || // Allow: Cmd+C (Mac)
            ( e.key === 'v' && e.metaKey === true ) || // Allow: Cmd+V (Mac)
            ( e.key === 'x' && e.metaKey === true ) || // Allow: Cmd+X (Mac)
            ( this.decimal && e.key === '.' && this.decimalCounter <= 1 ) // Allow: only one decimal point
        ) {
            // let it happen, don't do anything
            return;
        }
        // Ensure that it is a number and stop the keypress
        if ( e.key === ' ' || isNaN( Number( e.key ) ) ) {
            e.preventDefault();
        }
    }

    @HostListener( 'paste', ['$event'] )
    onPaste( event ) {
        //event.preventDefault();
        const pastedInput: string = event.clipboardData
            .getData( 'text/plain' )
            .replace( /[^0-9.]/g, '' ); // get a digit-only string

        if ( this.isValidDecimal( pastedInput ) ) {
            document.execCommand( 'insertText', false, pastedInput );
        }
    }

    @HostListener( 'drop', ['$event'] )
    onDrop( event: any ) {
        event.preventDefault();
        const textData = event.dataTransfer.getData( 'text' ).replace( /[^0-9.]/g, '' );
        this.inputElement.focus();

        if ( this.isValidDecimal( textData ) ) {
            document.execCommand( 'insertText', false, textData );
        }
    }

    isValidDecimal( string: string ): boolean {
        return this.decimal && string.split( '.' ).length <= 2;
    }
}