import { FormControl } from '@angular/forms';
import { AbstractControl } from "@angular/forms";
import { ValidationErrors } from "@angular/forms";

//From MailUtil.EMAIL_REGEX                         "^[\\w!#$%&'*+/=?`{|}~^-]+(?:\\.[\\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$",
var SOFAXIS_EMAIL_REGEXP = /^(?=.{1,254}$)(?=.{1,64}@)[\w!#$%&'*+/=?`{|}~^-]+(\.[\w!#$%&'*+/=?`{|}~^-]+)*@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;


function isEmptyInputValue( value: any ): boolean {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

export function ValidatorSofaxisEmail( control: AbstractControl ): ValidationErrors | null {
    if ( isEmptyInputValue( control.value ) ) {
        return null;  // don't validate empty values to allow optional controls
    }
    return SOFAXIS_EMAIL_REGEXP.test( control.value ) ? null : { 'email': true };
}