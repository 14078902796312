import { Directive, DoCheck, Input, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

/*
Cf. https://github.com/angular/components/issues/7891
*/

export interface IfErrorContext {
    errors: { [error: string]: any };
}

@Directive( {
    selector: '[ifError]'
} )
export class IfErrorDirective implements DoCheck {

    @Input( 'ifError' ) control: FormControl | null = null;
    @Input() set parent( value: NgForm | FormGroupDirective | null ) {
        this._parent = value;
    }
    @Input() set errorStateMatcher( value: ErrorStateMatcher ) {
        this._errorStateMatcher = value;
    }


    private _parent: NgForm | FormGroupDirective | null;
    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<IfErrorContext>,
        private _errorStateMatcher: ErrorStateMatcher,
        @Optional() parentForm: NgForm,
        @Optional() parentFormGroup: FormGroupDirective,
    ) {
        this._parent = parentForm || parentFormGroup || null;
    }

    private lastValid = false;
    ngDoCheck(): void {
        const valid = !!this._errorStateMatcher.isErrorState( this.control, this._parent )
        if ( valid !== this.lastValid ) {
            this.lastValid = valid;
            if ( valid ) {
                const errors = ( this.control && this.control.errors ) || {};
                this.viewContainer.createEmbeddedView( this.templateRef, { errors } );
            } else {
                this.viewContainer.clear();
            }
        }
    }

}
