import { Component, OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from "@angular/core";
import { ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material';
import { IdentiteParcoursWebDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/identiteParcoursWebDialog-part/identiteParcoursWebDialog-part.component';
import { AppConfig } from "src/app/utils/app.config";
import { Validators, ValidatorFn, AbstractControl } from "@angular/forms";
import { UtilsResourceService, Pays, NomenclatureDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { AELAdherentDTO, PieceJustificativeDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import * as moment from 'moment';
import { AFile } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
type FormState = 'telechargementmode' | 'manuelverifymode' | 'automaticverifymode';

@Component({
    selector: 'app-identite-part',
    templateUrl: './identite-part.component.html',
    styleUrls: ['./identite-part.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class IdentitePartComponent implements OnInit {
    formGroup: FormGroup;
    formGroupManual: FormGroup;
    @Input() adherent: AELAdherentDTO;
    @Input() provenanceExterne: boolean;
    @Input() pieceIdentiteModeModification: boolean;
    @Output() pieceidentiteVerified = new EventEmitter();

    public listePays: Array<Pays>;
    PaysParDefaut: Pays;
    protected state: FormState = 'telechargementmode';
    nombreEssaiPieceIdentite = 0;
    onboardingUid: string = null;
    fileUid: string = null;
    nbEssaiKOPieceIdentite;
    nbChangementPieceIdentiteMax;
    nombreChangementPieceidentite = 0;
    formGroupManualSubmitted = false;
    codeCiviliteASaisir = false;
    paysNaissance: Pays;
    maxSizePieceIdentite: string;
    allowedExtensionsPieceIdentite: string;
    premierClick:boolean=true
    get dateValidite() { return this.formGroupManual.get('dateValidite'); }

    constructor(private utilsService: UtilsResourceService, private dialog: MatDialog, private _formBuilder: FormBuilder, private config: AppConfig) {
        this.nbChangementPieceIdentiteMax = this.config.getConfig('nbChangementPieceIdentiteMax');
        this.nbEssaiKOPieceIdentite = this.config.getConfig( 'nbEssaiKOPieceIdentite' );
        this.maxSizePieceIdentite = this.config.getConfig('maxsizePieceIdentite');
        this.allowedExtensionsPieceIdentite = this.config.getConfig('allowedExtensionsPieceIdentite');
    }

    async ngOnInit() {
        if (this.pieceIdentiteModeModification) {
            if (this.adherent.controleManuelIdentiteAFaire) {
                this.goToSaisieManuel();
            } else {
                this.goToSaisieAutomatic();

            }
        }

        this.utilsService.getListePays().subscribe(result => {
            this.listePays = result;
            if (this.provenanceExterne) {
                this.PaysParDefaut = { codePays: 235, libelle: "FRANCE" };
            }
        })

    }

    compareObjects(o1: Pays, o2: Pays): boolean {
        return o1 && o2 ? o1.codePays === o2.codePays : o1 === o2;
    }

    OpenParcoursDesktop() {
        this.premierClick=false
       
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            onboardingUid: this.onboardingUid,
            fileUid: this.fileUid,
            nombreEssaiPieceIdentite: this.nombreEssaiPieceIdentite,
            state: 'webcapture'
        };

        const dialogRef = this.dialog.open(IdentiteParcoursWebDialogPartComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {

            this.onboardingUid = result.onboardingUid;
            this.fileUid = result.fileUid;
            if (!result.erreurTechnique) {
                this.nombreEssaiPieceIdentite = result.nombreEssaiPieceIdentite;

                if (result.valid) {
                    this.toAdherentDTOFrom(result.personInfo);
                    this.goToSaisieAutomatic();

                } else {
                    if (this.nombreEssaiPieceIdentite >= this.nbEssaiKOPieceIdentite) {
                        this.goToSaisieManuel();
                    }
                }
            } else {
                this.goToSaisieManuel();
            }
        });

    }

    OpenParcoursTelephone() {
        this.premierClick=false

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.width = '500px';
        dialogConfig.data = {
            action: false,
            state: 'phonecapture'

        };
        const dialogRef = this.dialog.open(IdentiteParcoursWebDialogPartComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (!result.erreurTechnique) {
                this.nombreEssaiPieceIdentite = result.nombreEssaiPieceIdentite;
                if (result.valid) {
                    this.toAdherentDTOFrom(result.personInfo);
                    this.goToSaisieAutomatic();

                } else {
                    if (this.nombreEssaiPieceIdentite >= this.nbEssaiKOPieceIdentite) {
                        this.goToSaisieManuel();
                    }
                }
            } else {
                this.goToSaisieManuel();
            }
        });
    }

    OpenParcoursManuel() {
        this.goToSaisieManuel();
    }

    getPays() {
        var paysParDefaut = { codePays: 235, libelle: "FRANCE" };

        if (this.adherent == undefined || this.adherent == null || this.adherent.codePaysNaissance == null) {
            if (this.provenanceExterne) {
                return paysParDefaut;
            }
            else {
                return null;
            }
        }


        this.paysNaissance = { codePays: this.adherent.codePaysNaissance, libelle: this.adherent.paysNaissance }
        return this.paysNaissance;
    }
    DateValiditePieceValidator(): ValidatorFn {
        return (controldate: AbstractControl): { [key: string]: boolean } | null => {
            if (controldate == undefined || controldate == null || controldate.value == undefined || controldate.value == null) {
                return {
                    'required': true
                };
            }
            if (moment(controldate.value).isBefore(moment())) {
                return {
                    'dateBeforeDateJour': true
                };
            }
            return null;
        }
    }
    geDateValiditePieceErrorMessage() {
        return this.dateValidite.hasError('required') ? 'Veuillez saisir une date de valide' :
            this.dateValidite.hasError('dateBeforeDateJour') ? 'La date de validité de la pièce doit être postérieure à la date du jour' : '';
    }

    buildFormAdherentManual() {
        this.formGroupManual = this._formBuilder.group({
            civilite: [this.adherent.codeCivilite, [Validators.required]],
            nomPatronymique: [this.adherent.nomPatronymique, Validators.required],
            prenom: [this.adherent.prenom, Validators.required],
            lieuNaissance: [this.adherent.lieuNaissance, Validators.required],
            dateNaissance: [this.adherent.dateNaissance, Validators.required],
            nomUsage: [this.adherent.nomUsage, Validators.required],
            paysNaissance: [this.getPays(), this.provenanceExterne ? Validators.required : null],
            dateValidite: [this.adherent.dateValiditePiece, this.provenanceExterne ? null : this.DateValiditePieceValidator()]
        });

        if (!this.provenanceExterne){
            this.formGroupManual.addControl('pjointe', new FormControl(this.adherent.pieceIdentite, [Validators.required]));
        }
    }

    onBlurNomPatronimyque() {
        if (this.formGroupManual.get('nomPatronymique').value != null && (this.formGroupManual.get('nomUsage').value == null || this.formGroupManual.get('nomUsage').value == "")){
            this.formGroupManual.get('nomUsage').patchValue(this.formGroupManual.get('nomPatronymique').value);
        }
    }

    buildFormAdherentAutomatic() {
        this.formGroup = this._formBuilder.group({
            paysNaissance: [this.getPays(), this.provenanceExterne ? Validators.required : null],
        });

        if (!this.provenanceExterne){
            this.formGroup.addControl('nomUsage', new FormControl(this.adherent.nomUsage, [Validators.required]))
            this.formGroup.addControl('lieuNaissance', new FormControl(this.adherent.lieuNaissance, [Validators.required]))
        }

        if (this.codeCiviliteASaisir) {
            this.formGroup.addControl('civilite', new FormControl(null, [Validators.required]));
        }
    }

    getValuesFromFormulaire() {
        this.adherent.controleManuelIdentiteAFaire = false;

        if (!this.provenanceExterne){
            this.adherent.nomUsage = this.formGroup.get('nomUsage').value.toUpperCase();
        }

        if (this.formGroup.get('paysNaissance') != null && this.formGroup.get('paysNaissance').value != null) {
            this.adherent.codePaysNaissance = this.formGroup.get('paysNaissance').value.codePays;
            this.adherent.paysNaissance = this.formGroup.get('paysNaissance').value.libelle;
        }
        if (this.codeCiviliteASaisir) {
            this.adherent.codeCivilite = this.formGroup.get('civilite').value;
        }
        if (!this.provenanceExterne){
            this.adherent.lieuNaissance = this.formGroup.get('lieuNaissance').value;
        }
    }

    getValuesFromFormulaireManuel() {
        this.adherent.controleManuelIdentiteAFaire = true;
        this.adherent.codeCivilite = this.formGroupManual.get('civilite').value;
        this.adherent.nomPatronymique = this.formGroupManual.get('nomPatronymique').value.toUpperCase();
        this.adherent.prenom = this.formGroupManual.get('prenom').value;
        this.adherent.nomUsage = this.formGroupManual.get('nomUsage').value.toUpperCase();
        this.adherent.dateNaissance = this.formGroupManual.get('dateNaissance').value;
        this.adherent.lieuNaissance = this.formGroupManual.get('lieuNaissance').value;
        if (this.formGroupManual.get('paysNaissance').value != null) {
            this.adherent.codePaysNaissance = this.formGroupManual.get('paysNaissance').value.codePays;
            this.adherent.paysNaissance = this.formGroupManual.get('paysNaissance').value.libelle;
        }

        if (!this.provenanceExterne) {
            
            this.adherent.pieceIdentite = this.formGroupManual.get('pjointe').value;
            
            this.adherent.dateValiditePiece = this.formGroupManual.get('dateValidite').value;
        }

    }

    toAdherentDTOFrom(personInfo: AFile) {
        this.adherent.codeCivilite = personInfo.codeCivilite;
        //dans le cas d'un passeport la civilité n'est pas renseignée
        this.codeCiviliteASaisir = this.adherent.codeCivilite == null || this.adherent.codeCivilite == '';
        this.adherent.controleManuelGDAAFaire = false;
        this.adherent.controleManuelIdentiteAFaire = false;
        this.adherent.controleManuelPPEAFaire = false;
        this.adherent.dateNaissance = personInfo.dateNaissance;
        this.adherent.lieuNaissance = personInfo.lieuNaissance;
        this.adherent.nomPatronymique = personInfo.nom
        this.adherent.nomUsage = personInfo.nomUsage ? personInfo.nomUsage : this.adherent.nomPatronymique;
        this.adherent.numeroPieceIdentite = personInfo.numeroPiece;

        this.adherent.prenom = personInfo.prenoms[0];
        this.adherent.typePieceIdentite = personInfo.typePiece;
        this.adherent.nationalite = personInfo.nationalite
        this.adherent.dateValiditePiece = personInfo.dateValiditePiece;
        this.adherent.dateEmissionPiece = personInfo.dateEmissionPiece;

        this.adherent.idAFile = personInfo.idAFile;
        this.adherent.pieceIdentite = [];
    }
  
    onValider() {
        if (this.formGroup.valid) {
            this.getValuesFromFormulaire();
            this.pieceidentiteVerified.emit(this.adherent);
        }
    }

    onValiderFormulaireManuel() {
        this.formGroupManualSubmitted = true;
        if (this.formGroupManual.valid) {
            this.getValuesFromFormulaireManuel();
            this.pieceidentiteVerified.emit(this.adherent);
        }
    }

    goToSaisieManuel() {
        this.state = 'manuelverifymode';
        this.buildFormAdherentManual();
    }

    goToSaisieAutomatic() {
        this.buildFormAdherentAutomatic();
        this.state = "automaticverifymode";
    }

    onChangePI() {
        this.nombreChangementPieceidentite = this.nombreChangementPieceidentite + 1;
        if (this.nombreChangementPieceidentite <= this.nbChangementPieceIdentiteMax) {
            this.state = 'telechargementmode';
        } else {
            this.goToSaisieManuel();
        }
    }
    
    onPassageManuel() {
        this.adherent = {};
        this.OpenParcoursManuel();
    }

}
