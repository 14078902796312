import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Input, ChangeDetectorRef } from "@angular/core";
import { DecimalEuroPipe } from "src/app/pipes/decimal-euro.pipe";
import { Decimal } from "decimal.js/decimal";
import * as moment from 'moment';
import { NewAdhService } from "src/app/api/new-adh.service";
import { AELAdhesionDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { DecimalHelperService } from "src/app/api/decimal-helper.service";
/*import { Observable } from "rxjs";
import { BehaviorSubject, ReplaySubject } from 'rxjs';*/

export interface Mensualite {
    echeance: string;
    value: string;
    restant: string,
    valueColl: string,
    restantColl: string

}

@Component( {
    selector: 'app-rachat-part',
    templateUrl: './rachat-part.component.html',
    styleUrls: ['./rachat-part.component.scss']
} )
export class RachatPartComponent implements OnInit {
    readonly minDateDebutrachat = moment( '31/03/1992', "DD/MM/YYYY" );

    dataSource = null;
    nouvelleAdhesion: AELAdhesionDTO;

    @Output()
    editerRachat = new EventEmitter();

    displayedColumns: string[] = ['echeance', 'value', 'restant', 'valueColl', 'restantColl'];
    @Input() formGroup;
    constructor( private newAdhService: NewAdhService, private decimalEuroPipe: DecimalEuroPipe, private ref: ChangeDetectorRef, private decimalHelperService: DecimalHelperService ) {
        this.newAdhService.adhesionItem$.subscribe( adhesion => this.nouvelleAdhesion = adhesion );
    }

    ngOnInit() {

        this.recalMensualite();
        this.formGroup.get( 'montantIndemnitesRachetees' ).valueChanges.subscribe(( change ) => {
            const taux = new Decimal( this.nouvelleAdhesion.taux );

            if ( change && this.decimalHelperService.isDecimal( change ) && this.decimalHelperService.isDecimal( taux ) ) {
                this.formGroup.get( 'montMax' ).setValue( this.decimalEuroPipe.transform( Decimal.mul( this.decimalHelperService.normalizeNumber( change ), taux ).div( 100 ).toFixed( 2 ) ) );
            } else {
                this.formGroup.get( 'montMax' ).setValue( null );
            }
            this.recalMensualite();
        } );

        this.formGroup.get( 'nbmensualites' ).valueChanges.subscribe(( change ) => {
            this.recalMensualite();
        } );

        this.formGroup.get( 'datePremierMoisRachat' ).valueChanges.subscribe(( change ) => {
            this.recalMensualite();
        } );

        this.formGroup.get( 'echeancier' ).valueChanges.subscribe(( change ) => {
            this.echeancier.value = change;
        } );
        this.formGroup.get( 'rachatEnPlusFois' ).valueChanges.subscribe(( change ) => {
            if ( change == false ) {
                //on passe de une fois à plusieurs fois
                this.echeancier.enable();
                if ( this.echeancier.value == "AUTO" ) {
                    this.nbmensualites.enable();
                    this.datePremierMoisRachat.enable();
                    this.pieceJointe.disable();
                }
                if ( this.echeancier.value == "MAN" ) {
                    this.nbmensualites.disable();
                    this.datePremierMoisRachat.disable();
                    this.pieceJointe.enable();
                }
                if ( this.echeancier.value == "ULTERIEUR" ) {
                    this.nbmensualites.disable();
                    this.datePremierMoisRachat.disable();
                    this.pieceJointe.disable();
                }
                this.ref.detectChanges();
            } else {
                //on passe de plusieurs fois à une fois 
                this.echeancier.disable();
                this.nbmensualites.disable();
                this.datePremierMoisRachat.disable();
                this.pieceJointe.disable();
                this.ref.detectChanges();

            }
        } );

        this.formGroup.get( 'doRachat' ).valueChanges.subscribe(( change ) => {
            if ( change == true ) {

                this.montantIndemnitesRachetees.enable();
                this.dateDebutRachat.enable();
                this.dateFinRachat.enable();
                if ( this.rachatEnPlusFois.value == false ) {

                    this.echeancier.enable();
                    if ( this.echeancier.value == "AUTO" ) {
                        this.nbmensualites.enable();
                        this.datePremierMoisRachat.enable();
                        this.pieceJointe.disable();
                    }
                    if ( this.echeancier.value == "MAN" ) {
                        this.nbmensualites.disable();
                        this.datePremierMoisRachat.disable();
                        this.pieceJointe.enable();
                    }
                    if ( this.echeancier.value == "ULTERIEUR" ) {
                        this.nbmensualites.disable();
                        this.datePremierMoisRachat.disable();
                        this.pieceJointe.disable();
                    }
                }

            } else {
                this.echeancier.disable();
                this.montantIndemnitesRachetees.disable();
                this.dateDebutRachat.disable();
                this.dateFinRachat.disable();
                this.nbmensualites.disable();
                this.datePremierMoisRachat.disable();
                this.pieceJointe.disable();

            }
        } );
        this.formGroup.get( 'echeancier' ).valueChanges.subscribe(( change ) => {
            if ( change == 'MAN' || change == 'ULTERIEUR' ) {
                this.nbmensualites.disable();
                this.datePremierMoisRachat.disable();

            } else { //auto
                this.nbmensualites.enable();
                this.datePremierMoisRachat.enable();

            }

            if ( change == 'AUTO' || change == 'ULTERIEUR' ) {
                this.pieceJointe.disable();

            } else { //man
                this.pieceJointe.enable();
            }

            this.ref.detectChanges();
        } );


    }

    ngAfterContentInit() {
    }

    get echeancier() { return this.formGroup.get( 'echeancier' ); }
    get nbmensualites() { return this.formGroup.get( 'nbmensualites' ); }
    get rachatEnPlusFois() { return this.formGroup.get( 'rachatEnPlusFois' ); }
    get datePremierMoisRachat() { return this.formGroup.get( 'datePremierMoisRachat' ); }
    get dateDebutRachat() { return this.formGroup.get( 'dateDebutRachat' ); }
    get dateFinRachat() { return this.formGroup.get( 'dateFinRachat' ); }
    get montantIndemnitesRachetees() { return this.formGroup.get( 'montantIndemnitesRachetees' ); }
    get pieceJointe() { return this.formGroup.get( 'pjointe' ); }
    getDateDebutErrorMessage() {
        var previousMonth = moment( this.nouvelleAdhesion.dateEffetSouhaiteeAdhesion ).subtract( 1, 'month' );
        var lastDayOfMonthPrevious = previousMonth.endOf( 'month' );
        var formatted_date = this.nouvelleAdhesion.dateEffetSouhaiteeAdhesion == null ? moment().format( 'DD/MM/YYYY' ) : lastDayOfMonthPrevious.format( 'DD/MM/YYYY' );

        return this.dateDebutRachat.hasError( 'required' ) ? 'Veuillez saisir une date de début' :
            this.dateDebutRachat.hasError( 'ControleCoherencePeriode' ) ? 'La date de début doit être antérieure....' :
                this.dateDebutRachat.hasError( 'dateDebutRachatSup92' ) ? 'La date de début de rachat doit être postérieure à 30/03/1992.'
                    : this.dateDebutRachat.hasError( 'dateDebRachatSupDateAdhesion' ) ? 'La date de début de rachat doit être inférieure ou égale au'
                        + " " + formatted_date : '';
    }

    getDateFinErrorMessage() {
        var previousMonth = moment( this.nouvelleAdhesion.dateEffetSouhaiteeAdhesion ).subtract( 1, 'month' );
        var lastDayOfMonthPrevious = previousMonth.endOf( 'month' );
        var formatted_date = this.nouvelleAdhesion.dateEffetSouhaiteeAdhesion == null ? moment().format( 'DD/MM/YYYY' ) : lastDayOfMonthPrevious.format( 'DD/MM/YYYY' );

        return this.dateFinRachat.hasError( 'required' ) ? 'Veuillez saisir une date de fin' :
            this.dateFinRachat.hasError( 'ControleCoherencePeriode' ) ? '... à la date de fin.' :
                this.dateFinRachat.hasError( 'dateFinRachatSupDateAdhesion' ) ? 'La date de fin de rachat doit être inférieure ou égale au'
                    + " " + formatted_date : '';
    }

    total() {
        const montantIndemnitesRachetees = this.montantIndemnitesRachetees.value;
        if ( this.decimalHelperService.isDecimal( montantIndemnitesRachetees ) ) {
            const taux = new Decimal( this.nouvelleAdhesion.taux );
            return Decimal.mul( this.decimalHelperService.normalizeNumber( montantIndemnitesRachetees ), taux ).div( 100 ).toFixed( 2 );
        } else {
            return "0.00";
        }
    }


    mensualiteStandard() {
        const nbmensualite = this.nbmensualites.value;
        return Decimal.div( this.total(), nbmensualite ).toFixed( 2 );
    }


    mensualiteFinale() {
        const nbmensualite = this.nbmensualites.value;
        const mensualite = this.mensualiteStandard();
        let restant = new Decimal( this.total() );
        let i;
        for ( i = 0; i < nbmensualite - 1; i++ ) {
            restant = restant.minus( mensualite );
        }

        return restant.toString();
    }

    recalMensualite() {
        if ( !this.nbmensualites.valid || !this.montantIndemnitesRachetees.valid || !this.datePremierMoisRachat.valid ) {
            this.dataSource = null;
            return;
        }

        if ( !this.decimalHelperService.isDecimal( this.nbmensualites.value ) || !this.decimalHelperService.isDecimal( this.montantIndemnitesRachetees.value ) ) {
            this.dataSource = null;
            return;
        }

        const nbmensualite = this.nbmensualites.value;

        const total = this.total();
        const mensualiteStandard = this.mensualiteStandard();

        let restant = new Decimal( total );

        let month = moment( this.datePremierMoisRachat.value );
        month.locale( 'fr' );

        let recap = new Array<Mensualite>();

        var i;
        for ( i = 0; i < nbmensualite; i++ ) {
            let mensualite;

            if ( i != nbmensualite - 1 ) {
                mensualite = mensualiteStandard;
                restant = restant.minus( mensualite );
            } else {
                mensualite = this.mensualiteFinale();
                restant = new Decimal( 0 );
            }
            recap.push( {
                echeance: month.format( "MMMM YYYY" ), value: this.decimalEuroPipe.transform( mensualite ), restant: this.decimalEuroPipe.transform( restant )
                , valueColl: this.decimalEuroPipe.transform( mensualite ), restantColl: this.decimalEuroPipe.transform( restant )
            } );
            month.add( 1, 'M' );
        }

        this.dataSource = recap;
    }

    onEditerRachat() {
        this.editerRachat.emit();
    }

}
