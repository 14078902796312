import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { EmailValidationResourceService } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { takeUntil } from "rxjs/operators";
import { Subject, BehaviorSubject } from "rxjs";

@Component( {
    selector: 'app-validate-email',
    templateUrl: './validate-email.component.html',
    styleUrls: ['./validate-email.component.scss']
} )
export class ValidateEmailComponent implements OnInit {
    status;
    public spinnerSubject = new BehaviorSubject<boolean>( true );
    public spinner$ = this.spinnerSubject.asObservable();
    private unsubscribe: Subject<void> = new Subject();
    constructor( private route: ActivatedRoute, private emailValidationService: EmailValidationResourceService ) {
        this.emailValidationService.validateEmail( this.route.snapshot.queryParamMap.get( 'token' ) ).pipe( takeUntil( this.unsubscribe ) ).subscribe( res => {
            this.status = res;
            this.spinnerSubject.next( false );
        } );
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

}
