import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

interface FileReaderEventTarget extends EventTarget {
    result: string;
}

interface FileReaderEvent extends Event {
    target: FileReaderEventTarget;
    getMessage(): string;
}

@Component( {
    selector: 'app-protectionDonneesComponent',
    templateUrl: './protectionDonnees.component.html',
    styleUrls: ['./protectionDonnees.component.scss']
} )

export class ProtectionDonneesComponent implements OnInit {
    cgText;
    data;
    constructor( private dialogRef: MatDialogRef<ProtectionDonneesComponent>, @Inject( MAT_DIALOG_DATA ) data: any ) {
        this.data = data;
    }

    ngOnInit() {

    }


}
