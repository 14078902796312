import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatDialogModule, MatProgressSpinnerModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatDividerModule, MatStepperModule, MatRadioModule, MatSelectModule, MatTableModule, MatCheckboxModule, MatMenuModule } from '@angular/material';
import { SnackSuccessComponent } from './components/snack-success/snack-success.component';
import { SnackErrorComponent } from './components/snack-error/snack-error.component';
import { SnackWaitComponent } from './components/snack-wait/snack-wait.component';
import { QuitConfirmDialogComponent } from './components/quit-confirm-dialog/quit-confirm-dialog.component';
import { JustifInputComponent } from "src/app/shared/components/justif-input/justif-input.component";
import { JustifInputDialogComponent } from "src/app/shared/components/justif-input-dialog/justif-input-dialog.component";
import { FileUploadModule } from 'ng2-file-upload';
import { WarningDialogComponent } from './components/warning-dialog/warning-dialog.component';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { DemandeGeneriqueComponent } from "src/app/shared/components/demande-generique/demande-generique.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ErrorDialogComponent } from "src/app/shared/components/error-dialog/error-dialog.component";
import { NewAdhMessageDialogComponent } from "src/app/shared/components/new-adh-message-dialog/new-adh-message-dialog.component";
import { NewAdhFormComponent } from './components/new-adh-form/new-adh-form.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { AdherentPartComponent } from './components/new-adh-form-parts/adherent-part/adherent-part.component';
import { IdentitePartComponent } from './components/new-adh-form-parts/identite-part/identite-part.component';

import { CollectivitePartComponent } from './components/new-adh-form-parts/collectivite-part/collectivite-part.component';
import { GarantiesPartComponent } from './components/new-adh-form-parts/garanties-part/garanties-part.component';
import { RachatPartComponent } from './components/new-adh-form-parts/rachat-part/rachat-part.component';
import { RecapPartComponent } from './components/new-adh-form-parts/recap-part/recap-part.component';
import { CgPartComponent } from './components/new-adh-form-parts/cg-part/cg-part.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AdressPartComponent } from './components/new-adh-form-parts/adress-part/adress-part.component';
import { AdressDialogPartComponent } from './components/new-adh-form-parts/adressDialog-part/adressDialog-part.component';
import { InfosNaissanceDialogPartComponent } from './components/new-adh-form-parts/infosNaissanceDialog-part/infosNaissanceDialog-part.component';

import { SmsDialogPartComponent } from './components/new-adh-form-parts/smsDialog-part/smsDialog-part.component';
import { CgComponent } from './components/new-adh-form-parts/cg/cg.component';
import { ProtectionDonneesComponent } from './components/new-adh-form-parts/protectionDonnees/protectionDonnees.component';

import { ContactDialogComponent } from './components/new-adh-form-parts/contactDialog/contactDialog.component';


import { PipesModuleModule } from "src/app/pipes/pipes-module.module";
import { DirectivesModule } from "src/app/directives/directives.module";
import { BeneficiairePartComponent } from './components/new-adh-form-parts/beneficiaire-part/beneficiaire-part.component';
import { DemarcheModule } from "src/app/home/demarche/demarche.module";
import { MonthDateSimuComponent } from "src/app/shared/components/month-date-simu/month-date-simu.component";
import { MonthDateComponent } from "src/app/shared/components/month-date/month-date.component";
import { DecimalEuroPipe } from "src/app/pipes/decimal-euro.pipe";
import { PrefixTelComponent } from './components/prefix-tel/prefix-tel.component';
import { SouscriptionInfosDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/souscriptionInfosDialog-part/souscriptionInfosDialog-part.component';
import { IdentiteParcoursWebDialogPartComponent } from 'src/app/shared/components/new-adh-form-parts/identiteParcoursWebDialog-part/identiteParcoursWebDialog-part.component';

@NgModule( {
    providers: [
        DecimalEuroPipe
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        TextFieldModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatStepperModule,
        RecaptchaModule,
        MatFormFieldModule,
        MatDatepickerModule,
        FlexLayoutModule,
        MatDialogModule,
        DirectivesModule,
        MatTableModule,
        FileUploadModule,
        PipesModuleModule, MatDividerModule, PipesModuleModule, MatMenuModule
    ],
    declarations: [MonthDateComponent, MonthDateSimuComponent, ConfirmDialogComponent, SnackSuccessComponent, SnackErrorComponent, SnackWaitComponent, QuitConfirmDialogComponent, JustifInputComponent, DemandeGeneriqueComponent, JustifInputDialogComponent, WarningDialogComponent, InfoDialogComponent, ErrorDialogComponent, NewAdhMessageDialogComponent, NewAdhFormComponent, IdentitePartComponent, AdherentPartComponent, CollectivitePartComponent, GarantiesPartComponent, RachatPartComponent, RecapPartComponent, CgPartComponent, AdressPartComponent, AdressDialogPartComponent, InfosNaissanceDialogPartComponent, BeneficiairePartComponent, CgComponent, ProtectionDonneesComponent, ContactDialogComponent, SmsDialogPartComponent, PrefixTelComponent, SouscriptionInfosDialogPartComponent, IdentiteParcoursWebDialogPartComponent],
    exports: [MonthDateComponent, MonthDateSimuComponent, JustifInputComponent, JustifInputDialogComponent, DemandeGeneriqueComponent, MonthDateSimuComponent, NewAdhFormComponent],
    entryComponents: [BeneficiairePartComponent, AdressDialogPartComponent, InfosNaissanceDialogPartComponent, CgComponent, ProtectionDonneesComponent, ContactDialogComponent, NewAdhMessageDialogComponent, SmsDialogPartComponent, SouscriptionInfosDialogPartComponent, IdentiteParcoursWebDialogPartComponent]
} )
export class SharedModule {
}
