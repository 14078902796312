import { Component, DoCheck, ElementRef, forwardRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { JustifInputDialogComponent } from '../justif-input-dialog/justif-input-dialog.component';
import { CanUpdateErrorState, ErrorStateMatcher, MatDialog, MatFormFieldControl, mixinErrorState } from '@angular/material';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { Subject } from 'rxjs';
import { JustifInputData } from './justif-input-data';
import { JustifInputDialogResponse } from '../justif-input-dialog/justif-input-dialog-response';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

export class MatInputBase {
    constructor( public _defaultErrorStateMatcher: ErrorStateMatcher,
        public _parentForm: NgForm,
        public _parentFormGroup: FormGroupDirective,
        public ngControl: NgControl ) {
    }
}

export const _MatFormFieldControlBase = mixinErrorState( MatInputBase );

@Component( {
    selector: 'app-justif-input',
    templateUrl: './justif-input.component.html',
    styleUrls: ['./justif-input.component.scss'],
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: forwardRef(() => JustifInputComponent ),
            multi: true
        }
    ]
} )

export class JustifInputComponent extends _MatFormFieldControlBase implements ControlValueAccessor, MatFormFieldControl<Array<JustifInputData>>, OnDestroy, CanUpdateErrorState, DoCheck {
    static nextId = 0;

    readonly autofilled: boolean = false;
    readonly controlType: string = 'justif-input';
    readonly placeholder: string = '';

    stateChanges = new Subject<void>();

    errorState = false;
    focused = false;

    @HostBinding( 'attr.aria-describedby' )
    describedBy: string;

    private _disabled = false;
    private _required = false;
    private _value: Array<JustifInputData>;

    private onChange: any;
    private onTouched: any;

    @HostBinding( 'class.floating' )
    get shouldLabelFloat() {
        return this.focused || !this.empty;
    }

    @Input()
    public justifLibelle: string;

    @Input()
    public maxSize: string;

    @Input()
    allowedExtensions: string;

    @Input()
    public libelleZoneTelechargement: string = "Joindre des documents";

    @HostBinding()
    id = `justif-input-${JustifInputComponent.nextId++}`;

    get empty() {
        return !this.value;
    }

    set value( value: Array<JustifInputData> | null ) {
        this._value = value;
        this.stateChanges.next();
    }

    @Input()
    get value() {
        return this._value;
    }

    @Input()
    get required() {
        return this._required;
    }

    set required( req ) {
        this._required = coerceBooleanProperty( req );
        this.stateChanges.next();
    }

    @Input()
    get disabled() {
        return this._disabled;
    }

    set disabled( dis ) {
        this._disabled = !!dis;
        this.stateChanges.next();
    }

    setDescribedByIds( ids: string[] ) {
        this.describedBy = ids.join( ' ' );
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring( this.elRef.nativeElement );
    }

    constructor( @Optional() @Self() public ngControl: NgControl,
        @Optional() _parentForm: NgForm,
        @Optional() _parentFormGroup: FormGroupDirective,
        _defaultErrorStateMatcher: ErrorStateMatcher,
        private fm: FocusMonitor,
        private elRef: ElementRef,
        private dialog: MatDialog ) {
        super( _defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl );

        if ( this.ngControl != null ) {
            this.ngControl.valueAccessor = this;
        }
        fm.monitor( elRef.nativeElement, true ).subscribe( origin => {
            this.focused = !!origin;
            this.stateChanges.next();
        } );
    }

    onContainerClick( event: MouseEvent ): void {
        this.openDialog();
    }

    openDialog(): void {
        if ( this.disabled ) {
            return;
        }

        const dialogRef = this.dialog.open( JustifInputDialogComponent, {
            width: '600px',
            panelClass: 'justif-input-dialog',
            data: { value: this.value ? this.value : undefined, libelle: this.justifLibelle, maxSize: this.maxSize, allowedExtensions: this.allowedExtensions }
        } );

        dialogRef.afterClosed().subscribe(( result?: JustifInputDialogResponse ) => {
            if ( result.cancel ) {
                return;
            }

            if ( this.onTouched ) this.onTouched();

            if ( this.onChange ) this.onChange( result.data );

            this.value = result.data;
        } );
    }

    registerOnChange( fn: any ): void {
        this.onChange = fn;
    }

    registerOnTouched( fn: any ): void {
        this.onTouched = fn;
    }

    setDisabledState( isDisabled: boolean ): void {
        this._disabled = isDisabled;
    }

    writeValue( obj: any ): void {
        this.value = obj as Array<JustifInputData>;
    }

    ngDoCheck() {
        this.updateErrorState();
    }

    updateErrorState() {
        super.updateErrorState();
    }
}
