import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Input } from "@angular/core";
import { UtilsResourceService, Pays } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { ViewEncapsulation } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { ControlContainer } from "@angular/forms";
import { FormGroup, Validators } from "@angular/forms";
import {MatExpansionPanel} from '@angular/material/expansion';


@Component( {
    selector: 'app-adress-part',
    templateUrl: './adress-part.component.html',
    styleUrls: ['./adress-part.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    encapsulation: ViewEncapsulation.None
} )
export class AdressPartComponent implements OnInit {
    public listePays: Array<Pays>;
    public listeVilles: Array<String>;
    formGroup: FormGroup;
    PaysParDefaut: Pays;
    public ZipCodeRegex = /^\d{5}$/;
    
    @ViewChild( MatExpansionPanel ) panel;
    
    constructor( private utilsService: UtilsResourceService, private parentF: FormGroupDirective ) {

    }
    ngOnInit() {
        this.formGroup = this.parentF.form;
        this.utilsService.getListePays().subscribe( result => {
            this.listePays = result;
            this.PaysParDefaut = { codePays: 235, libelle: "FRANCE" };
        } )
    }
    ngAfterViewInit() {
        if ( this.formGroup.get( 'pays' ) !== undefined && this.formGroup.get( 'pays' ).value.codePays == 235 ) {
            this.formGroup.get( 'codePostal' ).setValidators( [Validators.required, Validators.pattern( this.ZipCodeRegex )] );
            this.formGroup.get( 'codePostal' ).updateValueAndValidity();
        }
    }
    compareObjects( o1: Pays, o2: Pays ): boolean {
        return o1 && o2 ? o1.codePays === o2.codePays : o1 === o2;
    }
    codePostalModifie( $value: string ) {
        this.formGroup.get( 'ville' ).setValue( '' );
        this.villeParCodePostal( $value );
    }

    villeParCodePostal( $codePostal: string ) {
        this.listeVilles = [];
        if ( $codePostal.length >= 3 && this.formGroup.get( 'pays' ).value.codePays == 235 ) {
            this.utilsService.getCodePostauxVillesParCodePostal( $codePostal ).subscribe( result => {
                for ( const entry of Object.entries( result ) ) {
                    let codePostal = Object.keys( entry[1] )[0];
                    let ville = Object.values( entry[1] )[0];
                    this.listeVilles.unshift( codePostal + " " + ville );
                }
            } )
        }
    }
    onSelectionCodePostal( entry: string ) {
        this.formGroup.get( 'codePostal' ).setValue( entry.substring( 0, 5 ) );
        this.formGroup.get( 'ville' ).setValue( entry.substring( 5 ) );
    }

    onRecherchePaysChange() {
        if ( this.formGroup.get( 'pays' ).value.codePays == 235 ) {
            this.formGroup.get( 'codePostal' ).setValidators( [Validators.required, Validators.pattern( this.ZipCodeRegex )] );

        } else {

            this.formGroup.get( 'codePostal' ).setValidators( [Validators.required] );
        }
        this.listeVilles = [];
        this.formGroup.get( 'codePostal' ).setValue( '' );
        this.formGroup.get( 'ville' ).setValue( '' );
        this.formGroup.get( 'codePostal' ).updateValueAndValidity();
    }
    effacer() {
        this.formGroup.get( 'ligne1' ).reset();
        this.formGroup.get( 'ligne2' ).reset();
        this.formGroup.get( 'ligne3' ).reset();
        this.formGroup.get( 'codePostal' ).reset();
        this.formGroup.get( 'ville' ).reset();
    }

    expand(){
        this.panel.open();
    }

}
