import { Component, OnInit } from '@angular/core';
import { Moment } from "moment/moment";
import { MatDatepicker } from "@angular/material/datepicker";
import { DateAdapter } from "@angular/material/core";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as moment from 'moment';
import { ControlContainer } from "@angular/forms";
import { FormGroupDirective } from "@angular/forms";

export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};


@Component( {
    selector: 'app-month-date-simu',
    templateUrl: './month-date-simu.component.html',
    styleUrls: ['./month-date-simu.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ],
} )


export class MonthDateSimuComponent implements OnInit {
    @Input() myFormControlName: string;
    readonly premierjanvieranneeencours = moment().startOf( 'year' );

    formGroup: FormGroup;

    constructor( private parentF: FormGroupDirective ) {
    }

    ngOnInit() {
        this.formGroup = this.parentF.form;
    }

    chosenMonthHandler( normalizedMonth: Moment, datepicker: MatDatepicker<Moment> ) {
        if ( normalizedMonth ) {
            this.field().setValue( normalizedMonth );
            datepicker.close();
        }
    }

    field() {
        return this.formGroup.get( this.myFormControlName );
    }
}