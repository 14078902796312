import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class ConfirmEmailValidators {
    static ConfirmEmail( comparedField: string ): ValidatorFn {
        function confirmEmailValidator( c: AbstractControl ): ValidationErrors | null {
            if ( c && c.parent ) {
                const comparedFormControl = c.parent.get( comparedField );
                /*console.log( "contrôle" + c.value );
                console.log( comparedFormControl );*/

                if ( !comparedFormControl || comparedFormControl.value !== c.value ) {
                    return { notIdenticalFields: true };
                }
            }
            return null;
        }

        return confirmEmailValidator;
    }
}
