import { NgModule } from '@angular/core';
import { DecimalEuroPipe } from "src/app/pipes/decimal-euro.pipe";
import { CurrencyPipe } from "@angular/common";
import { PointPipe } from './point.pipe';
import { DecimalPipe } from "@angular/common";
import { EmptyCheckPipe } from "src/app/pipes/empty-check.pipe";
import { PhoneNumberPipe } from "src/app/pipes/phone-number.pipe";
import { SiretPipe } from "src/app/pipes/siret-pipe";
import { NirPipe } from "src/app/pipes/nir-pipe";
import { PercentPipe } from "src/app/pipes/percent.pipe";
import { CapitalizeFirstPipe } from "src/app/pipes/capitalizefirst.pipe";

@NgModule( {
    declarations: [DecimalEuroPipe, PointPipe, EmptyCheckPipe, PhoneNumberPipe, SiretPipe, NirPipe, PercentPipe, CapitalizeFirstPipe],
    exports: [DecimalEuroPipe, PointPipe, EmptyCheckPipe, PhoneNumberPipe, SiretPipe, NirPipe, PercentPipe, CapitalizeFirstPipe],
    imports: [],
    providers: [CurrencyPipe, DecimalPipe, EmptyCheckPipe, PhoneNumberPipe, SiretPipe, NirPipe, PercentPipe, CapitalizeFirstPipe]
} )
export class PipesModuleModule { }
