import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AELAdhesionDTO, NomenclatureDTO } from "@com.sofaxis/sofaxis-fonpel-espace-elus-ws-ts/dist";
import { NewAdhService } from "src/app/api/new-adh.service";
import { MatTableDataSource } from "@angular/material/table";
import { trigger } from "@angular/animations";
import { animate } from "@angular/animations";
import { style } from "@angular/animations";
import { transition } from "@angular/animations";
import { state } from "@angular/animations";
import TelephoneNumberUtils from "src/app/utils/telephone-utils";
@Component( {
    selector: 'app-recap-part',
    templateUrl: './recap-part.component.html',
    styleUrls: ['./recap-part.component.scss'],
    animations: [
        trigger( 'detailExpand', [
            state( 'collapsed', style( { height: '0px', minHeight: '0' } ) ),
            state( 'expanded', style( { height: '*' } ) ),
            transition( 'expanded <=> collapsed', animate( '225ms cubic-bezier(0.4, 0.0, 0.2, 1)' ) ),
        ] ),
    ],
} )
export class RecapPartComponent implements OnInit {
    nouvelleAdhesion: AELAdhesionDTO;
    displayedColumnsBeneficiaire: string[] = ['beneficiaire', 'ordre', 'taux', 'actionsColumn'];
    dataSourceBeneficiaires;
    public visibleDiv;

    @Output()
    goTo = new EventEmitter();

    constructor( private newAdhService: NewAdhService ) {

    }

    ngOnInit() {
        this.newAdhService.adhesionItem$.subscribe( adhesion => {
            this.nouvelleAdhesion = adhesion;
            this.dataSourceBeneficiaires = new MatTableDataSource( this.nouvelleAdhesion.beneficiaires );
        } );
    }

    getNationnalNumberMobile() {
        return TelephoneNumberUtils.toNationalFormat( this.nouvelleAdhesion.adherent.portable );
    }

    getPrefixNumeroMobile() {
        return TelephoneNumberUtils.toPrefix( this.nouvelleAdhesion.adherent.portable );
    }

    afficherDetail( id: any ) {
        if ( this.visibleDiv === id ) {
            this.visibleDiv = null;
        } else {
            this.visibleDiv = id;
        }
    }

    divDetailVisible( id: any ): boolean {
        return this.visibleDiv == id;
    }
    onGoTo( index ) {
        this.goTo.emit( index );
    }
}
