import { Component, Inject } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { JustifInputDialogResponse } from './justif-input-dialog-response';
import { JustifInputDialogData } from './justif-input-dialog-data';
import { environment } from "src/environments/environment";
import { JustifInputData } from "src/app/shared/components/justif-input/justif-input-data";
import { AppConfig } from '../../../utils/app.config';


@Component( {
    selector: 'app-justif-input-dialog',
    templateUrl: './justif-input-dialog.component.html',
    styleUrls: ['./justif-input-dialog.component.scss']
} )
export class JustifInputDialogComponent {
    public uploader: FileUploader = new FileUploader( { url: '' } );
    public hasDropZoneOver = false;
    public previousData: Array<JustifInputData>;
    public errorMsg = '';
    public totalSize = 0;
    public allowedExtensions: string;
    public maxSize = 0;
    public inputMaxSize;
    public inputAllowedExtensions: string;

    constructor(
        public dialogRef: MatDialogRef<JustifInputDialogComponent, JustifInputDialogResponse>, private config: AppConfig,
        @Inject( MAT_DIALOG_DATA ) public data: JustifInputDialogData ) {

        this.inputMaxSize = data.maxSize;
        this.inputAllowedExtensions = data.allowedExtensions;
        if ( data.value && data.value.length > 0 ) {
            this.previousData = data.value;
            for ( let file of this.previousData ) {
                if ( typeof file.base64 === 'string' ) {
                    this.totalSize += file.base64.length / 4 * 3;
                } else if ( file.base64 instanceof ArrayBuffer ) {
                    this.totalSize += file.base64.byteLength / 4 * 3;
                }
            }
        } else {
            this.previousData = new Array<JustifInputData>();
            this.totalSize = 0;
        }

    }

    ngOnInit() {
        if ( this.inputAllowedExtensions == undefined ) {
            this.allowedExtensions = this.config.getConfig( 'allowedExtensions' );
        } else {
            this.allowedExtensions = this.inputAllowedExtensions;
        }
        if ( this.inputMaxSize == undefined ) {
            this.maxSize = environment.maxSizeUploadFile;
        } else {
            this.maxSize = this.inputMaxSize;
        }
        //console.log( "maxsize=" + this.maxSize );
        //console.log( "extensions=" + this.allowedExtensions );

    }

    public fileOver( e: any ): void {
        this.hasDropZoneOver = e;
    }

    public dropped( e: any ): void {
        if ( e && e[0] ) {
            this.errorMsg = null;
            for ( let file of e ) {
                this.totalSize += file.size
            }
            //console.log( "total size 0" + this.totalSize );
            if ( this.totalSize <= this.maxSize ) {
                for ( let file of e ) {
                    if ( this.checkExtension( file.name ) ) {
                        this.uploader.addToQueue( file );
                    } else {
                        this.errorMsg = `Les formats de fichier autorisés sont ${this.allowedExtensions}.`;
                    }
                }
            } else {
                this.errorMsg = `La taille totale de vos fichiers ne peut pas excéder ${this.maxSize / 1024} Ko`;
            }
        }
    }


    public hasError() {
        return this.errorMsg && this.errorMsg.length > 0;
    }

    public cancelUpload(): void {
        this.uploader.clearQueue();
        this.dialogRef.close( { cancel: true } );
    }

    public removeInData( item ) {

        if ( typeof item.base64 === 'string' ) {
            this.totalSize -= item.base64.length / 4 * 3;
        } else if ( item.base64 instanceof ArrayBuffer ) {
            this.totalSize -= item.base64.byteLength / 4 * 3;
        }
        //console.log( "total size" + this.totalSize );
        this.previousData = this.previousData.filter( obj => item !== obj );
        if ( this.totalSize <= this.maxSize ) {
            this.errorMsg = null;
        } else {
            this.errorMsg = `La taille totale de vos fichiers ne peut pas excéder ${this.maxSize / 1024} Ko`;
        }
    }

    public removeInQueue( item ) {
        this.totalSize -= item.file.size;
        item.remove();
        if ( this.totalSize <= this.maxSize ) {
            this.errorMsg = null;
        } else {
            this.errorMsg = `La taille totale de vos fichiers ne peut pas excéder ${this.maxSize / 1024} Ko`;
        }
    }

    public removeFile(): void {
        this.uploader.clearQueue();
        this.dialogRef.close( {
            data: new Array<JustifInputData>()
        } );
    }

    public validUpload(): void {
        if ( this.uploader.queue.length > 0 ) {
            let tasks$ = [];
            let data: Array<JustifInputData> = this.previousData;
            for ( let elem of this.uploader.queue ) {
                const reader = new FileReader();
                let obs = Observable.create( observer => {
                    let rawFile = elem.file.rawFile;
                    let fileName = elem.file.name;
                    reader.readAsDataURL( rawFile.slice( 0 ) as any );
                    reader.onloadend = () => {
                        data.push( {
                            base64: reader.result,
                            filename: fileName
                        } )
                        observer.next( data );
                        observer.complete();
                    };

                } );
                tasks$.push( obs );
            }
            forkJoin( tasks$ ).subscribe( results => {
                this.dialogRef.close( {
                    data: data
                } );
            }
            );
        } else {
            this.dialogRef.close( {
                data: this.previousData
            } );
        }
    }

    private checkExtension( filename: string ): boolean {
        const exploded = ( filename.toLowerCase() ).split( '.' );
        return this.allowedExtensions.indexOf( exploded[exploded.length - 1] ) >= 0;
    }
}
